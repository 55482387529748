import React, { useEffect, useState } from 'react';
import {
  Paper,
  Typography,
  Button,
  Box,
  useTheme,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import { Redirect, useHistory } from 'react-router';

import { useDispatch, useSelector } from 'src/store';
import { logIn } from 'src/store/session/actions';
import { Logo } from 'src/components/Icons';
import { TextField } from 'src/components/Fields';
import { signIn, LoginResult } from 'src/services/auth';

export const SignUpPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.session);

  const [loading, setLoading] = useState(true);
  const [loginResult, setLoginResult] = useState<LoginResult | -1>();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState<{ message?: string }>();

  useEffect(() => {
    const [email, password] = window.location.search
      .replace(/^\?email=/gi, '')
      .split('&tempPassword=')
      .map(decodeURIComponent);

    signIn(email, password)
      .then(setLoginResult)
      .catch(() => {
        setLoginResult(-1);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const theme = useTheme();

  if (loggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        minHeight: '100%',
        padding: theme.spacing(8),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper elevation={12} style={{ maxWidth: 320 }}>
        <Box
          p={6}
          display="grid"
          gridTemplateColumns="1fr"
          gridGap={theme.spacing(4)}
          component="form"
          onSubmit={(ev) => {
            ev.preventDefault();
            if (loginResult !== -1 && loginResult?.updatePassword) {
              setLoading(true);
              setPasswordError(undefined);
              loginResult
                .updatePassword(password)
                .then(() => {
                  dispatch(logIn());
                })
                .catch((err) => {
                  setPasswordError(err);
                  setLoading(false);
                });
            }
          }}
        >
          <Typography
            component="h1"
            variant="h3"
            style={{
              fontWeight: 'bold',
              display: 'flex',
              flexDirection: 'column',
              lineHeight: 1,
              marginBottom: theme.spacing(4),
              justifySelf: 'center',
            }}
          >
            <Typography
              variant="body1"
              component="span"
              style={{ fontWeight: 'normal' }}
            >
              Welcome to{' '}
            </Typography>
            ProForma
            <Logo size={72} style={{ alignSelf: 'flex-end' }} />
          </Typography>
          {loading ? (
            <CircularProgress style={{ justifySelf: 'center' }} />
          ) : (
            <>
              {loginResult === -1 ? (
                <Typography
                  variant="body1"
                  align="center"
                  style={{ marginBottom: theme.spacing(4) }}
                >
                  Something went wrong. If you have already registered, please
                  sign-in instead.
                </Typography>
              ) : (
                <>
                  <Typography
                    variant="body1"
                    align="center"
                    color={passwordError ? 'error' : undefined}
                    style={{ marginBottom: theme.spacing(4) }}
                  >
                    {passwordError?.message
                      ? passwordError.message.split(': ')[1]
                      : passwordError
                      ? 'Something went wrong. Please, try again.'
                      : 'To finish setting up you account, please choose a password.'}
                  </Typography>
                  <TextField
                    label="Password"
                    type="password"
                    required
                    value={password}
                    onChange={(ev) => setPassword(ev.currentTarget.value)}
                  />
                  <TextField
                    label="Confirm password"
                    type="password"
                    required
                    value={confirmPassword}
                    onChange={(ev) =>
                      setConfirmPassword(ev.currentTarget.value)
                    }
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disableElevation
                    disabled={
                      password.length < 8 || password !== confirmPassword
                    }
                  >
                    Sign-up
                  </Button>
                </>
              )}
              <Divider />
              <Typography variant="body2" align="center">
                Already registered?
              </Typography>
              <Button
                onClick={() => {
                  history.push('/login');
                }}
                type="button"
                variant="contained"
                color="primary"
                disableElevation
              >
                Sign-in
              </Button>
            </>
          )}
        </Box>
      </Paper>
    </div>
  );
};
