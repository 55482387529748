import React, { useState, useEffect, ReactNode } from 'react';

import {
  localizationCategoryMap,
  postProductionCategoryMap,
} from 'src/util/common';
import { StatusChip } from './StatusChip';

export type TGroupBy = 'Episode' | 'Asset Type' | 'Language' | 'Status';
export const groups: TGroupBy[] = [
  'Episode',
  'Asset Type',
  'Language',
  'Status',
];

export const useGroups = (
  project: E.Project,
  assets: E.Asset[],
  requestsView?: boolean,
) => {
  const [groupedAssets, setGroupedAssets] = useState<
    [ReactNode, string, E.Asset[]][]
  >([]);
  const [groupingBy, setGroupingBy] = useState<TGroupBy>(groups[0]);

  useEffect(() => {
    switch (groupingBy) {
      case 'Episode':
        setGroupedAssets(
          project.episodes
            .map(({ number, title, episodeId }) => [
              `Episode ${number} – ${title}`,
              episodeId,
              assets.filter(
                (a) =>
                  a.episodeId === episodeId &&
                  (Boolean(a.deliverable) || !requestsView),
              ),
            ])
            .filter(([, , a]) => a.length > 0) as any,
        );
        break;
      case 'Asset Type':
        setGroupedAssets(
          [
            ...Object.entries(project.localizationServices)
              .filter(([, b]) => b)
              .sort(([a], [b]) => (a > b ? 1 : a < b ? -1 : 0))
              .map(
                ([service]) =>
                  [
                    `Localization ・ ${localizationCategoryMap[service]}`,
                    `Loc${service}`,
                    assets.filter(
                      (a) =>
                        a.category === service && a.group === 'LOCALIZATION',
                    ),
                  ] as const,
              ),
            ...(requestsView
              ? []
              : Object.entries(project.originalPostProductionServices)
                  .filter(([, b]) => b)
                  .sort(([a], [b]) => (a > b ? 1 : a < b ? -1 : 0))
                  .map(
                    ([service]) =>
                      [
                        `Post Production ・ ${postProductionCategoryMap[service]}`,
                        `Post${service}`,
                        assets.filter(
                          (a) =>
                            a.category === service &&
                            a.group === 'POST_PRODUCTION',
                        ),
                      ] as const,
                  )),
          ].filter(([, , a]) => a.length > 0) as any,
        );
        break;
      case 'Language':
        setGroupedAssets(
          [...new Set(assets.map((ass) => ass.language))]
            .filter(Boolean)
            .sort()
            .map(
              (language) =>
                [
                  language,
                  language!,
                  assets.filter(
                    (a) => a.language === language && Boolean(a.deliverable),
                  ),
                ] as const,
            )
            .filter(([, , a]) => a.length > 0) as any,
        );
        break;
      case 'Status':
        setGroupedAssets(
          [...new Set(assets.map((ass) => ass.status))]
            .sort()
            .map(
              (status) =>
                [
                  <StatusChip status={status} />,
                  status,
                  assets.filter(
                    (a) =>
                      a.status === status &&
                      (Boolean(a.deliverable) || !requestsView),
                  ),
                ] as const,
            )
            .filter(([, , a]) => a.length > 0) as any,
        );
        break;
    }
  }, [
    assets,
    groupingBy,
    project.episodes,
    project.localizationServices,
    project.originalPostProductionServices,
    requestsView,
  ]);

  return [groupedAssets, groupingBy, setGroupingBy] as const;
};
