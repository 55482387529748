import React, { FC } from 'react';

type IconProps = {
  color?: string;
  size: number;
} & React.SVGProps<SVGSVGElement>;

const make = (
  width: number,
  height: number,
  paths: string[],
  colors?: { [key: number]: string },
): FC<IconProps> => (props) => {
  const { color, size, className, ...svgProps } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={(size * height) / width}
      viewBox={`0 0 ${width} ${height}`}
      {...svgProps}
      className={`icon ${className ?? ''}`}
    >
      {paths.map((path, k) => (
        <path
          fillRule="evenodd"
          key={k}
          d={path}
          fill={colors?.[k] ?? color ?? 'currentColor'}
        />
      ))}
    </svg>
  );
};

export const Logo = make(
  96,
  22,
  [
    'M62.0815 0.501129H48.3651L48.3079 5.12574L53.362 5.16869L53.319 20.7893H56.9128L56.9414 5.15437H61.6519L62.0815 0.501129Z',
    'M35.3933 13.5159L32.2434 15.6063C32.2434 15.6063 33.3903 20.9611 39.7745 20.9611C43.8121 20.9611 47.3613 18.3393 47.3199 14.4465C47.2763 10.3374 45.6018 9.79004 44.0269 8.84832C42.4519 7.9066 36.8398 7.48546 37.1114 5.92751C37.4084 4.22371 41.8129 3.02102 44.1987 7.18747L47.4917 4.86801C47.4917 4.86801 46.0654 0.0143127 40.3186 0.0143127C35.9087 0.0143127 33.575 2.47695 33.5034 5.88456C33.337 13.8022 43.0647 11.7548 43.5973 13.8022C44.0568 15.5684 41.8349 16.3758 39.6886 16.451C36.009 16.5799 35.966 14.0456 35.3933 13.5159Z',
    'M23.08 21.047C27.611 21.047 31.284 16.3355 31.284 10.5235C31.284 4.71153 27.611 0 23.08 0C18.549 0 14.876 4.71153 14.876 10.5235C14.876 16.3355 18.549 21.047 23.08 21.047ZM23.08 16.5799C25.6183 16.5799 27.676 13.8683 27.676 10.5235C27.676 7.17864 25.6183 4.46711 23.08 4.46711C20.5417 4.46711 18.484 7.17864 18.484 10.5235C18.484 13.8683 20.5417 16.5799 23.08 16.5799Z',
    'M0 20.6318V0.415192C0.919397 0.472168 1.95919 0.45419 3.04574 0.435404C7.96605 0.350331 13.8452 0.248681 13.8452 7.01564C13.8452 14.2851 8.37977 14.1934 4.89202 14.1348C4.41614 14.1269 3.97707 14.1195 3.59374 14.1315V20.6318H0ZM3.5794 4.85368V9.76464C3.93291 9.76464 4.29152 9.76978 4.64979 9.77491C7.51296 9.81591 10.3546 9.85661 10.3946 7.27337C10.434 4.73292 7.659 4.78489 4.80821 4.83827C4.39745 4.84596 3.98512 4.85368 3.5794 4.85368Z',
    'M72.2469 11.3969C65.4317 11.2394 66.2621 13.0148 66.2621 20.2738L76.0124 20.3168C76.0554 20.3168 76.3847 20.2022 76.4849 19.9731L76.4706 17.6394H69.412C69.5409 14.604 69.412 13.9884 72.2469 13.9884C74.7668 13.9884 76.7283 13.3011 76.7283 9.76464C76.7283 6.22818 73.5784 5.25458 71.9605 5.25458H66.7346V7.91767H71.6885C72.9341 8.08948 73.5463 8.75323 73.5069 9.76464C73.4755 10.5693 72.8767 11.4114 72.2469 11.3969Z',
    'M77.9883 17.5535H81.1668V20.3311H77.9883V17.5535Z',
    'M89.4568 20.3311C95.599 20.3311 95.9999 17.3101 95.9999 12.8C95.9999 8.28995 95.37 5.26892 89.4568 5.26892C83.5436 5.26892 82.9136 8.247 82.9136 12.8C82.9136 17.353 83.3145 20.3311 89.4568 20.3311ZM89.4567 17.6966C92.6421 17.6966 92.85 15.9499 92.85 12.8C92.85 9.65011 92.6496 7.93199 89.4281 7.93199C86.2066 7.93199 86.0634 9.70738 86.0634 12.8C86.0634 15.8926 86.2713 17.6966 89.4567 17.6966Z',
  ],
  {
    0: '#f60100',
    1: '#f60100',
    2: '#f60100',
    3: '#f60100',
  },
);

export const ChevronBack = make(80, 130, [
  'M80 114.725L30.5533 65L80 15.275L64.7773 0L0 65L64.7773 130L80 114.725Z',
]);

export const Bell = make(32, 39, [
  'M16 39.25C18.2 39.25 20 37.45 20 35.25H12C12 37.45 13.78 39.25 16 39.25ZM28 27.25V17.25C28 11.11 24.72 5.97 19 4.61V3.25C19 1.59 17.66 0.25 16 0.25C14.34 0.25 13 1.59 13 3.25V4.61C7.26 5.97 4 11.09 4 17.25V27.25L0 31.25V33.25H32V31.25L28 27.25Z',
]);

export const Filter = make(210, 133, [
  'M81.7778 133H128.222V110.833H81.7778V133ZM0.5 0V22.1667H209.5V0H0.5ZM35.3333 77.5833H174.667V55.4167H35.3333V77.5833Z',
]);

export const Search = make(18, 18, [
  'M12.8645 11.3208H12.0515L11.7633 11.0429C12.7719 9.86964 13.3791 8.34648 13.3791 6.68954C13.3791 2.99485 10.3842 0 6.68954 0C2.99485 0 0 2.99485 0 6.68954C0 10.3842 2.99485 13.3791 6.68954 13.3791C8.34648 13.3791 9.86964 12.7719 11.0429 11.7633L11.3208 12.0515V12.8645L16.4666 18L18 16.4666L12.8645 11.3208V11.3208ZM6.68954 11.3208C4.12693 11.3208 2.05832 9.25214 2.05832 6.68954C2.05832 4.12693 4.12693 2.05832 6.68954 2.05832C9.25214 2.05832 11.3208 4.12693 11.3208 6.68954C11.3208 9.25214 9.25214 11.3208 6.68954 11.3208Z',
]);

export const Exit = make(18, 18, [
  'M7.79 13.29C8.18 13.68 8.81 13.68 9.2 13.29L12.79 9.7C13.18 9.31 13.18 8.68 12.79 8.29L9.2 4.7C8.81 4.31 8.18 4.31 7.79 4.7C7.4 5.09 7.4 5.72 7.79 6.11L9.67 8H1C0.45 8 0 8.45 0 9C0 9.55 0.45 10 1 10H9.67L7.79 11.88C7.4 12.27 7.41 12.91 7.79 13.29ZM16 0H2C0.89 0 0 0.9 0 2V5C0 5.55 0.45 6 1 6C1.55 6 2 5.55 2 5V3C2 2.45 2.45 2 3 2H15C15.55 2 16 2.45 16 3V15C16 15.55 15.55 16 15 16H3C2.45 16 2 15.55 2 15V13C2 12.45 1.55 12 1 12C0.45 12 0 12.45 0 13V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0Z',
]);

export const List = make(18, 10, [
  'M0 6H2V4H0V6ZM0 10H2V8H0V10ZM0 2H2V0H0V2ZM4 6H18V4H4V6ZM4 10H18V8H4V10ZM4 0V2H18V0H4Z',
]);

export const Grid = make(17, 13, [
  'M0 6H5V0H0V6ZM0 13H5V7H0V13ZM6 13H11V7H6V13ZM12 13H17V7H12V13ZM6 6H11V0H6V6ZM12 0V6H17V0H12Z',
]);

export const Comparasion = make(155, 124, [
  'M0 124H108.5V0H0V124Z',
  'M155 0H124V124H155V0Z',
]);

export const SortDescending = make(24, 24, [
  'M19 7H22L18 3L14 7H17V21H19M2 17H12V19H2M6 5V7H2V5M2 11H9V13H2V11Z',
]);

export const SortAscending = make(24, 24, [
  'M19 17H22L18 21L14 17H17V3H19M2 17H12V19H2M6 5V7H2V5M2 11H9V13H2V11Z',
]);
