import { Auth } from 'aws-amplify';

import { takeLatest } from 'src/store/saga';
import * as actions from './actions';

type Action<T extends keyof typeof actions> = ReturnType<typeof actions[T]>;

function logIn(_: Action<'logIn'>) {}

function* logOut(_: Action<'logOut'>) {
  yield Auth.signOut();
}

export function* sessionSaga() {
  yield takeLatest('LOGOUT', logOut);
  yield takeLatest('LOGIN', logIn);
}
