import React, { useState } from 'react';
import {
  IconButton,
  Box,
  Checkbox,
  useTheme,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { KeyboardArrowDown, Search } from '@material-ui/icons';
import { useHistory } from 'react-router';

import CustomTable from 'src/components/CustomTable';
import { timeToLocalDateString, timezoneCity } from 'src/util/date';
import { TextField } from 'src/components/Fields';
import { BidsViewData } from '.';
import { useSelector } from 'src/store';
import { ListItem } from './types';
import { ServiceSection } from './ServiceSection';
import { useData } from './Data';
import { batchInviteToBid } from 'src/services/bids';

export type BidsUnassignedViewProps = {
  projects: E.Project[];
  vendors: E.Vendor[];
  bids: BidsViewData;
};

export const BidsPendingView = ({
  projects,
  vendors,
  bids,
}: BidsUnassignedViewProps) => {
  const history = useHistory();
  const theme = useTheme();
  const user = useSelector((state) => state.session.user!);
  const [creating, setCreating] = useState(false);
  const [list, selected] = useData(projects, vendors, bids);

  return (
    <div
      style={{
        borderRadius: theme.shape.borderRadius,
        transition: 'background-color 0.3s',
        backgroundColor:
          selected.length > 0 ? theme.palette.primary.light : '#424242',
      }}
    >
      <Box
        padding={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <div
          style={{
            padding: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Search style={{ marginRight: theme.spacing(2) }} />
          <TextField placeholder="Search" variant="standard" color="primary" />
        </div>
        {selected.length > 0 && (
          <div>
            <Typography
              color="inherit"
              variant="subtitle1"
              component="span"
              style={{ marginRight: theme.spacing(4) }}
            >
              {selected.length} selected
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={async () => {
                setCreating(true);
                try {
                  await batchInviteToBid(selected);
                  history.push('/bids/complete');
                } catch (e) {
                  alert(
                    'Something went wrong: ' + e?.message ?? 'UNKOWN ERROR',
                  );
                  setCreating(false);
                }
              }}
              disableElevation
              disabled={creating}
            >
              {creating && (
                <CircularProgress
                  size={16}
                  style={{ marginRight: theme.spacing(2) }}
                />
              )}
              Invite to bid
            </Button>
          </div>
        )}
      </Box>
      <CustomTable<ListItem>
        defaultAlign="left"
        columns={[
          {
            id: 'project',
            label: <span style={{ width: 150 }}>Project</span>,
            render: (row) => (
              <>
                <Checkbox
                  checked={row.selected}
                  onChange={() => row.toggleSelection()}
                  color="default"
                  style={{ margin: '-12px 0 -12px -8px' }}
                  disabled={row.invited}
                />{' '}
                {row.project.title}
              </>
            ),
            filterValue: (row) => row.project.title,
          },
          {
            id: 'language',
            label: 'Language',
            filterValue: (row) => row.language,
          },
          {
            id: 'status',
            label: 'Status',
            render: ({ status }) =>
              status === 'PENDING' ? `Pending invitation` : 'Invited',
            filterValue: ({ status }) =>
              status === 'PENDING' ? `Pending invitation` : 'Invited',
          },
          {
            id: 'dueDate',
            label: <span style={{ whiteSpace: 'nowrap' }}>Due Date</span>,
            render: ({ dueDate: [d, tz] }) => (
              <span
                title={timeToLocalDateString(d, tz) + ` (${timezoneCity(tz)})`}
              >
                {timeToLocalDateString(d, user.timezone)}
              </span>
            ),
            filterValue: ({ dueDate: [d] }) =>
              timeToLocalDateString(d, user.timezone),
          },
          {
            id: '_' as any,
            label: '',
            disablePaddingBody: true,
            disablePaddingHeader: true,
            render: (_, expand, isExpanded) => (
              <div style={{ textAlign: 'right' }}>
                <IconButton onClick={expand}>
                  <KeyboardArrowDown
                    style={{
                      transform: isExpanded ? 'rotate(-180deg)' : '',
                      transition: 'transform 0.3s',
                    }}
                  />
                </IconButton>
              </div>
            ),
          },
        ]}
        renderExpanded={({ dubbing, scripting, subtitling, qc }) => {
          return (
            <>
              {dubbing && dubbing.vendors.length > 0 && (
                <ServiceSection
                  selected={dubbing.selected}
                  toggleSelection={dubbing.toggleSelection}
                  title="Dubbing"
                  vendors={dubbing.vendors}
                  category="dubbing"
                  invited={dubbing.invited}
                />
              )}
              {scripting && scripting.vendors.length > 0 && (
                <ServiceSection
                  selected={scripting.selected}
                  toggleSelection={scripting.toggleSelection}
                  title="Scripting"
                  vendors={scripting.vendors}
                  category="scripting"
                  invited={scripting.invited}
                />
              )}
              {subtitling && subtitling.vendors.length > 0 && (
                <ServiceSection
                  selected={subtitling.selected}
                  toggleSelection={subtitling.toggleSelection}
                  title="Subtitling"
                  vendors={subtitling.vendors}
                  category="subtitling"
                  invited={subtitling.invited}
                />
              )}
              {qc && qc.vendors.length > 0 && (
                <ServiceSection
                  selected={qc.selected}
                  toggleSelection={qc.toggleSelection}
                  title="QC"
                  vendors={qc.vendors}
                  invited={qc.invited}
                  category="qc"
                />
              )}
            </>
          );
        }}
        rows={list}
        defaultOrder="asc"
        defaultOrderBy="dueDate"
      />
    </div>
  );
};
