import React, { useMemo, ReactNode } from 'react';

export const Watcher = ({
  values,
  render,
}: {
  values: any[];
  render: () => ReactNode;
}) => {
  const memo = useMemo(() => {
    return render();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, values);
  return <>{memo}</>;
};
