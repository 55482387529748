import React, { useState, useEffect } from 'react';
import {
  Button,
  Typography,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { CloudUpload, Close } from '@material-ui/icons';

import { SelectField } from 'src/components/Fields';
import { makeStyles } from 'src/styles';
import { useAspera } from 'src/hooks/aspera';
import { AsperaFile } from 'src/services/aspera';
import { CircularProgressWithLabel } from 'src/components/Progress';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  dropIcon: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(6, 0, 11),
    cursor: 'pointer',
    width: '100%',
    transition: 'background 0.3s',
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.03)',
    },
  },
  fileList: {
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.primary.light,
    width: '100%',
  },
  fileListHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(3),
  },
  versionUpload: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& label + .MuiInput-formControl': {
      marginTop: 0,
    },
    '& button': {
      margin: theme.spacing(3),
    },
  },
  fileListFile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(4, 3),
    '&:nth-child(2n)': {
      background: 'rgba(0, 0, 0, 0.1)',
    },
  },
}));

export type UploadProps = {
  id: string;
  files: (AsperaFile & {
    progress?: number;
  })[];
  onChange: (files: UploadProps['files']) => unknown;
  onStartTransfer: () => unknown;
  transferring?: boolean;
};

export const Upload = ({
  id,
  files,
  onChange,
  onStartTransfer,
  transferring,
}: UploadProps) => {
  const aspera = useAspera();
  const css = useStyles();
  const [version, setVersion] = useState<number>();

  const _id = `upload_${id}`;

  useEffect(() => {
    aspera.addDragDropTarget(`#${_id}`);
    const removeDropHandler = aspera.onDrop((ev, _files) => {
      const el = ev.target as HTMLElement;
      if (el.matches(`#${_id}, #${_id} *`))
        onChange([
          ...files,
          ..._files.filter((f) => !files.some((q) => q.name === f.name)),
        ]);
    });
    return () => {
      aspera.removeDragDropTarget(`#${_id}`);
      removeDropHandler();
    };
  }, [_id, aspera, files, onChange]);

  return (
    <div className={css.wrapper} id={`upload_${id}`}>
      <div
        className={css.dropIcon}
        onClick={() => {
          aspera.showFileDialog((_files) => {
            onChange([
              ...files,
              ..._files.filter((f) => !files.some((q) => q.name === f.name)),
            ]);
          });
        }}
      >
        <CloudUpload style={{ width: 110, height: 'auto' }} />
        <Typography>Drop files here</Typography>
      </div>
      <div className={css.fileList}>
        <div className={css.fileListHeader}>
          <Typography>
            {files.length === 0
              ? `No file selected`
              : files.length === 1
              ? `1 file selected`
              : `${files.length} files selected`}
          </Typography>
          <div className={css.versionUpload}>
            <SelectField
              empty
              emptyLabel="Version #"
              style={{
                gridColumn: 'auto / span 2',
              }}
              options={[1, 2, 3, 4, 5, 6, 7, 8, 9].map((version) => ({
                label: `Version ${version}`,
                value: version.toString(),
              }))}
              value={version?.toString() ?? ''}
              onChange={(value) => setVersion(Number(value))}
              variant="standard"
            />
            <Button
              variant="contained"
              color="secondary"
              type="button"
              disableElevation
              disabled={!version || files.length === 0 || transferring}
              onClick={() => onStartTransfer()}
            >
              {transferring ? (
                <>
                  <CircularProgress size={16} style={{ marginRight: 6 }} />
                  Uploading...
                </>
              ) : (
                <>
                  <CloudUpload
                    style={{ width: 18, height: 18, marginRight: 6 }}
                  />
                  Start Upload
                </>
              )}
            </Button>
          </div>
        </div>
        {files.map((file) => (
          <div key={file.name} className={css.fileListFile}>
            <Typography>{file.name.split(/\/|\\/).reverse()[0]}</Typography>
            {file.progress === undefined ? (
              <IconButton
                size="small"
                onClick={() =>
                  onChange(files.filter((f) => f.name !== file.name))
                }
              >
                <Close style={{ width: 18, height: 'auto' }} />
              </IconButton>
            ) : (
              <CircularProgressWithLabel value={file.progress * 100} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
