import React, { useState } from 'react';
import {
  Paper,
  Typography,
  Button,
  Box,
  useTheme,
  CircularProgress,
} from '@material-ui/core';
import { Redirect, useHistory } from 'react-router';

import { useDispatch, useSelector } from 'src/store';
import { logIn } from 'src/store/session/actions';
import { Logo } from 'src/components/Icons';
import { TextField } from 'src/components/Fields';
import { signIn } from 'src/services/auth';

export const LoginPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.session);

  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState<string>();

  const theme = useTheme();

  if (loggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Box
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        minHeight: '100%',
        padding: theme.spacing(8),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper elevation={12}>
        <Box
          p={6}
          display="grid"
          gridTemplateColumns="1fr"
          gridGap={theme.spacing(4)}
          component="form"
          onSubmit={(ev) => {
            ev.preventDefault();
            setLoading(true);

            const form = ev.currentTarget as any;
            const email = form.email.value as string;
            const password = form.password.value as string;

            signIn(email, password)
              .then((res) => {
                if (res.updatePassword) {
                  history.push(
                    `/signup?email=${email}&tempPassword=${password}`,
                  );
                } else {
                  dispatch(logIn());
                }
              })
              .catch((err) => {
                setLoginError(err.message ?? 'Something went wrong');
                setLoading(false);
              });
          }}
        >
          <Typography
            component="h1"
            variant="h3"
            style={{
              fontWeight: 'bold',
              display: 'flex',
              flexDirection: 'column',
              lineHeight: 1,
              marginBottom: theme.spacing(4),
            }}
          >
            <Typography
              variant="body1"
              component="span"
              style={{ fontWeight: 'normal' }}
            >
              Welcome to{' '}
            </Typography>
            ProForma
            <Logo size={72} style={{ alignSelf: 'flex-end' }} />
          </Typography>
          {loading ? (
            <CircularProgress style={{ justifySelf: 'center' }} />
          ) : (
            <>
              <Typography
                variant="body1"
                align="center"
                style={{ marginBottom: theme.spacing(4) }}
                color={loginError ? 'error' : undefined}
              >
                {loginError || 'Please sign-in to continue.'}
              </Typography>
              <TextField label="E-mail" name="email" type="email" required />
              <TextField
                label="Password"
                name="password"
                type="password"
                required
              />
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disableElevation
              >
                Sign-in
              </Button>
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );
};
