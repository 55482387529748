import React from 'react';
import { Chip, ChipProps } from '@material-ui/core';

import { statusColorMap, BidStatus } from '../common';

export const BidStatusChip = ({
  status,
  currentStatus,
  ...chipProps
}: ChipProps & { status: BidStatus; currentStatus?: BidStatus }) => (
  <Chip
    label={status}
    size="medium"
    style={{
      backgroundColor: statusColorMap[status],
      opacity: currentStatus === status ? 1 : 0.6,
      ...chipProps.style,
    }}
    {...chipProps}
  />
);
