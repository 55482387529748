import React, { FC, useCallback, ReactNode, CSSProperties } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { Box, useTheme, IconButton } from '@material-ui/core';
import { CloudUpload, Close } from '@material-ui/icons';

export const Dropzone: FC<
  {
    renderEmpty?: () => ReactNode;
    renderAfterDrop(files: File[]): ReactNode;
    renderDragActive: () => ReactNode;
    containerStyle?: CSSProperties;
    files: File[];
    onChange: (files: File[]) => unknown;
    label?: ReactNode;
  } & DropzoneOptions
> = ({
  renderAfterDrop,
  renderEmpty,
  renderDragActive,
  containerStyle,
  files,
  onChange,
  label,
  ...dropzoneOptions
}) => {
  const theme = useTheme();

  const onDrop: NonNullable<DropzoneOptions['onDrop']> = useCallback(
    (acceptedFiles) => {
      onChange(acceptedFiles);
    },
    [onChange],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...dropzoneOptions,
    onDrop,
  });

  return (
    <div {...getRootProps()}>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          cursor: 'pointer',
          backgroundColor: theme.palette.primary.light,
          padding: theme.spacing(4),
          borderRadius: theme.shape.borderRadius,
          lineHeight: 1.15,
          textAlign: 'center',
          ...containerStyle,
        }}
      >
        <input {...getInputProps()} />
        {isDragActive
          ? renderDragActive()
          : (dropzoneOptions.multiple || files.length === 0) &&
            (renderEmpty?.() ?? (
              <>
                <CloudUpload style={{ width: 44, height: 44 }} />
                {label || 'Drop image here'}
              </>
            ))}
        {files.length > 0 && renderAfterDrop(files)}
      </Box>
    </div>
  );
};

export const DropzoneAfterDrop: FC<{
  imgSrc: string;
  onDelete: () => unknown;
}> = ({ imgSrc, onDelete, children }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        height: '100%',
        transform: 'translate(-50%, -50%)',
        padding: theme.spacing(4),
        textAlign: 'center',
      }}
    >
      <img
        src={imgSrc}
        alt=""
        style={{
          borderRadius: theme.shape.borderRadius,
          height: '100%',
        }}
      />
      <IconButton
        size="small"
        style={{
          position: 'absolute',
          bottom: '8px',
          left: '50%',
          background: '#9c0808',
          margin: '0',
          transform: 'translateX(-50%)',
        }}
        onClick={(ev) => {
          ev.stopPropagation();
          onDelete();
        }}
      >
        <Close />
      </IconButton>
      {children}
    </div>
  );
};
