import React, { ReactNode, Fragment } from 'react';

import { makeStyles, c } from 'src/styles';
import { Box, Typography, useTheme } from '@material-ui/core';
import {
  deserializeDateWithTimezone,
  timeToLocalDateString,
  timezoneCity,
} from 'src/util/date';
import { useSelector } from 'src/store';

const useHeaderStyles = makeStyles((theme) => ({
  root: c({
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.main,
    overflow: 'hidden',
    display: 'grid',
    gridTemplateColumns: '400px auto auto',
    gridGap: theme.spacing(4),
    '& .header__image': c({
      position: 'relative',
      width: 400,
      margin: -theme.spacing(4),
      '& img': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    }),
    '& .header__info': c({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '& dl': c({
        gridColumnGap: theme.spacing(2),
        gridAutoFlow: 'column',
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'repeat(6, auto)',
        '& dd': c({
          marginBottom: theme.spacing(2),
        }),
        '& dd, & dt': c({
          width: '134px',
        }),
      }),
      '& .header__links': c({
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: theme.spacing(2),
      }),
    }),
  }),
}));

export type ProjectHeaderProps = {
  project: E.Project;
  buttons?: ReactNode;
  customStats?: { label: ReactNode; description: ReactNode }[];
};

export const ProjectHeader = ({
  project,
  buttons,
  customStats,
}: ProjectHeaderProps) => {
  const theme = useTheme();
  const headerStyles = useHeaderStyles();
  const user = useSelector((state) => state.session.user!);
  return (
    <Box className={headerStyles.root}>
      <Box className="header__image">
        <img src={project.coverImage} alt={project.title} />
      </Box>
      <Box>
        <Typography variant="subtitle1" component="h2">
          {project.title}
        </Typography>
        <dl>
          <dt>
            <Typography variant="caption">Synopsis</Typography>
          </dt>
          <dd>
            <Typography variant="body2">{project.synopsis}</Typography>
          </dd>
          <dt style={{ marginTop: theme.spacing(4) }}>
            <Typography variant="caption">Principle Cast</Typography>
          </dt>
          <dd>
            <Typography variant="body2">
              {project.characters
                .filter((c) => c.principleCast)
                .map((c) => c.name)
                .join(', ')}
            </Typography>
          </dd>
        </dl>
      </Box>
      <Box className="header__info">
        <dl>
          {customStats?.map(({ label, description }, k) => (
            <Fragment key={k}>
              <dt>
                <Typography variant="caption">{label}</Typography>
              </dt>
              <dd>
                <Typography>{description}</Typography>
              </dd>
            </Fragment>
          )) ?? (
            <>
              <dt>
                <Typography variant="caption">Episodes</Typography>
              </dt>
              <dd>
                <Typography>{project.episodes.length}</Typography>
              </dd>
              <dt>
                <Typography variant="caption">
                  Final Deliverables Due Date
                </Typography>
              </dt>
              <dd>
                {(() => {
                  const [date, tz] = deserializeDateWithTimezone(
                    project.finalDeliverablesDueDate,
                  );
                  return (
                    <Typography
                      title={
                        timeToLocalDateString(date, tz) +
                        ` (${timezoneCity(tz)})`
                      }
                    >
                      {timeToLocalDateString(date, user.timezone)}
                    </Typography>
                  );
                })()}
              </dd>
              <dt>
                <Typography variant="caption">Casting Status</Typography>
              </dt>
              <dd>
                <Typography>
                  {project.castingStatus === 'IN_PROGRESS'
                    ? 'In Progress'
                    : project.castingStatus === 'CASTING_COMPLETE'
                    ? 'Casting Complete'
                    : 'Waiting For Approval'}
                </Typography>
              </dd>
              <dt>
                <Typography variant="caption">Episode Runtime</Typography>
              </dt>
              <dd>
                <Typography>{project.runtime} min</Typography>
              </dd>
              <dt>
                <Typography variant="caption">
                  Post Production Start Date
                </Typography>
              </dt>
              <dd>
                {(() => {
                  const [date, tz] = deserializeDateWithTimezone(
                    project.postProductionStartDate,
                  );
                  return (
                    <Typography
                      title={
                        timeToLocalDateString(date, tz) +
                        ` (${timezoneCity(tz)})`
                      }
                    >
                      {timeToLocalDateString(date, user.timezone)}
                    </Typography>
                  );
                })()}
              </dd>
            </>
          )}
        </dl>
        <Box className="header__links">{buttons}</Box>
      </Box>
    </Box>
  );
};
