import React, { ReactNode } from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
} from '@material-ui/core';
import { Star, ExpandMore } from '@material-ui/icons';

import { useStyles } from './styles';
import { VendorInfo } from './types';

export const ServiceSection = ({
  title,
  vendors,
  category,
}: {
  title: ReactNode;
  category: 'dubbing' | 'qc' | 'subtitling' | 'scripting';
  vendors: VendorInfo[];
}) => {
  const classes = useStyles();
  return (
    <>
      <Accordion
        className={classes.root}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" className="comparasion">
            {vendors.length === 0 ? (
              <Typography className="empty-category">
                No invitations have been sent for this category yet.
              </Typography>
            ) : (
              vendors.map((vendor) => {
                const categoryInfo = vendor[category];
                return (
                  <div key={vendor.vendorId} className="comparasion-item">
                    <div className="comparasion-item__header">
                      <div
                        className="comparasion-item__background"
                        style={{
                          backgroundImage: `url(${vendor.logo})`,
                        }}
                      />
                      <div className="comparasion-item__header-content">
                        <Checkbox
                          color="default"
                          checked={categoryInfo?.selected}
                          onChange={() => categoryInfo?.toggleSelection()}
                        />
                        <div
                          style={{
                            textAlign: 'center',
                            flex: 1,
                            paddingRight: 30,
                          }}
                        >
                          {vendor.logo ? (
                            <img
                              src={vendor.logo}
                              alt={vendor.name}
                              className="comparasion-item__logo"
                            />
                          ) : (
                            <Typography variant="body1">
                              {vendor.name}
                            </Typography>
                          )}
                          <div className="comparasion-item__rating">
                            {[0, 0, 0, 0, 0]
                              .slice(0, Math.round(vendor.rating ?? 0))
                              .map((_, k) => (
                                <Star key={k} style={{ width: 18 }} />
                              ))}
                            ・
                            <Typography variant="body2">
                              {vendor.location}
                            </Typography>
                          </div>
                        </div>
                        {categoryInfo?.bidSent ? (
                          <Typography
                            className="status-label"
                            style={{ textAlign: 'right' }}
                          >
                            TOTAL $ {categoryInfo?.price}
                          </Typography>
                        ) : (
                          <Typography className="status-label">
                            PENDING
                          </Typography>
                        )}
                      </div>
                    </div>
                    {categoryInfo?.services.map((service) => (
                      <div style={{ paddingRight: 12 }} key={service.id}>
                        <div className="comparasion-item__detail">
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <span>
                              <Checkbox
                                color="default"
                                checked={service.selected}
                                onChange={() => service.toggleSelection()}
                              />
                              {service.label}
                            </span>
                            <span className="comparasion-item__price">
                              {categoryInfo.bidSent ? (
                                <>$ {service.price}</>
                              ) : (
                                '--'
                              )}
                            </span>
                          </div>
                          {service.services?.map((subService) => (
                            <div
                              style={{
                                marginRight: 24,
                                marginLeft: 24,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                              key={subService.id}
                            >
                              <span>
                                <Checkbox
                                  color="default"
                                  checked={subService.selected}
                                  onChange={() => subService.toggleSelection()}
                                />
                                {subService.label}
                              </span>
                              <span className="comparasion-item__price">
                                {categoryInfo.bidSent ? (
                                  <>$ {subService.price}</>
                                ) : (
                                  '--'
                                )}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
