import { http } from '../http';

export const uploadFile = async (
  ext: string,
  blob: Blob,
  onProgress?: (progress: number) => unknown,
) => {
  try {
    const { data } = await http.get<{ uploadUrl: string; downloadUrl: string }>(
      '/uploads/getSignedUrl',
      {
        params: {
          ext,
          contentType: blob.type,
        },
      },
    );
    await http.put(data.uploadUrl, await blob.arrayBuffer(), {
      ...{
        headers: {
          'Content-Type': blob.type,
        },
        onUploadProgress: (progress) => {
          onProgress?.(progress);
        },
      },
      ...({
        disableAutomaticAuthorizationHandling: true,
      } as any),
    });
    return data.downloadUrl;
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};

export const requestAsperaUpload = async (
  assetId: string,
  projectId: string,
  episodeId: string,
) => {
  try {
    const { data } = await http.get('/uploads/aoc-upload', {
      params: {
        assetId,
        projectId,
        episodeId,
      },
    });
    return data;
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};
