import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

export const timeToLocalDateString = (
  time: Date | number | string,
  localTz: string,
) => {
  const zonedTime = utcToZonedTime(time, localTz);
  return [
    zonedTime.toLocaleDateString(),
    zonedTime
      .toLocaleTimeString()
      .replace(/:[0-9]{2}( |$)/, ' ')
      .trim(),
  ].join(' ');
};

export const daysRelative = (time: number, days: number) =>
  new Date(new Date(time).setDate(new Date(time).getDate() + days));

export const serializeDateWithTimezone = (
  date: number | string | Date,
  timezone: string,
) => {
  return `${zonedTimeToUtc(date, timezone).toISOString()} ${timezone}`;
};

export const deserializeDateWithTimezone = (dateStr: string) => {
  const [utcDate, timezone] = dateStr.split(' ');
  return [new Date(utcDate), timezone] as [Date, string];
};

export const prepareForDateField = (date: string | number | Date, tz: string) =>
  utcToZonedTime(date, tz);

export const timezoneCity = (tzStr: string) =>
  tzStr.replace(/_/g, ' ').split('/').reverse()[0];

export { timezones } from './timezones';
