import React, { useMemo, useState, useCallback, useEffect } from 'react';
import {
  Button,
  Box,
  useTheme,
  Typography,
  MenuItem,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useParams } from 'react-router';

import { useAppFrameOptions } from 'src/hooks/navigation';
import { Fieldset } from 'src/components/Fieldset';
import { TextField } from 'src/components/Fields';
import { Watcher } from 'src/components/Watcher';
import { Loader } from 'src/components/Loader';
import {
  postProductionCategoryMap,
  localizationCategoryMap,
  localizationServicesDetailedMap,
} from 'src/util/common';
import { CloudDownload } from '@material-ui/icons';
import { getBid, updateBid } from 'src/services/bids';
import { getProject } from 'src/services/projects';
import { ProjectHeader } from 'src/components/ProjectHeader';
import CustomTable from 'src/components/CustomTable';
import {
  deserializeDateWithTimezone,
  timeToLocalDateString,
  timezoneCity,
} from 'src/util/date';
import { useSelector } from 'src/store';

const currencies = ['USD ($)', 'EUR (€)', 'GBP (£)', 'YEN (¥)'];
const exchangeRates: Record<string, number> = {
  'USD ($)': 1,
  'EUR (€)': 1.17,
  'GBP (£)': 1.29,
  'YEN (¥)': 0.0095,
};

type FormData = Partial<E.Bid>;

export const VendorFormPage = () => {
  const theme = useTheme();
  const user = useSelector((state) => state.session.user!);
  const { projectId, bidId } = useParams<{
    bidId: string;
    projectId: string;
  }>();

  useAppFrameOptions(
    useMemo(
      () => ({
        title: 'Submit Bid',
      }),
      [],
    ),
  );

  const [working, setWorking] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(true);
  const [formData, setFormData] = useState<FormData>({});
  const [project, setProject] = useState({} as E.Project);
  const [loading, setLoading] = useState<boolean | -1>(true);

  useEffect(() => {
    Promise.all([
      getBid(projectId, bidId).then(setFormData),
      getProject(projectId).then(setProject),
    ])
      .then(() => setLoading(false))
      .catch(() => setLoading(-1));
  }, [bidId, projectId]);

  const update = useCallback(function <T extends keyof FormData>(
    k: T,
    v: FormData[T],
  ) {
    setFormData((prev) => ({
      ...prev,
      [k]: v,
    }));
  },
  []);

  let total = Object.values(formData.localizationServices ?? {})
    .filter(Boolean)
    .flatMap((v) => Object.values(v!))
    .reduce((s, x) => s + x, 0);

  return Boolean(loading) ? (
    <Loader error={loading === -1} loading={loading === true} />
  ) : (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box margin={8}>
        <ProjectHeader
          project={project}
          customStats={[
            {
              label: 'Director',
              description: project.director,
            },
            {
              label: 'Episodes',
              description: project.episodes.length,
            },
            {
              label: 'Start Date',
              description: (() => {
                const [d, tz] = deserializeDateWithTimezone(
                  project.postProductionStartDate,
                );
                return (
                  <span
                    title={
                      timeToLocalDateString(d, tz) + ` ${timezoneCity(tz)}`
                    }
                  >
                    {timeToLocalDateString(d, user.timezone)}
                  </span>
                );
              })(),
            },
            {
              label: 'Bidding Due Date',
              description: (() => {
                const [d, tz] = deserializeDateWithTimezone(
                  project.bidDueDate!,
                );
                return (
                  <span
                    title={
                      timeToLocalDateString(d, tz) + ` ${timezoneCity(tz)}`
                    }
                  >
                    {timeToLocalDateString(d, user.timezone)}
                  </span>
                );
              })(),
            },
            {
              label: 'Episodic Runtime',
              description: project.runtime + ' minutes',
            },
            {
              label: 'Due Date',
              description: (() => {
                const [d, tz] = deserializeDateWithTimezone(
                  project.finalDeliverablesDueDate,
                );
                return (
                  <span
                    title={
                      timeToLocalDateString(d, tz) + ` ${timezoneCity(tz)}`
                    }
                  >
                    {timeToLocalDateString(d, user.timezone)}
                  </span>
                );
              })(),
            },
          ]}
        />
        <Box
          component="form"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          mt={6}
          padding={6}
          bgcolor={theme.palette.primary.main}
          borderRadius={theme.shape.borderRadius}
          gridGap={theme.spacing(6)}
          onSubmit={async (ev) => {
            ev.preventDefault();
            setWorking(true);
            try {
              const data = { ...formData };
              await updateBid({
                ...data,
                bidId,
                projectId,
                submittedAt: `${new Date().toISOString()} ${user.timezone}`,
              });
              window.location.reload();
            } catch (err) {
              alert('Something went wrong: ' + err?.message ?? 'UNKOWN ERROR');
            } finally {
              setWorking(false);
            }
          }}
        >
          <Watcher
            values={[termsAccepted]}
            render={() => (
              <Fieldset
                variant="box"
                label="Terms and Conditions"
                gridColumn="1 / -1"
              >
                <iframe
                  src="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                  title="Terms and Conditions"
                  style={{
                    border: 'none',
                    width: '100%',
                    height: 200,
                  }}
                ></iframe>
                <label>
                  <Checkbox
                    color="default"
                    checked={termsAccepted}
                    onChange={(ev) =>
                      setTermsAccepted(ev.currentTarget.checked)
                    }
                    style={{ marginLeft: -9 }}
                  />{' '}
                  I agree with the terms and conditions.
                </label>
              </Fieldset>
            )}
          />
          {termsAccepted && (
            <>
              <Watcher
                values={[]}
                render={() => (
                  <Fieldset
                    variant="box"
                    gridColumn="1 / -1"
                    label="Reference Materials"
                  >
                    {project.bidReferenceMaterials ? (
                      <CustomTable<{
                        id: string;
                        downloadUrl: string;
                        name: string;
                      }>
                        rows={project.bidReferenceMaterials.map((f) => ({
                          downloadUrl: f,
                          name: f.split('/').reverse()[0],
                          id: f,
                        }))}
                        disablePagination
                        defaultAlign="left"
                        columns={[
                          {
                            id: '_' as any,
                            label: '',
                            disablePaddingBody: true,
                            disablePaddingHeader: true,
                            bodyCellStyle: {
                              width: 56,
                              paddingLeft: 8,
                            },
                            render: (row) => (
                              <IconButton
                                onClick={() => {
                                  window.open(row.downloadUrl);
                                }}
                              >
                                <CloudDownload />
                              </IconButton>
                            ),
                          },
                          {
                            id: 'name',
                            label: 'Material',
                          },
                        ]}
                        defaultOrder="asc"
                        defaultOrderBy="name"
                      />
                    ) : (
                      '--'
                    )}
                  </Fieldset>
                )}
              />
              <Watcher
                values={[]}
                render={() => (
                  <Fieldset variant="box" gridColumn="1 / -1" label="Services">
                    <Typography variant="body1">
                      Please uncheck the services that you will not be able to
                      fulfill.
                    </Typography>
                    {Object.entries(localizationCategoryMap)
                      .filter(
                        ([s]) => s in (formData.localizationServices ?? {}),
                      )
                      .map(([key, service]) => (
                        <FormControlLabel
                          key={key}
                          control={<Checkbox color="default" />}
                          label={service}
                          checked={true}
                        />
                      ))}
                    {Object.entries(postProductionCategoryMap)
                      .filter(
                        ([s]) =>
                          s in (formData.originalPostProductionServices ?? {}),
                      )
                      .map(([key, service]) => (
                        <FormControlLabel
                          key={key}
                          control={<Checkbox color="default" />}
                          label={service}
                          checked={true}
                        />
                      ))}
                  </Fieldset>
                )}
              />
              <Watcher
                values={[formData.currency]}
                render={() => (
                  <Fieldset variant="box" label="Pricing">
                    <Box display="flex">
                      <TextField
                        select
                        value={formData.currency ?? '_'}
                        onChange={(ev) =>
                          update('currency', ev.target.value as any)
                        }
                        label="Local Currency"
                        style={{ width: 160 }}
                      >
                        <MenuItem value="_">Select</MenuItem>
                        {currencies.map((currency) => (
                          <MenuItem key={currency} value={currency}>
                            {currency}
                          </MenuItem>
                        ))}
                      </TextField>
                      <TextField
                        style={{ flex: 1, marginLeft: theme.spacing(4) }}
                        label="Exchange Rate"
                        disabled
                        value={
                          formData.currency
                            ? `1.00 ${formData.currency} = $ ${
                                exchangeRates[formData.currency]
                              } USD`
                            : ''
                        }
                      />
                    </Box>
                  </Fieldset>
                )}
              />
              <Watcher
                values={[formData.pricingMode]}
                render={() => (
                  <Fieldset variant="box" label="Units">
                    <TextField
                      select
                      value={formData.pricingMode ?? '_'}
                      onChange={(ev) =>
                        update('pricingMode', ev.target.value as any)
                      }
                      label="Please select how you would like to bid for these services."
                      style={{ width: '100%' }}
                    >
                      <MenuItem value="_">Select</MenuItem>
                      {([
                        'FLAT_FEE',
                        'PRICE_PER_MINUTE',
                      ] as E.Bid['pricingMode'][]).map((mode) => (
                        <MenuItem key={mode} value={mode}>
                          {
                            {
                              FLAT_FEE: 'Flat Fee',
                              PRICE_PER_MINUTE: 'Price per Minute',
                            }[mode!]
                          }
                        </MenuItem>
                      ))}
                    </TextField>
                  </Fieldset>
                )}
              />
              <Watcher
                values={[formData.localizationServices]}
                render={() =>
                  Object.entries(localizationCategoryMap)
                    .filter(
                      ([k]) => (formData.localizationServices as any)?.[k],
                    )
                    .map(([_key, group]) => {
                      const key = _key as E.LocalizationService;
                      const subServices = Object.entries(
                        localizationServicesDetailedMap[
                          key as E.LocalizationService
                        ],
                      );
                      return (
                        <Fieldset
                          key={key}
                          variant="box"
                          label={group}
                          gridRow={`auto / span ${subServices.length + 1}`}
                        >
                          {subServices.map(([k, name], i) => {
                            const value = (formData.localizationServices as any)[
                              key
                            ][k];
                            return (
                              <Watcher
                                values={[value]}
                                key={k}
                                render={() => (
                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    mt={5 * Math.sign(i)}
                                  >
                                    <TextField
                                      label={name}
                                      value={value || ''}
                                      style={{
                                        flex: 1,
                                        marginRight: theme.spacing(4),
                                      }}
                                      onChange={(ev) => {
                                        const { value } = ev.currentTarget;
                                        setFormData(
                                          (p) =>
                                            ({
                                              ...p,
                                              localizationServices: {
                                                ...p.localizationServices,
                                                [key]: {
                                                  ...p.localizationServices?.[
                                                    key
                                                  ],
                                                  [k]: Number(value),
                                                },
                                              },
                                            } as any),
                                        );
                                      }}
                                    />
                                    <span
                                      style={{ width: 160, textAlign: 'right' }}
                                    >
                                      Price in USD: ${' '}
                                      {(
                                        value *
                                        exchangeRates[
                                          formData.currency ?? 'USD ($)'
                                        ]
                                      ).toFixed(2)}
                                    </span>
                                  </Box>
                                )}
                              />
                            );
                          })}
                        </Fieldset>
                      );
                    })
                }
              />
              {formData.currency && (
                <Fieldset variant="box" gridColumn="1 / -1" textAlign="center">
                  <Typography variant="body1">
                    Estimated Project Total
                  </Typography>
                  <Typography variant="body1">
                    <span style={{ width: 70, display: 'inline-block' }}>
                      {formData.currency}
                    </span>
                    {total.toFixed(2)}
                  </Typography>
                  <Typography variant="body1">
                    <span style={{ width: 70, display: 'inline-block' }}>
                      USD ($)
                    </span>
                    {(
                      total * exchangeRates[formData.currency ?? 'USD ($)']
                    ).toFixed(2)}
                  </Typography>
                  <br />
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disableElevation
                    disabled={working}
                  >
                    {working && (
                      <CircularProgress
                        size={16}
                        style={{ marginRight: theme.spacing(2) }}
                      />
                    )}
                    Submit
                  </Button>
                </Fieldset>
              )}
            </>
          )}
        </Box>
      </Box>
    </MuiPickersUtilsProvider>
  );
};
