import { makeStyles } from 'src/styles';
import { statusColorMap } from '../common';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    '& .MuiAccordionSummary-root': {
      margin: 0,
      padding: theme.spacing(1, 3, 1, 4),
      background: theme.palette.primary.main,
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      padding: 0,
      alignItems: 'center',
    },
    '& .MuiAccordionDetails-root': {
      margin: 0,
      padding: 0,
      background: theme.palette.primary.light,
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 0,
    },
    '&.MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
    '&.MuiAccordion-root:before': {
      display: 'none',
    },
    '&': {
      boxShadow: 'none',
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
    '& + &': {
      marginTop: '1px !important',
    },
    '& .comparasion': {
      backgroundColor: theme.palette.primary.light,
      flexWrap: 'nowrap',
      overflowX: 'auto',
      width: '100%',
    },
    '& .comparasion-item': {
      borderRight: '1px solid rgba(255, 255, 255, 0.1)',
      width: 280,
    },
    '& .comparasion-item__header': {
      paddingTop: theme.spacing(3),
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 30px inset',
        zIndex: 1,
      },
    },
    '& .comparasion-item__background': {
      position: 'absolute',
      width: '125%',
      height: '125%',
      top: '-12.5%',
      left: '-12.5%',
      filter: 'blur(30px)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundColor: '#000',
      zIndex: 0,
    },
    '& .comparasion-item__header-content': {
      display: 'contents',
      '& > *': {
        position: 'relative',
        zIndex: 2,
      },
    },
    '& .comparasion-item__logo': {
      width: 180,
      height: 32,
      objectFit: 'contain',
    },
    '& .comparasion-item__rating': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .comparasion-item__detail': {
      textAlign: 'right',
      '& + &': {
        marginTop: 12,
      },
    },
    '& .comparasion-item__price': {
      marginLeft: theme.spacing(2),
      fontWeight: 'bold',
    },
    '& .empty-category': {
      padding: theme.spacing(4),
      textAlign: 'center',
      flex: 1,
    },
    '& .status-label': {
      flex: 1,
      width: '100%',
      flexShrink: 0,
      backgroundColor: statusColorMap.OPEN,
      marginTop: theme.spacing(1.5),
      textAlign: 'center',
      color: '#fff',
      padding: theme.spacing(1, 3),
    },
  },
}));
