import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Box, useTheme } from '@material-ui/core';
import { sortBy } from 'lodash';

import { useAppFrameOptions } from 'src/hooks/navigation';
import { getBids } from 'src/services/bids';
import { Loader } from 'src/components/Loader';
import { getVendors } from 'src/services/vendors';
import { getProjects } from 'src/services/projects';
import { BidsHeader } from './Header';
import { BidsGridViewItem } from './GridViewItem';
import { BidsListView } from './ListView';
import { BidStatus } from '../common';

export type BidsViewData = (E.Bid & {
  vendor: E.Vendor;
  project: E.Project;
  bidTotalUsd: number;
  status: BidStatus;
  dueDate: string;
})[];

export type SortField = keyof BidsViewData[0];
export type Order = 'asc' | 'desc';
export type ViewMode = 'compare' | 'grid' | 'list';

export const BidsComplete = () => {
  const theme = useTheme();
  const history = useHistory();

  const [mode, setMode] = useState<ViewMode>('grid');
  const [filterStatus, setFilterStatus] = useState<BidStatus>('OPEN');
  const [orderBy, setOrderBy] = useState<SortField>('dueDate');
  const [order, setOrder] = useState<Order>('asc');
  const [bids, setBids] = useState<BidsViewData | -1>();

  useAppFrameOptions(
    useMemo(
      () => ({
        topbarLeftActions: (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => history.push('/bids/edit')}
            disableElevation
          >
            New Bid
          </Button>
        ),
        title: 'Bids',
      }),
      [history],
    ),
  );

  const sortedBids = useMemo(() => {
    const sorted = sortBy(bids instanceof Array ? bids : [], orderBy);
    return order === 'asc' ? sorted : sorted.reverse();
  }, [order, orderBy, bids]);

  useEffect(() => {
    getBids()
      .then(async (bids) => {
        const [vendors, projects] = await Promise.all([
          getVendors(),
          getProjects(),
        ]);
        setBids(
          bids.map((bid) => {
            const project = projects.find(
              (p) => p.projectId === bid.projectId,
            )!;
            return {
              ...bid,
              bidTotalUsd: [
                ...Object.values(bid.localizationServices ?? {})
                  .filter(Boolean)
                  .flatMap((v) => Object.values(v!)),
                ...Object.values(bid.originalPostProductionServices ?? {})
                  .filter(Boolean)
                  .flatMap((v) => Object.values(v!)),
              ].reduce((s, x) => (x || 0) + (s || 0), 0) as number,
              project,
              vendor: vendors.find((v) => v.vendorId === bid.vendorId)!,
              status: (bid.acceptedAt
                ? 'HIRED'
                : bid.declinedAt
                ? 'DECLINED'
                : bid.submittedAt
                ? 'SUBMITTED'
                : 'OPEN') as BidStatus,
              dueDate: project.bidDueDate!,
            };
          }),
        );
      })
      .catch(() => setBids(-1));
  }, []);

  return bids === -1 || !bids ? (
    <Loader loading={!bids} error={bids === -1} />
  ) : (
    <Box
      padding={8}
      display="grid"
      gridTemplateColumns={mode === 'grid' ? 'repeat(auto-fill, 200px)' : '1fr'}
      justifyContent="center"
      gridGap={theme.spacing(6)}
    >
      <BidsHeader
        {...{
          filterStatus,
          setFilterStatus,
          mode,
          setMode,
          order,
          setOrder,
          orderBy,
          setOrderBy,
        }}
      />
      {mode === 'grid' ? (
        sortedBids
          .filter((b) => b.status === filterStatus)
          .map((bid) => <BidsGridViewItem key={bid.bidId} bid={bid} />)
      ) : (
        <Box gridColumn="1 / -1">
          <BidsListView {...{ bids, filterStatus }} />
        </Box>
      )}
    </Box>
  );
};
