import { nanoid } from 'nanoid';

export type AsperaFile = {
  name: string;
  size: number;
  type: string;
};

interface AsperaConnect {
  new (config: {
    sdkLocation: string;
    minVersion: string;
    id: string;
    dragDropEnabled: boolean;
  }): AsperaConnect;
  addEventListener(
    eventType: unknown,
    listener: (eventType: unknown, data: unknown) => unknown,
  ): {};
  initSession(id: string): void;
  STATUS: {
    STATUS: unknown;
    INITIALIZING: unknown;
    FAILED: unknown;
    OUTDATED: unknown;
    RUNNING: unknown;
  };
  EVENT: {
    STATUS: unknown;
  };
  setDragDropTargets(
    selector: string,
    _: {},
    callback: (p: {
      event: DragEvent;
      files: {
        dataTransfer?: {
          files: AsperaFile[];
        };
      };
    }) => unknown,
  ): void;
  showSelectFileDialog(options: {
    success: (
      t: Parameters<
        Parameters<AsperaConnect['setDragDropTargets']>[2]
      >[0]['files'],
    ) => unknown;
  }): void;
  startTransfer(a: any, b: any, c: any): { request_id: string };
}

export interface Aspera {
  addDragDropTarget(selector: string): void;
  removeDragDropTarget(selector: string): void;
  onDrop: (
    handler: (event: DragEvent, files: AsperaFile[]) => unknown,
  ) => () => void;
  showFileDialog: (handler: (files: AsperaFile[]) => unknown) => unknown;
  onTransfersUpdate: (
    listener: (info: {
      transfers: {
        aspera_connect_settings: {
          request_id: string;
        };
        percentage: number;
        status: string;
        previous_status: string;
        files?: {
          bytes_expected: number;
          bytes_written: number;
          file: string;
        }[];
      }[];
    }) => void,
  ) => () => void;
  upload: (
    spec: any,
    paths: any,
    onError?: (...args: any) => unknown,
    onSuccess?: (transferId: string) => unknown,
  ) => unknown;
  download: (
    spec: any,
    onError?: (...args: any) => unknown,
    onSuccess?: (...args: any) => unknown,
  ) => unknown;
}

const { AW4 } = window as Window &
  typeof globalThis & {
    AW4: {
      Connect: AsperaConnect;
      ConnectInstaller: {
        new (config: { sdkLocation: string }): {
          showLaunching(): void;
          showDownload(): void;
          showUpdate(): void;
          connected(): void;
        };
      };
    };
  };

const initAsperaConnect = function initConnect(
  id: string | number,
  callback: (client: AsperaConnect) => unknown,
) {
  const CONNECT_INSTALLER = '//d3gcli72yxqn2z.cloudfront.net/connect/v4';
  const asperaWeb = new AW4.Connect({
    sdkLocation: CONNECT_INSTALLER,
    minVersion: '3.8.0',
    id: 'aspera_web_transfers-' + id,
    dragDropEnabled: true,
  });
  const asperaInstaller = new AW4.ConnectInstaller({
    sdkLocation: CONNECT_INSTALLER,
  });
  const statusEventListener = function (eventType: unknown, data: unknown) {
    const status = AW4.Connect.STATUS;
    if (eventType === AW4.Connect.EVENT.STATUS) {
      if (data === status.INITIALIZING) {
        asperaInstaller.showLaunching();
      }
      if (data === status.FAILED) {
        asperaInstaller.showDownload();
      }
      if (data === status.OUTDATED) {
        asperaInstaller.showUpdate();
      }
      if (data === status.RUNNING) {
        asperaInstaller.connected();
        callback(asperaWeb);
      }
    }
  };
  asperaWeb.addEventListener(AW4.Connect.EVENT.STATUS, statusEventListener);
  asperaWeb.initSession('nodeConnect-' + id);
};

export const setup = (): Promise<Aspera> =>
  new Promise((resolve) => {
    const asperaId = nanoid();
    initAsperaConnect(asperaId, (asperaWeb) => {
      let statusListeners: Parameters<Aspera['onTransfersUpdate']>[0][] = [];
      let dropHandlers: Parameters<Aspera['onDrop']>[0][] = [];
      let dragNDropTargets: string[] = [];
      const updateDragNDrop = () => {
        asperaWeb.setDragDropTargets(
          dragNDropTargets.join(', ') || '#BACON',
          {},
          ({ files, event }) => {
            dropHandlers.forEach(
              (handler) =>
                files.dataTransfer && handler(event, files.dataTransfer.files),
            );
          },
        );
      };
      asperaWeb.addEventListener('transfer', (_, data) => {
        statusListeners.forEach((listener) => listener(data as any));
      });
      resolve({
        addDragDropTarget: (sel) => {
          if (!dragNDropTargets.includes(sel)) dragNDropTargets.push(sel);
          updateDragNDrop();
        },
        removeDragDropTarget: (sel) => {
          dragNDropTargets = dragNDropTargets.filter((a) => a !== sel);
          updateDragNDrop();
        },
        onDrop: (handler) => {
          dropHandlers.push(handler);
          return () => {
            dropHandlers = dropHandlers.filter((h) => h !== handler);
          };
        },
        onTransfersUpdate: (listener) => {
          statusListeners.push(listener);
          return () => {
            statusListeners = statusListeners.filter((l) => l !== listener);
          };
        },
        showFileDialog: (handler) =>
          asperaWeb.showSelectFileDialog({
            success: function (pathArray) {
              if (!pathArray.dataTransfer?.files.length) return;
              handler(pathArray.dataTransfer.files);
            },
          }),
        upload: (spec, paths, onError, onSuccess) => {
          const { request_id } = asperaWeb.startTransfer(
            {
              ...spec,
              paths,
              authentication: 'token',
            },
            {},
            {
              success: (...args: any) => {
                onSuccess?.(request_id) ?? console.log(...args);
              },
              error: onError ?? console.error,
            },
          );
        },
        download: (spec, onError, onSuccess) =>
          asperaWeb.startTransfer(
            {
              ...spec,
              authentication: 'token',
            },
            {},
            {
              success: onSuccess ?? console.log,
              error: onError ?? console.error,
            },
          ),
      });
    });
  });
