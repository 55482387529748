import { useMemo, useState, useCallback } from 'react';

import { deserializeDateWithTimezone } from 'src/util/date';
import {
  ListItem,
  SelectedItem,
  VendorInfo,
  VendorServiceDetails,
} from './types';
import { localizationServicesDetailedMap } from 'src/util/common';

export const useData = (
  projects: E.Project[],
  vendors: E.Vendor[],
  bids: E.Bid[],
) => {
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
  const selectionProps = useCallback(
    function (
      key: E.LocalizationService,
      vendor: E.Vendor,
      project: E.Project,
      language: E.Language,
      alreadyInvited: boolean,
    ) {
      const vendorSelection = (arr: SelectedItem[] = selectedItems) =>
        arr.find(
          (s) =>
            s.vendorId === vendor.vendorId &&
            s.projectId === project.projectId &&
            s.language === language,
        );
      return {
        selected:
          alreadyInvited ||
          Boolean(vendorSelection()?.localizationServices?.[key]),
        toggleSelection: (status?: boolean) => {
          if (alreadyInvited) return;
          setSelectedItems((s) => {
            const _vendor = vendorSelection(s);
            const selected = _vendor?.localizationServices?.[key];
            const _selected =
              status === undefined ? Boolean(selected) : !status;
            return (_vendor
              ? s.map((t) =>
                  t !== _vendor
                    ? t
                    : {
                        ...t,
                        localizationServices: {
                          ...t.localizationServices,
                          [key]: _selected
                            ? undefined
                            : Object.fromEntries(
                                Object.entries(
                                  (localizationServicesDetailedMap as any)[key],
                                ).map(([k]) => [k, 0]),
                              ),
                        },
                      },
                )
              : !_selected
              ? [
                  ...s,
                  {
                    language,
                    localizationServices: {
                      [key]: Object.fromEntries(
                        Object.entries(
                          (localizationServicesDetailedMap as any)[key],
                        ).map(([k]) => [k, 0]),
                      ),
                    },
                    projectId: project.projectId,
                    vendorId: vendor.vendorId,
                  },
                ]
              : s
            ).filter(
              (s) =>
                Object.values(s.localizationServices ?? {}).filter(Boolean)
                  .length > 0,
            );
          });
        },
      };
    },
    [selectedItems],
  );

  const isInvited = useCallback(
    (
      vendor: E.Vendor,
      project: E.Project,
      language: E.Language,
      service: E.LocalizationService,
    ) =>
      bids.some(
        (bid) =>
          bid.vendorId === vendor.vendorId &&
          bid.projectId === project.projectId &&
          bid.language === language &&
          Boolean(bid.localizationServices?.[service]),
      ),
    [bids],
  );

  const list = useMemo(() => {
    const items: ListItem[] = [];
    projects.forEach((project) => {
      const dueDateTz = deserializeDateWithTimezone(project.bidDueDate!);
      const localizationServices = project.localizationServices;
      new Set<E.Language>([
        ...project.requestedDubbedLanguages,
        ...project.requestedSubtitledLanguages,
      ]).forEach((language) => {
        const dubbingLanguage = project.requestedDubbedLanguages.includes(
          language,
        );
        const subtitlingLanguage = project.requestedSubtitledLanguages.includes(
          language,
        );
        const item: ListItem = {
          language,
          selected: false,
          invited: false,
          toggleSelection: () => {
            item.dubbing?.toggleSelection();
            item.qc?.toggleSelection();
            item.subtitling?.toggleSelection();
          },
          _dueDate_sort: dueDateTz[0].getTime(),
          dueDate: dueDateTz,
          project,
          dubbing: {
            invited: false,
            selected: false,
            toggleSelection: () => {
              item.dubbing?.vendors.forEach((v) =>
                v.dubbing?.toggleSelection(),
              );
            },
            vendors: [],
          },
          qc: {
            invited: false,
            selected: false,
            toggleSelection: () => {
              item.qc?.vendors.forEach((v) => v.qc?.toggleSelection());
            },
            vendors: [],
          },
          subtitling: {
            invited: false,
            selected: false,
            toggleSelection: () => {
              item.subtitling?.vendors.forEach((v) =>
                v.subtitling?.toggleSelection(),
              );
            },
            vendors: [],
          },
          status: 'PENDING',
          id: `${project.projectId}${language}`,
        };
        vendors.forEach((vendor) => {
          if (!vendor.languages.includes(language)) return;
          if (
            !vendor.localizationServices?.some((s) =>
              Object.entries(localizationServices).some(
                ([t, b]) => Boolean(b) && t === s,
              ),
            )
          )
            return;

          const vendorInfo: VendorInfo = {
            ...vendor,
            dubbing: {
              invited: false,
              services: [],
              selected: false,
              toggleSelection: () => {
                vendorInfo.dubbing?.services.forEach((s) => {
                  s.toggleSelection();
                });
              },
            },
            qc: {
              invited: false,
              services: [],
              selected: false,
              toggleSelection: () => {
                vendorInfo.qc?.services.forEach((s) => {
                  s.toggleSelection();
                });
              },
            },
            subtitling: {
              invited: false,
              services: [],
              selected: false,
              toggleSelection: () => {
                vendorInfo.subtitling?.services.forEach((s) => {
                  s.toggleSelection();
                });
              },
            },
          };

          if (dubbingLanguage) {
            const translationServices: VendorServiceDetails['services'] = [];
            const invited = isInvited(vendor, project, language, 'TRANSLATING');
            if (localizationServices.TRANSLATING) {
              translationServices.push({
                id: vendor.vendorId + 'Translation',
                label: 'Translation',
                ...selectionProps(
                  'TRANSLATING',
                  vendor,
                  project,
                  language,
                  invited,
                ),
                unavailable: !vendor.localizationServices?.includes(
                  'TRANSLATING',
                ),
                invited,
              });
            }

            if (localizationServices.ADAPTING) {
              const invited = isInvited(vendor, project, language, 'ADAPTING');
              translationServices.push({
                id: vendor.vendorId + 'Adaptation',
                label: 'Adaptation',
                ...selectionProps(
                  'ADAPTING',
                  vendor,
                  project,
                  language,
                  invited,
                ),
                unavailable: !vendor.localizationServices?.includes('ADAPTING'),
                invited,
              });
            }

            if (translationServices.length > 0) {
              const selected =
                translationServices.filter((s) => s.selected).length ===
                translationServices.length;
              const invited =
                translationServices.filter((s) => s.invited).length ===
                translationServices.length;
              vendorInfo.dubbing?.services.push({
                id: vendor.vendorId + 'Translation',
                label: 'Translation',
                selected,
                toggleSelection: () => {
                  if (invited) return;
                  const sel = !selected;
                  translationServices.forEach((s) => s.toggleSelection(sel));
                },
                unavailable:
                  translationServices.filter((s) => s.unavailable).length ===
                  translationServices.length,
                services: translationServices,
                invited,
              });
            }

            const dubbingServices: VendorServiceDetails['services'] = [];
            if (localizationServices.CASTING) {
              const invited = isInvited(vendor, project, language, 'CASTING');
              dubbingServices.push({
                id: vendor.vendorId + 'Casting',
                label: 'Casting',
                ...selectionProps(
                  'CASTING',
                  vendor,
                  project,
                  language,
                  invited,
                ),
                unavailable: !vendor.localizationServices?.includes('CASTING'),
                invited,
              });
            }

            if (localizationServices.RECORDING) {
              const invited = isInvited(vendor, project, language, 'RECORDING');
              dubbingServices.push({
                id: vendor.vendorId + 'Recording',
                label: 'Recording',
                ...selectionProps(
                  'RECORDING',
                  vendor,
                  project,
                  language,
                  invited,
                ),
                unavailable: !vendor.localizationServices?.includes(
                  'RECORDING',
                ),
                invited,
              });
            }

            if (localizationServices.EDITING) {
              const invited = isInvited(vendor, project, language, 'EDITING');
              dubbingServices.push({
                id: vendor.vendorId + 'Editing',
                label: 'Editing',
                ...selectionProps(
                  'EDITING',
                  vendor,
                  project,
                  language,
                  invited,
                ),
                unavailable: !vendor.localizationServices?.includes('EDITING'),
                invited,
              });
            }

            if (localizationServices.MIXING) {
              const invited = isInvited(vendor, project, language, 'MIXING');
              dubbingServices.push({
                id: vendor.vendorId + 'Mixing',
                label: 'Mixing',
                ...selectionProps('MIXING', vendor, project, language, invited),
                unavailable: !vendor.localizationServices?.includes('MIXING'),
                invited,
              });
            }

            if (dubbingServices.length > 0) {
              const selected =
                dubbingServices.filter((s) => s.selected).length ===
                dubbingServices.length;
              const invited =
                dubbingServices.filter((s) => s.invited).length ===
                dubbingServices.length;
              vendorInfo.dubbing?.services.push({
                id: vendor.vendorId + 'Dubbing',
                label: 'Dubbing',
                selected,
                invited,
                toggleSelection: () => {
                  if (invited) return;
                  const sel = !selected;
                  dubbingServices.forEach((s) => s.toggleSelection(sel));
                },
                unavailable:
                  dubbingServices.filter((s) => s.unavailable).length ===
                  dubbingServices.length,
                services: dubbingServices,
              });
              item.dubbing?.vendors.push(vendorInfo);
            }
          }

          vendorInfo.dubbing!.selected = !vendorInfo.dubbing!.services.some(
            (v) => !v.selected,
          );

          vendorInfo.dubbing!.invited = !vendorInfo.dubbing!.services.some(
            (v) => !v.invited,
          );

          if (subtitlingLanguage && localizationServices.SUBTITLING) {
            const invited = isInvited(vendor, project, language, 'SUBTITLING');
            vendorInfo.subtitling?.services.push({
              id: vendor.vendorId + 'Subtitling',
              label: 'Subtitling',
              ...selectionProps(
                'SUBTITLING',
                vendor,
                project,
                language,
                invited,
              ),
              unavailable: !vendor.localizationServices?.includes('SUBTITLING'),
              invited,
            });
            item.subtitling?.vendors.push(vendorInfo);
          }

          vendorInfo.subtitling!.selected = !vendorInfo.subtitling!.services.some(
            (v) => !v.selected,
          );

          vendorInfo.subtitling!.invited = !vendorInfo.subtitling!.services.some(
            (v) => !v.invited,
          );

          const qcServices: VendorServiceDetails['services'] = [];
          if (localizationServices.LINGUISTIC_QC) {
            const invited = isInvited(
              vendor,
              project,
              language,
              'LINGUISTIC_QC',
            );
            qcServices.push({
              id: vendor.vendorId + 'Linguistic QC',
              label: 'Linguistic QC',
              unavailable: !vendor.localizationServices?.includes(
                'LINGUISTIC_QC',
              ),
              invited,
              ...selectionProps(
                'LINGUISTIC_QC',
                vendor,
                project,
                language,
                invited,
              ),
            });
          }

          if (localizationServices.TECHNICAL_QC) {
            const invited = isInvited(
              vendor,
              project,
              language,
              'TECHNICAL_QC',
            );
            qcServices.push({
              id: vendor.vendorId + 'Technical QC',
              label: 'Technical QC',
              unavailable: !vendor.localizationServices?.includes(
                'TECHNICAL_QC',
              ),
              invited,
              ...selectionProps(
                'TECHNICAL_QC',
                vendor,
                project,
                language,
                invited,
              ),
            });
          }

          if (localizationServices.COMPLETE_QC) {
            const invited = isInvited(vendor, project, language, 'COMPLETE_QC');
            qcServices.push({
              id: vendor.vendorId + 'Complete QC',
              label: 'Complete QC',
              unavailable: !vendor.localizationServices?.includes(
                'COMPLETE_QC',
              ),
              invited,
              ...selectionProps(
                'COMPLETE_QC',
                vendor,
                project,
                language,
                invited,
              ),
            });
          }

          if (qcServices.length > 0) {
            const selected =
              qcServices.filter((s) => s.selected).length === qcServices.length;
            const invited =
              qcServices.filter((s) => s.invited).length === qcServices.length;
            vendorInfo.qc?.services.push({
              id: vendor.vendorId + 'Dubbing QC',
              label: 'Dubbing QC',
              selected,
              invited,
              toggleSelection: () => {
                if (invited) return;
                const sel = !selected;
                qcServices.forEach((s) => s.toggleSelection(sel));
              },
              unavailable:
                qcServices.filter((s) => s.unavailable).length ===
                qcServices.length,
              services: qcServices,
            });
            item.qc?.vendors.push(vendorInfo);
          }

          vendorInfo.qc!.selected = !vendorInfo.qc!.services.some(
            (v) => !v.selected,
          );

          vendorInfo.qc!.invited = !vendorInfo.qc!.services.some(
            (v) => !v.invited,
          );
        });

        item.dubbing!.selected = !item.dubbing!.vendors.some(
          (v) => !v.dubbing?.selected,
        );

        item.dubbing!.invited = !item.dubbing!.vendors.some(
          (v) => !v.dubbing?.invited,
        );

        item.subtitling!.selected = !item.subtitling!.vendors.some(
          (v) => !v.subtitling?.selected,
        );

        item.subtitling!.invited = !item.subtitling!.vendors.some(
          (v) => !v.subtitling?.invited,
        );

        item.qc!.selected = !item.qc!.vendors.some((v) => !v.qc?.selected);

        item.qc!.invited = !item.qc!.vendors.some((v) => !v.qc?.invited);

        item.selected = Boolean(
          item.dubbing?.selected &&
            item.qc?.selected &&
            item.subtitling?.selected,
        );

        item.invited = Boolean(
          item.dubbing?.invited && item.qc?.invited && item.subtitling?.invited,
        );

        items.push(item);
      });

      const item: ListItem = {
        language: 'OV' as E.Language,
        selected: false,
        invited: false,
        toggleSelection: () => {
          item.subtitling?.toggleSelection();
          item.scripting?.toggleSelection();
        },
        _dueDate_sort: dueDateTz[0].getTime(),
        dueDate: dueDateTz,
        project,
        subtitling: {
          invited: false,
          selected: false,
          toggleSelection: () => {
            item.subtitling?.vendors.forEach((v) =>
              v.subtitling?.toggleSelection(),
            );
          },
          vendors: [],
        },
        scripting: {
          invited: false,
          selected: false,
          toggleSelection: () => {
            item.scripting?.vendors.forEach((v) =>
              v.scripting?.toggleSelection(),
            );
          },
          vendors: [],
        },
        status: 'PENDING',
        id: `${project.projectId}OV`,
      };

      vendors.forEach((vendor) => {
        if (!vendor.languages.includes(project.originalVersionLanguage)) return;
        const vendorOffersScripting = Boolean(
          vendor.localizationServices?.includes('SCRIPTING'),
        );
        const vendorOffersSubtitling = Boolean(
          vendor.localizationServices?.includes('SUBTITLING'),
        );
        if (!vendorOffersScripting && !vendorOffersSubtitling) return;
        const invited = isInvited(vendor, project, item.language, 'SCRIPTING');
        const vendorInfo: VendorInfo = {
          ...vendor,
          scripting: {
            invited,
            ...selectionProps(
              'SCRIPTING',
              vendor,
              project,
              item.language,
              invited,
            ),
            services: [
              {
                id: vendor.vendorId + 'Scripting',
                label: 'Scripting',
                ...selectionProps(
                  'SCRIPTING',
                  vendor,
                  project,
                  item.language,
                  invited,
                ),
                unavailable: !vendorOffersScripting,
                invited,
              },
            ],
          },
          subtitling: {
            invited,
            ...selectionProps(
              'SUBTITLING',
              vendor,
              project,
              item.language,
              invited,
            ),
            services: [
              {
                id: vendor.vendorId + 'Subtitling',
                label: 'Subtitling',
                ...selectionProps(
                  'SUBTITLING',
                  vendor,
                  project,
                  item.language,
                  invited,
                ),
                unavailable: !vendorOffersSubtitling,
                invited,
              },
            ],
          },
        };
        if (vendorOffersScripting) item.scripting?.vendors.push(vendorInfo);
        if (vendorOffersSubtitling) item.subtitling?.vendors.push(vendorInfo);
      });

      item.scripting!.selected = !item.scripting!.vendors.some(
        (v) => !v.scripting?.selected,
      );

      item.scripting!.invited = !item.scripting!.vendors.some(
        (v) => !v.scripting?.invited,
      );

      item.subtitling!.selected = !item.subtitling!.vendors.some(
        (v) => !v.subtitling?.selected,
      );

      item.subtitling!.invited = !item.subtitling!.vendors.some(
        (v) => !v.subtitling?.invited,
      );

      item.selected = Boolean(
        item.scripting?.selected && item.subtitling?.selected,
      );

      item.invited = Boolean(
        item.scripting?.invited && item.subtitling?.invited,
      );

      items.push(item);
    });
    return items;
  }, [isInvited, projects, selectionProps, vendors]);

  return [list, selectedItems] as const;
};
