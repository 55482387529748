import React, { FC, ReactNode } from 'react';
import { Box, useTheme, BoxProps } from '@material-ui/core';

export const Fieldset: FC<
  { label?: ReactNode; variant?: 'outline' | 'box' } & BoxProps
> = ({ children, label, variant = 'outline', ...boxProps }) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      {...boxProps}
      style={{
        ...(variant === 'box'
          ? {
              backgroundColor: theme.palette.primary.light,
              borderRadius: theme.shape.borderRadius,
              padding: theme.spacing(4),
            }
          : {}),
        ...boxProps.style,
      }}
    >
      {variant === 'outline' && (
        <Box
          width={9}
          className="MuiOutlinedInput-notchedOutline"
          border="1px solid"
          borderRight="none"
          style={{
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
          }}
        />
      )}
      <Box
        flex={1}
        borderBottom={variant === 'outline' ? '1px solid' : ''}
        className="MuiOutlinedInput-notchedOutline"
      >
        <Box
          fontSize={variant === 'outline' ? '12px' : '16px'}
          lineHeight="1"
          display="flex"
          alignItems="top"
          position="relative"
          paddingBottom={variant === 'box' ? 4 : 0}
        >
          {label && (
            <Box
              style={
                variant === 'outline'
                  ? {
                      transform: 'translateY(-50%)',
                      padding: `0 ${theme.spacing(1)}px`,
                    }
                  : {}
              }
            >
              {label}
            </Box>
          )}
          {variant === 'outline' && (
            <Box
              height={0}
              flex={1}
              borderTop="1px solid"
              className="MuiOutlinedInput-notchedOutline"
            />
          )}
        </Box>
        <Box
          {...(variant === 'outline'
            ? {
                paddingTop: 1.75,
                paddingBottom: 4,
                paddingX: 1,
              }
            : {})}
        >
          {children}
        </Box>
      </Box>
      {variant === 'outline' && (
        <Box
          width={9}
          className="MuiOutlinedInput-notchedOutline"
          border="1px solid"
          borderLeft="none"
          style={{
            borderTopRightRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
          }}
        />
      )}
    </Box>
  );
};
