import { useMemo, useCallback, useState } from 'react';

import { deserializeDateWithTimezone } from 'src/util/date';
import {
  ListItem,
  VendorInfo,
  VendorServiceDetails,
  SelectedItem,
} from './types';
import { calculateBidPrice } from 'src/services/bids';
import { sumBy } from 'lodash';

export const useData = (
  projects: E.Project[],
  vendors: E.Vendor[],
  bids: E.Bid[],
) => {
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);

  const selectionProps = useCallback(
    function (
      key: E.LocalizationService,
      vendor: E.Vendor,
      project: E.Project,
      language: E.Language,
      bid: E.Bid,
      alreadyHired: boolean,
    ) {
      const vendorSelection = (arr: SelectedItem[] = selectedItems) =>
        arr.find((s) => s.bidId === bid.bidId);
      return {
        selected:
          alreadyHired ||
          Boolean(vendorSelection()?.localizationServices?.includes(key)),
        toggleSelection: (status?: boolean) => {
          if (alreadyHired) return;
          setSelectedItems((s) => {
            const _vendor = vendorSelection(s);
            const selected = _vendor?.localizationServices?.includes(key);
            const _selected =
              status === undefined ? Boolean(selected) : !status;
            return (_vendor
              ? s.map((t) =>
                  t !== _vendor
                    ? t
                    : {
                        ...t,
                        localizationServices: [
                          ...(t.localizationServices?.filter(
                            (k) => k !== key,
                          ) ?? []),
                          ...(_selected ? [] : [key]),
                        ] as E.LocalizationService[],
                      },
                )
              : !_selected
              ? [
                  ...s,
                  {
                    language,
                    localizationServices: [key],
                    projectId: project.projectId,
                    vendorId: vendor.vendorId,
                    bidId: bid.bidId,
                  },
                ]
              : s
            ).filter((s) => Boolean(s.localizationServices?.length));
          });
        },
      };
    },
    [selectedItems],
  );

  const findBbid = useCallback(
    (
      project: E.Project,
      language: E.Language,
      vendor: E.Vendor,
      service: E.LocalizationService,
    ) =>
      bids.find(
        (b) =>
          b.projectId === project.projectId &&
          b.language === language &&
          b.localizationServices?.[service] &&
          b.vendorId === vendor.vendorId,
      ),
    [bids],
  );

  const list = useMemo(() => {
    const items: ListItem[] = [];
    projects.forEach((project) => {
      const dueDateTz = deserializeDateWithTimezone(project.bidDueDate!);
      const localizationServices = project.localizationServices;
      new Set<E.Language>([
        ...project.requestedDubbedLanguages,
        ...project.requestedSubtitledLanguages,
      ]).forEach((language) => {
        const dubbingLanguage = project.requestedDubbedLanguages.includes(
          language,
        );
        const subtitlingLanguage = project.requestedSubtitledLanguages.includes(
          language,
        );
        const item: ListItem = {
          language,
          _dueDate_sort: dueDateTz[0].getTime(),
          dueDate: dueDateTz,
          project,
          dubbing: {
            vendors: [],
          },
          qc: {
            vendors: [],
          },
          subtitling: {
            vendors: [],
          },
          id: `${project.projectId}${language}`,
        };

        vendors.forEach((vendor) => {
          if (!vendor.languages.includes(language)) return;
          if (!bids.some((b) => b.vendorId === vendor.vendorId)) return;
          if (
            !vendor.localizationServices?.some((s) =>
              Object.entries(localizationServices).some(
                ([t, b]) => Boolean(b) && t === s,
              ),
            )
          )
            return;

          const vendorInfo: VendorInfo = {
            ...vendor,
            dubbing: {
              services: [],
              price: 0,
              bidSent: false,
              selected: false,
              toggleSelection: () => {
                vendorInfo.dubbing?.services.forEach((s) =>
                  s.toggleSelection(!vendorInfo.dubbing?.selected),
                );
              },
            },
            qc: {
              services: [],
              price: 0,
              bidSent: false,
              selected: false,
              toggleSelection: () => {
                vendorInfo.qc?.services.forEach((s) =>
                  s.toggleSelection(!vendorInfo.qc?.selected),
                );
              },
            },
            subtitling: {
              services: [],
              price: 0,
              bidSent: false,
              selected: false,
              toggleSelection: () => {
                vendorInfo.subtitling?.services.forEach((s) =>
                  s.toggleSelection(!vendorInfo.subtitling?.selected),
                );
              },
            },
          };

          if (dubbingLanguage) {
            const translationServices: VendorServiceDetails['services'] = [];
            const translatingBid = findBbid(
              project,
              language,
              vendor,
              'TRANSLATING',
            );

            if (translatingBid) {
              translationServices.push({
                id: vendor.vendorId + 'Translation',
                label: 'Translation',
                unavailable: !vendor.localizationServices?.includes(
                  'TRANSLATING',
                ),
                price: calculateBidPrice(
                  translatingBid,
                  'localizationServices',
                  'TRANSLATING',
                ),
                ...selectionProps(
                  'TRANSLATING',
                  vendor,
                  project,
                  language,
                  translatingBid,
                  false,
                ),
              });
            }

            const adaptingBid = findBbid(project, language, vendor, 'ADAPTING');

            if (adaptingBid) {
              translationServices.push({
                id: vendor.vendorId + 'Adaptation',
                label: 'Adaptation',
                price: calculateBidPrice(
                  adaptingBid,
                  'localizationServices',
                  'ADAPTING',
                ),
                unavailable: !vendor.localizationServices?.includes('ADAPTING'),
                ...selectionProps(
                  'ADAPTING',
                  vendor,
                  project,
                  language,
                  adaptingBid,
                  false,
                ),
              });
            }

            if (translationServices.length > 0) {
              const translation: VendorServiceDetails = {
                id: vendor.vendorId + 'Translation',
                label: 'Translation',
                price: sumBy(translationServices, 'price'),
                bidSent: [adaptingBid, translatingBid].some(
                  (b) => b?.submittedAt,
                ),
                unavailable:
                  translationServices.filter((s) => s.unavailable).length ===
                  translationServices.length,
                services: translationServices,
                selected: !translationServices.some((t) => !t.selected),
                toggleSelection: (status) => {
                  translationServices.forEach((s) =>
                    s.toggleSelection(status ?? !translation.selected),
                  );
                },
              };
              vendorInfo.dubbing?.services.push(translation);
            }

            const dubbingServices: VendorServiceDetails['services'] = [];
            const castingBid = findBbid(project, language, vendor, 'CASTING');

            if (castingBid) {
              dubbingServices.push({
                id: vendor.vendorId + 'Casting',
                label: 'Casting',
                unavailable: !vendor.localizationServices?.includes('CASTING'),
                price: calculateBidPrice(
                  castingBid,
                  'localizationServices',
                  'CASTING',
                ),
                ...selectionProps(
                  'CASTING',
                  vendor,
                  project,
                  language,
                  castingBid,
                  false,
                ),
              });
            }

            const recordingBid = findBbid(
              project,
              language,
              vendor,
              'RECORDING',
            );

            if (recordingBid) {
              dubbingServices.push({
                id: vendor.vendorId + 'Recording',
                label: 'Recording',
                unavailable: !vendor.localizationServices?.includes(
                  'RECORDING',
                ),
                price: calculateBidPrice(
                  recordingBid,
                  'localizationServices',
                  'RECORDING',
                ),
                ...selectionProps(
                  'RECORDING',
                  vendor,
                  project,
                  language,
                  recordingBid,
                  false,
                ),
              });
            }

            const editingBid = findBbid(project, language, vendor, 'EDITING');

            if (editingBid) {
              dubbingServices.push({
                id: vendor.vendorId + 'Editing',
                label: 'Editing',
                unavailable: !vendor.localizationServices?.includes('EDITING'),
                price: calculateBidPrice(
                  editingBid,
                  'localizationServices',
                  'EDITING',
                ),
                ...selectionProps(
                  'EDITING',
                  vendor,
                  project,
                  language,
                  editingBid,
                  false,
                ),
              });
            }

            const mixingBid = findBbid(project, language, vendor, 'MIXING');

            if (mixingBid) {
              dubbingServices.push({
                id: vendor.vendorId + 'Mixing',
                label: 'Mixing',
                unavailable: !vendor.localizationServices?.includes('MIXING'),
                price: calculateBidPrice(
                  mixingBid,
                  'localizationServices',
                  'MIXING',
                ),
                ...selectionProps(
                  'MIXING',
                  vendor,
                  project,
                  language,
                  mixingBid,
                  false,
                ),
              });
            }

            if (dubbingServices.length > 0) {
              const dubbing: VendorServiceDetails = {
                id: vendor.vendorId + 'Dubbing',
                label: 'Dubbing',
                unavailable:
                  dubbingServices.filter((s) => s.unavailable).length ===
                  dubbingServices.length,
                bidSent: [mixingBid, editingBid, recordingBid, castingBid].some(
                  (b) => b?.submittedAt,
                ),
                services: dubbingServices,
                price: sumBy(dubbingServices, 'price'),
                selected: !dubbingServices.some((t) => !t.selected),
                toggleSelection: (status) => {
                  dubbingServices.forEach((s) =>
                    s.toggleSelection(status ?? !dubbing.selected),
                  );
                },
              };
              vendorInfo.dubbing?.services.push(dubbing);
              item.dubbing?.vendors.push(vendorInfo);
            }
          }

          const subtitlingBid = findBbid(
            project,
            language,
            vendor,
            'SUBTITLING',
          );

          if (
            subtitlingLanguage &&
            localizationServices.SUBTITLING &&
            subtitlingBid
          ) {
            vendorInfo.subtitling?.services.push({
              id: vendor.vendorId + 'Subtitling',
              label: 'Subtitling',
              unavailable: !vendor.localizationServices?.includes('SUBTITLING'),
              bidSent: Boolean(subtitlingBid),
              price: subtitlingBid
                ? calculateBidPrice(
                    subtitlingBid,
                    'localizationServices',
                    'SUBTITLING',
                  )
                : 0,
              ...(subtitlingBid
                ? selectionProps(
                    'SUBTITLING',
                    vendor,
                    project,
                    language,
                    subtitlingBid,
                    false,
                  )
                : {
                    selected: false,
                    toggleSelection: () => {},
                  }),
            });
            item.subtitling?.vendors.push(vendorInfo);
          }

          const qcServices: VendorServiceDetails['services'] = [];
          const linguisticQcBid = findBbid(
            project,
            language,
            vendor,
            'LINGUISTIC_QC',
          );

          if (linguisticQcBid) {
            qcServices.push({
              id: vendor.vendorId + 'Linguistic QC',
              label: 'Linguistic QC',
              unavailable: !vendor.localizationServices?.includes(
                'LINGUISTIC_QC',
              ),
              price: calculateBidPrice(
                linguisticQcBid,
                'localizationServices',
                'LINGUISTIC_QC',
              ),
              ...selectionProps(
                'LINGUISTIC_QC',
                vendor,
                project,
                language,
                linguisticQcBid,
                false,
              ),
            });
          }

          const technicalQcBid = findBbid(
            project,
            language,
            vendor,
            'TECHNICAL_QC',
          );

          if (technicalQcBid) {
            qcServices.push({
              id: vendor.vendorId + 'Technical QC',
              label: 'Technical QC',
              unavailable: !vendor.localizationServices?.includes(
                'TECHNICAL_QC',
              ),
              price: calculateBidPrice(
                technicalQcBid,
                'localizationServices',
                'TECHNICAL_QC',
              ),
              ...selectionProps(
                'TECHNICAL_QC',
                vendor,
                project,
                language,
                technicalQcBid,
                false,
              ),
            });
          }

          const completeQcBid = findBbid(
            project,
            language,
            vendor,
            'COMPLETE_QC',
          );

          if (completeQcBid) {
            qcServices.push({
              id: vendor.vendorId + 'Complete QC',
              label: 'Complete QC',
              unavailable: !vendor.localizationServices?.includes(
                'COMPLETE_QC',
              ),
              price: calculateBidPrice(
                completeQcBid,
                'localizationServices',
                'COMPLETE_QC',
              ),
              ...selectionProps(
                'COMPLETE_QC',
                vendor,
                project,
                language,
                completeQcBid,
                false,
              ),
            });
          }

          if (qcServices.length > 0) {
            const qc: VendorServiceDetails = {
              id: vendor.vendorId + 'Dubbing QC',
              label: 'Dubbing QC',
              unavailable:
                qcServices.filter((s) => s.unavailable).length ===
                qcServices.length,
              bidSent: [completeQcBid, linguisticQcBid, technicalQcBid].some(
                (b) => b?.submittedAt,
              ),
              services: qcServices,
              price: sumBy(qcServices, 'price'),
              selected: !qcServices.some((t) => !t.selected),
              toggleSelection: (status) => {
                qcServices.forEach((s) =>
                  s.toggleSelection(status ?? !qc.selected),
                );
              },
            };
            vendorInfo.qc?.services.push(qc);
            item.qc?.vendors.push(vendorInfo);
          }

          if (vendorInfo.qc) {
            vendorInfo.qc.price = sumBy(vendorInfo.qc.services, 'price');
            vendorInfo.qc.bidSent = vendorInfo.qc.services.some(
              (s) => s.bidSent,
            );
            vendorInfo.qc.selected = !vendorInfo.qc.services.some(
              (s) => !s.selected,
            );
          }

          if (vendorInfo.dubbing) {
            vendorInfo.dubbing.price = sumBy(
              vendorInfo.dubbing.services,
              'price',
            );
            vendorInfo.dubbing.bidSent = vendorInfo.dubbing.services.some(
              (s) => s.bidSent,
            );
            vendorInfo.dubbing.selected = !vendorInfo.dubbing.services.some(
              (s) => !s.selected,
            );
          }

          if (vendorInfo.subtitling) {
            vendorInfo.subtitling.price = sumBy(
              vendorInfo.subtitling.services,
              'price',
            );
            vendorInfo.subtitling.bidSent = vendorInfo.subtitling.services.some(
              (s) => s.bidSent,
            );
            vendorInfo.subtitling.selected = !vendorInfo.subtitling.services.some(
              (s) => !s.selected,
            );
          }
        });
        items.push(item);
      });

      const item: ListItem = {
        language: 'OV' as E.Language,
        _dueDate_sort: dueDateTz[0].getTime(),
        dueDate: dueDateTz,
        project,
        subtitling: {
          vendors: [],
        },
        scripting: {
          vendors: [],
        },
        id: `${project.projectId}OV`,
      };

      vendors.forEach((vendor) => {
        if (!vendor.languages.includes(project.originalVersionLanguage)) return;
        if (!bids.some((b) => b.vendorId === vendor.vendorId)) return;

        const scriptingBid = findBbid(
          project,
          'OV' as E.Language,
          vendor,
          'SCRIPTING',
        );
        const subtitlingBid = findBbid(
          project,
          'OV' as E.Language,
          vendor,
          'SUBTITLING',
        );

        if (!scriptingBid && !subtitlingBid) return;

        const scriptingPrice = scriptingBid
          ? calculateBidPrice(scriptingBid, 'localizationServices', 'SCRIPTING')
          : 0;

        const subtitlingPrice = subtitlingBid
          ? calculateBidPrice(
              subtitlingBid,
              'localizationServices',
              'SCRIPTING',
            )
          : 0;

        const subtitling: VendorServiceDetails = {
          id: vendor.vendorId + 'Subtitling',
          label: 'Subtitling',
          unavailable: !subtitlingBid,
          price: subtitlingPrice,
          bidSent: Boolean(subtitlingBid?.submittedAt),
          ...(subtitlingBid
            ? selectionProps(
                'SUBTITLING',
                vendor,
                project,
                item.language,
                subtitlingBid,
                false,
              )
            : {
                selected: false,
                toggleSelection: () => {},
              }),
        };

        const scripting: VendorServiceDetails = {
          id: vendor.vendorId + 'Scripting',
          label: 'Scripting',
          unavailable: !scriptingBid,
          price: scriptingPrice,
          bidSent: Boolean(scriptingBid?.submittedAt),
          ...(scriptingBid
            ? selectionProps(
                'SCRIPTING',
                vendor,
                project,
                item.language,
                scriptingBid,
                false,
              )
            : {
                selected: false,
                toggleSelection: () => {},
              }),
        };

        const vendorInfo: VendorInfo = {
          ...vendor,
          scripting: {
            price: scriptingPrice,
            bidSent: Boolean(scriptingBid?.submittedAt),
            services: [scripting],
            selected: scripting.selected,
            toggleSelection: scripting.toggleSelection,
          },
          subtitling: {
            price: subtitlingPrice,
            bidSent: Boolean(subtitlingBid?.submittedAt),
            services: [subtitling],
            selected: subtitling.selected,
            toggleSelection: subtitling.toggleSelection,
          },
        };

        if (scriptingBid) item.scripting?.vendors.push(vendorInfo);
        if (subtitlingBid) item.subtitling?.vendors.push(vendorInfo);
      });

      items.push(item);
    });
    return items;
  }, [bids, findBbid, projects, selectionProps, vendors]);

  return [list, selectedItems] as const;
};
