import { http } from '../http';

export const getProjects = async () => {
  try {
    const { data } = await http.get('/projects/all');
    return data as E.Project[];
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};

export const getProject = async (projectId: string) => {
  try {
    const { data } = await http.get(`/projects/project/${projectId}`);
    return data as E.Project;
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};

export const createProject = async (project: Partial<E.Project>) => {
  try {
    const { data } = await http.post(`/projects/create`, project);
    return data as E.Project;
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};

export const updateProject = async (
  project: Partial<E.Project> & { projectId: string },
) => {
  try {
    await http.patch(`/projects/project/${project.projectId}`, project);
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};
