import React, { ReactNode, useState, FC } from 'react';
import {
  Box,
  BoxProps,
  useTheme,
  Avatar,
  IconButton,
  Typography,
  withStyles,
  createStyles,
} from '@material-ui/core';
import {
  ChevronLeft,
  Notifications,
  ExitToApp,
  Settings,
} from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { AutoPopover } from '../AutoPopover';
import { useSelector, useDispatch } from 'src/store';
import { logOut } from 'src/store/session/actions';

const NotificationItem = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `1px solid ${theme.palette.primary.light}`,
  paddingBottom: theme.spacing(2),
  marginBottom: theme.spacing(2),
  '& i': {
    fontStyle: 'normal',
    fontSize: 12,
  },
  '& strong': {
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
  },
  '&:hover, &:focus': {
    filter: 'none',
    color: '#fff',
  },
}));

const NotificationsWrapper: FC = () => {
  return (
    <Box height="300px" maxWidth="300px" padding={4}>
      <ul>
        <li>
          <Link to="/bacon">
            <NotificationItem>
              <i>06/29/2020 – 3h32pm</i>
              <strong>You have a new bid invitation</strong>
              Click here to submit a bid
            </NotificationItem>
          </Link>
        </li>
        <li>
          <Link to="/bacon">
            <NotificationItem>
              <i>06/29/2020 – 3h32pm</i>
              <strong>You have a new bid invitation</strong>
              Click here to submit a bid
            </NotificationItem>
          </Link>
        </li>
      </ul>
    </Box>
  );
};

const UserButton = withStyles((theme) =>
  createStyles({
    root: {
      border: '2px solid',
      borderColor: theme.palette.secondary.main,
      cursor: 'pointer',
      transition: 'filter 0.3s',

      '&:hover, &:focus': {
        filter: 'brightness(1.2)',
      },
    },
  }),
)(Avatar);

export type TopbarProps = Omit<BoxProps, 'title'> & {
  leftArea?: ReactNode;
  rightArea?: ReactNode;
  title?: ReactNode;
  goBack?: () => unknown;
};

export function Topbar({
  leftArea,
  rightArea,
  title,
  goBack,
  ...divProps
}: TopbarProps) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.session.user);

  const [showingNotifications, setShowingNotifications] = useState(false);
  const [showingMyAccount, setShowingMyAccount] = useState(false);

  return (
    <Box
      {...divProps}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      bgcolor={theme.palette.primary.main}
      height={64}
      paddingRight={6}
      boxShadow="rgba(0, 0, 0, 0.3) 0 0 16px"
      zIndex={1}
    >
      <Box display="flex" alignItems="center">
        {goBack && (
          <IconButton onClick={() => goBack()}>
            <ChevronLeft width={12} />
          </IconButton>
        )}
        {leftArea && (
          <>
            <Box paddingLeft={2} />
            {leftArea}
          </>
        )}
      </Box>
      <Typography component="h1">{title}</Typography>
      <Box display="flex" height="100%">
        {rightArea}
        <Box display="flex" height="100%" alignItems="center">
          <IconButton onClick={() => setShowingNotifications((p) => !p)}>
            <Notifications width={16} />
          </IconButton>
          <Box paddingLeft={2} />
          <AutoPopover
            open={showingNotifications}
            onClose={() => setShowingNotifications(false)}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <NotificationsWrapper />
          </AutoPopover>
        </Box>
        <Box display="flex" alignItems="center">
          <UserButton
            alt={userInfo?.firstName}
            src={userInfo?.picture}
            onClick={() => setShowingMyAccount((p) => !p)}
          />
          <AutoPopover
            open={showingMyAccount}
            onClose={() => setShowingMyAccount(false)}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Box paddingY={2}>
              <Link to="/settings">
                <Box display="flex" alignItems="center" padding={2}>
                  <Settings
                    width={14}
                    style={{ marginRight: theme.spacing(2) }}
                  />
                  Settings
                </Box>
              </Link>
              <Link
                to="/logout"
                onClick={(ev) => {
                  ev.preventDefault();
                  dispatch(logOut());
                }}
              >
                <Box display="flex" alignItems="center" padding={2}>
                  <ExitToApp
                    width={14}
                    style={{ marginRight: theme.spacing(2) }}
                  />
                  Logout
                </Box>
              </Link>
            </Box>
          </AutoPopover>
        </Box>
      </Box>
    </Box>
  );
}
