import React, { FC } from 'react';
import { Typography, useTheme, CircularProgress } from '@material-ui/core';
import { Error } from '@material-ui/icons';

export const Loader: FC<{ loading: boolean; error: boolean }> = ({
  loading,
  error,
}) => {
  const theme = useTheme();
  return error ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(6),
      }}
    >
      <Error style={{ marginRight: theme.spacing(2) }} />
      <Typography>Something went wrong!</Typography>
    </div>
  ) : loading ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(6),
      }}
    >
      <CircularProgress
        size={16}
        color="secondary"
        style={{ marginRight: theme.spacing(2) }}
      />
      <Typography>Loading...</Typography>
    </div>
  ) : null;
};
