import React, { ReactNode, FC } from 'react';
import {
  useTheme,
  styled,
  Paper,
  Box,
  Typography,
  BoxProps,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  timezoneCity,
  deserializeDateWithTimezone,
  timeToLocalDateString,
} from 'src/util/date';

import { BidsViewData } from '.';
import { useSelector } from 'src/store';
import { statusColorMap } from '../common';

const ItemContainer = styled(Paper)({
  overflow: 'hidden',
  height: '100%',
});

const BidsGridViewItemInfo: FC<
  { title: ReactNode; info: ReactNode } & BoxProps
> = ({ title, info, ...boxProps }) => {
  return (
    <Box display="flex" flexDirection="column" {...boxProps}>
      <Typography variant="caption" color="textSecondary">
        {title}
      </Typography>
      <Typography variant="subtitle2">{info}</Typography>
    </Box>
  );
};

export const BidsGridViewItem: FC<{ bid: BidsViewData[0] }> = ({ bid }) => {
  const theme = useTheme();
  const user = useSelector((state) => state.session.user!);

  return (
    <Link to={`/bids/bid/${bid.projectId}/${bid.bidId}`}>
      <ItemContainer>
        <div
          style={{
            backgroundColor: statusColorMap[bid.status],
            color: '#fff',
            padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="caption" color="inherit">
            {bid.status}
          </Typography>
          {bid.purchaseOrder && (
            <Typography variant="caption" color="inherit">
              #{bid.purchaseOrder}
            </Typography>
          )}
        </div>
        <div
          style={{
            paddingTop: '56.25%',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              background: `url(${bid.vendor.logo}) no-repeat center`,
              backgroundSize: 'cover',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              filter: 'blur(30px)',
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 30px inset',
            }}
          />
          <img
            src={bid.vendor.logo}
            alt={bid.vendor.name}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '80%',
              transform: 'translate(-50%, -50%)',
              filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.3))',
            }}
          />
        </div>
        <Box padding={4}>
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: 'bold',
              lineHeight: 1.15,
              marginBottom: theme.spacing(2),
            }}
          >
            {bid.project.title}
          </Typography>
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gridColumnGap={theme.spacing(4)}
            gridRowGap={theme.spacing(2)}
          >
            <BidsGridViewItemInfo
              gridColumn="1 / -1"
              title="Language"
              info={
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    display: 'block',
                    textOverflow: 'ellipsis',
                  }}
                  title={bid.language}
                >
                  {bid.language}
                </span>
              }
            />
            <BidsGridViewItemInfo
              gridColumn="1 / -1"
              title="Price"
              info={bid.bidTotalUsd > 0 ? 'USD ' + bid.bidTotalUsd : '--'}
            />
            <BidsGridViewItemInfo
              title="Due Date"
              info={(() => {
                const [d, tz] = deserializeDateWithTimezone(
                  bid.project.bidDueDate!,
                );
                return (
                  <span
                    title={
                      timeToLocalDateString(d, user.timezone) +
                      ` (${timezoneCity(tz)})`
                    }
                  >
                    {timeToLocalDateString(d, user.timezone)}
                  </span>
                );
              })()}
            />
            <BidsGridViewItemInfo
              title="Service"
              info={
                bid.localizationServices ? 'Localization' : 'Post Production'
              }
            />
          </Box>
        </Box>
      </ItemContainer>
    </Link>
  );
};
