import * as actions from './actions';

const initialState = {
  loggedIn: false,
  user: undefined as E.User | undefined,
};

export const sessionReducer: Store.Reducer<
  typeof actions,
  typeof initialState
> = (state = initialState, action): typeof initialState => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        loggedIn: true,
      };
    case 'LOGOUT':
      return {
        ...state,
        loggedIn: false,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};
