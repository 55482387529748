import React, {
  useMemo,
  useState,
  FormEvent,
  useCallback,
  useEffect,
} from 'react';
import {
  Button,
  Box,
  useTheme,
  Typography,
  Checkbox,
  FormControlLabel,
  IconButton,
  lighten,
  CircularProgress,
} from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Delete } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router';
import { nanoid } from 'nanoid';
import resize from 'image-blob-reduce';

import { useAppFrameOptions } from 'src/hooks/navigation';
import { Fieldset } from 'src/components/Fieldset';
import { Dropzone, DropzoneAfterDrop } from 'src/components/Dropzone';
import { TextField, SelectField } from 'src/components/Fields';
import { Watcher } from 'src/components/Watcher';
import {
  daysRelative,
  serializeDateWithTimezone,
  deserializeDateWithTimezone,
  timezoneCity,
  prepareForDateField,
} from 'src/util/date';
import {
  createProject,
  getProject,
  updateProject,
} from 'src/services/projects';
import { uploadFile } from 'src/services/uploads';
import { Loader } from 'src/components/Loader';
import { allLanguages } from 'src/util/languages';
import { useSelector } from 'src/store';

const resizer = resize();

type FormData = Partial<E.Project>;

export const ProjectFormPage = () => {
  const theme = useTheme();
  const history = useHistory();
  const { projectId } = useParams<{
    projectId?: string;
  }>();
  const user = useSelector((state) => state.session.user!);

  useAppFrameOptions(
    useMemo(
      () => ({
        title: projectId ? 'Edit Project' : 'New Project',
      }),
      [projectId],
    ),
  );

  const [loading, setLoading] = useState<boolean | -1>(Boolean(projectId));
  const [working, setWorking] = useState(false);
  const [coverImage, setCoverImage] = useState<File>();
  const [characterPictures, setCharacterPictures] = useState<
    Record<string, File>
  >({});

  useEffect(() => {
    if (projectId) {
      getProject(projectId)
        .then((proj) => {
          setFormData((p) => ({ ...p, ...proj }));
          setLoading(false);
        })
        .catch(() => {
          setLoading(-1);
        });
    }
  }, [projectId]);

  const [formData, setFormData] = useState<FormData>({
    creator: '',
    director: '',
    executiveProducer: '',
    genre: '',
    producer: '',
    writer: '',
    postProductionStartDate: serializeDateWithTimezone(
      daysRelative(Date.now(), 7),
      user.timezone,
    ),
    localizationStartDate: serializeDateWithTimezone(
      daysRelative(Date.now(), 14),
      user.timezone,
    ),
    finalDeliverablesDueDate: serializeDateWithTimezone(
      daysRelative(Date.now(), 21),
      user.timezone,
    ),
    expectedReleaseDate: serializeDateWithTimezone(
      daysRelative(Date.now(), 51),
      user.timezone,
    ),
    localizationServices: {
      ADAPTING: false,
      CASTING: false,
      COMPLETE_QC: false,
      EDITING: false,
      LINGUISTIC_QC: false,
      MIXING: false,
      RECORDING: false,
      SCRIPTING: false,
      SUBTITLING: false,
      TECHNICAL_QC: false,
      TRANSLATING: false,
    },
    originalPostProductionServices: {
      'SOUND_EDITORIAL/MIXING': false,
      DI_PICTURE_FINISHING: false,
      PICTURE_EDITORIAL: false,
      VFX: false,
    },
    additionalComments: '',
    characters: [],
    episodes: [],
    requestedSubtitledLanguages: [],
    requestedDubbedLanguages: [],
    projectManagers: [],
    clientId: 'gq2P0XAqDQTgFuRhZWCts',
    synopsis: '',
    title: '',
  });

  const update = useCallback(function <T extends keyof FormData>(
    k: T,
    v: FormData[T],
  ) {
    setFormData((prev) => ({
      ...prev,
      [k]: v,
    }));
  },
  []);

  const changeHandler = useCallback(
    function <T extends keyof FormData>(
      key: T,
      onChange?: (value: string) => FormData[T],
    ) {
      return (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = ev.currentTarget as any;
        update(key, onChange ? onChange(value) : value);
      };
    },
    [update],
  );

  const locServiceChangeHandler = useCallback(
    (k: E.LocalizationService) => ({
      checked: Boolean(formData.localizationServices?.[k]),
      onChange: (ev: any) =>
        update('localizationServices', {
          ...formData.localizationServices,
          [k]: ev.target.checked,
        }),
    }),
    [formData.localizationServices, update],
  );

  const postProdChangeHandler = useCallback(
    (k: E.OriginalPostProductionService) => ({
      checked: Boolean(formData.originalPostProductionServices?.[k]),
      onChange: (ev: any) =>
        update('originalPostProductionServices', {
          ...formData.originalPostProductionServices,
          [k]: ev.target.checked,
        }),
    }),
    [formData.originalPostProductionServices, update],
  );

  const updateCharacter = useCallback(
    (characterId: string, data: Partial<E.Character>) =>
      setFormData((p) => ({
        ...p,
        characters: p.characters?.map((c) =>
          c.characterId !== characterId
            ? c
            : {
                ...c,
                ...data,
              },
        ),
      })),
    [],
  );

  const dateField = useCallback(
    (field: keyof FormData, label: string, endOfTheDay?: boolean) => {
      const [date, tz] = deserializeDateWithTimezone(formData[field] as string);
      return (
        <Watcher
          values={[formData[field]]}
          render={() => (
            <KeyboardDatePicker
              disableToolbar
              inputVariant="outlined"
              variant="inline"
              format={`MM/dd/yyyy '(${timezoneCity(tz)})'`}
              margin="none"
              label={label}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              value={prepareForDateField(date, tz)}
              onChange={(date) =>
                date &&
                update(
                  field,
                  serializeDateWithTimezone(
                    endOfTheDay
                      ? new Date(date.setHours(23, 59, 59, 0))
                      : new Date(date.setHours(0, 0, 0, 0)),
                    user.timezone,
                  ),
                )
              }
              style={{ gridColumn: 'auto / span 1' }}
            />
          )}
        />
      );
    },
    [formData, update, user.timezone],
  );

  return Boolean(loading) ? (
    <Loader error={loading === -1} loading={loading === true} />
  ) : (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box
        padding={6}
        margin={8}
        bgcolor={theme.palette.primary.main}
        borderRadius={theme.shape.borderRadius}
      >
        <Box
          component="form"
          display="grid"
          gridTemplateColumns="repeat(4, 1fr)"
          gridGap={theme.spacing(6)}
          onSubmit={async (ev) => {
            ev.preventDefault();
            setWorking(true);
            try {
              const data = { ...formData };

              if (coverImage) {
                const coverImageResized = await resizer.to_blob(coverImage, {
                  max: 1920,
                });
                data.coverImage = await uploadFile('.jpg', coverImageResized);
              }

              await Promise.all(
                Object.entries(characterPictures).map(
                  async ([characterId, blob]) => {
                    const resized = await resizer.to_blob(blob, {
                      max: 960,
                    });
                    data.characters!.find(
                      (a) => a.characterId === characterId,
                    )!.picture = await uploadFile('.jpg', resized);
                  },
                ),
              );

              let newlyCreatedId = projectId;

              if (projectId) {
                await updateProject({ ...data, projectId });
              } else {
                newlyCreatedId = (await createProject(data)).projectId;
              }

              history.push(`/projects/view/${newlyCreatedId}`);
            } catch (err) {
              alert('Something went wrong: ' + err?.message ?? 'UNKOWN ERROR');
              setWorking(false);
            }
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              gridColumn: '1 / -1',
              marginBottom: theme.spacing(2),
            }}
          >
            Project Info
          </Typography>
          <Watcher
            values={[formData.title]}
            render={() => (
              <TextField
                label="Title"
                placeholder="Title"
                value={formData.title ?? ''}
                onChange={changeHandler('title')}
                style={{
                  gridColumn: 'auto / span 2',
                }}
                required
              />
            )}
          />
          <Fieldset
            label="Creative Team"
            gridRow="3 / span 6"
            gridColumn="1 / span 2"
            variant="box"
          >
            <Box
              display="grid"
              gridTemplateColumns="1fr"
              gridRowGap={theme.spacing(5)}
            >
              <Watcher
                values={[formData.creator]}
                render={() => (
                  <TextField
                    label="Creator"
                    placeholder="Creator"
                    value={formData.creator}
                    onChange={changeHandler('creator')}
                  />
                )}
              />
              <Watcher
                values={[formData.executiveProducer]}
                render={() => (
                  <TextField
                    label="Executive Producer"
                    placeholder="Executive Producer"
                    value={formData.executiveProducer}
                    onChange={changeHandler('executiveProducer')}
                  />
                )}
              />
              <Watcher
                values={[formData.director]}
                render={() => (
                  <TextField
                    label="Director"
                    placeholder="Director"
                    value={formData.director}
                    onChange={changeHandler('director')}
                  />
                )}
              />
              <Watcher
                values={[formData.producer]}
                render={() => (
                  <TextField
                    label="Producer"
                    placeholder="Producer"
                    value={formData.producer}
                    onChange={changeHandler('producer')}
                  />
                )}
              />
              <Watcher
                values={[formData.writer]}
                render={() => (
                  <TextField
                    label="Writer"
                    placeholder="Writer"
                    value={formData.writer}
                    onChange={changeHandler('writer')}
                  />
                )}
              />
            </Box>
          </Fieldset>
          <Fieldset
            label="Details"
            gridRow="2 / span 7"
            gridColumn="3 / span 2"
            variant="box"
          >
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              gridGap={theme.spacing(5)}
            >
              <Watcher
                values={[formData.genre]}
                render={() => (
                  <TextField
                    label="Genre"
                    placeholder="Genre"
                    value={formData.genre}
                    onChange={changeHandler('genre')}
                    style={{ gridColumn: '1 / -1' }}
                  />
                )}
              />
              <Watcher
                values={[formData.type]}
                render={() => (
                  <SelectField
                    labelBgcolor={theme.palette.primary.light}
                    style={{ gridColumn: '1 / -1' }}
                    options={[
                      {
                        value: 'LIVE_ACTION',
                        label: 'Live Action',
                      },
                      {
                        value: 'ANIMATION',
                        label: 'Animation',
                      },
                    ]}
                    value={formData.type ?? ''}
                    onChange={(value) => update('type', value as any)}
                    label="Type"
                    empty
                  />
                )}
              />
              <Watcher
                values={[formData.format]}
                render={() => (
                  <SelectField
                    labelBgcolor={theme.palette.primary.light}
                    style={{ gridColumn: '1 / -1' }}
                    options={[
                      {
                        value: 'SERIES',
                        label: 'Series',
                      },
                      {
                        value: 'FEATURE',
                        label: 'Feature',
                      },
                      {
                        value: 'SHORT',
                        label: 'Short',
                      },
                    ]}
                    value={formData.format ?? ''}
                    onChange={(value) => update('format', value as any)}
                    label="Format"
                    empty
                  />
                )}
              />
              <Watcher
                values={[formData.runtime]}
                render={() => (
                  <TextField
                    label="(Episodic) Runtime"
                    placeholder="45 min"
                    value={formData.runtime ?? ''}
                    onChange={changeHandler('runtime', (v) => Number(v))}
                    style={{ gridColumn: '1 / -1' }}
                  />
                )}
              />
              {dateField(
                'postProductionStartDate',
                'Post-Production Start Date',
              )}
              {dateField('localizationStartDate', 'Localization Start Date')}
              {dateField(
                'finalDeliverablesDueDate',
                'Final Deliverables Due Date',
              )}
              {dateField('expectedReleaseDate', 'Expected Release Date')}
            </Box>
          </Fieldset>
          <Watcher
            values={[coverImage, formData.coverImage]}
            render={() => (
              <Fieldset
                label="Cover Image"
                gridColumn="1 / span 2"
                gridRow="auto / span 3"
              >
                <Dropzone
                  files={coverImage ? [coverImage] : []}
                  onChange={([img]) => setCoverImage(img)}
                  multiple={false}
                  renderDragActive={() => <>Drop your files here</>}
                  renderEmpty={() =>
                    formData.coverImage ? (
                      <DropzoneAfterDrop
                        onDelete={() =>
                          setFormData((p) => ({
                            ...p,
                            coverImage: undefined,
                          }))
                        }
                        imgSrc={formData.coverImage!}
                      />
                    ) : undefined
                  }
                  renderAfterDrop={() => (
                    <DropzoneAfterDrop
                      imgSrc={URL.createObjectURL(coverImage)}
                      onDelete={() => setCoverImage(undefined)}
                    />
                  )}
                  containerStyle={{
                    minHeight: 148,
                    height: '100%',
                    position: 'relative',
                  }}
                />
              </Fieldset>
            )}
          />
          <Watcher
            values={[formData.synopsis]}
            render={() => (
              <TextField
                label="Synopsis"
                placeholder="Synopsis"
                inputProps={{
                  style: {
                    height: '100%',
                  },
                }}
                InputProps={{
                  style: {
                    height: '100%',
                  },
                }}
                multiline
                style={{
                  gridColumn: 'auto / span 2',
                  gridRow: 'auto / span 3',
                }}
                value={formData.synopsis}
                onChange={changeHandler('synopsis')}
              />
            )}
          />
          <Watcher
            values={[formData.originalVersionLanguage]}
            render={() => (
              <SelectField
                empty
                style={{
                  gridColumn: 'auto / span 2',
                }}
                options={allLanguages.map((lang) => ({
                  label: lang,
                  value: lang,
                }))}
                value={formData.originalVersionLanguage}
                onChange={(value) =>
                  update('originalVersionLanguage', value as E.Language)
                }
                label="Original Version Language"
              />
            )}
          />
          <Watcher
            values={[formData.projectManagers]}
            render={() => (
              <SelectField
                empty
                multiple
                style={{
                  gridColumn: 'auto / span 2',
                }}
                options={['John Hoa', 'Robert Pfitzer', 'Jorja Smith'].map(
                  (name) => ({
                    label: name,
                    value: name,
                  }),
                )}
                value={formData.projectManagers}
                onChange={(value) =>
                  update('projectManagers', value as string[])
                }
                label="Project Managers"
              />
            )}
          />
          <Watcher
            values={[formData.additionalComments]}
            render={() => (
              <TextField
                label="Additional Comments"
                placeholder="Additional Comments"
                inputProps={{
                  style: {
                    height: '100%',
                  },
                }}
                InputProps={{
                  style: {
                    height: '100%',
                  },
                }}
                value={formData.additionalComments}
                onChange={changeHandler('additionalComments')}
                multiline
                style={{
                  gridColumn: '1 / -1',
                  gridRow: 'auto / span 2',
                  height: 130,
                }}
              />
            )}
          />
          <Watcher
            values={[formData.episodes, formData.format]}
            render={() =>
              formData.format === 'SERIES' && (
                <Box
                  gridColumn="1 / -1"
                  bgcolor={lighten(theme.palette.primary.main, 0.05)}
                  marginX={-6}
                >
                  <Watcher
                    values={[formData.episodes?.length]}
                    render={() => (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        padding={6}
                      >
                        <Typography variant="subtitle1">
                          Episodes ({formData.episodes?.length ?? 0})
                        </Typography>
                        <Button
                          variant="contained"
                          color="secondary"
                          disableElevation
                          onClick={() => {
                            setFormData((p) => ({
                              ...p,
                              episodes: [
                                ...(p.episodes ?? []),
                                {
                                  episodeId: nanoid(),
                                  number: (p.episodes?.length
                                    ? Math.max(
                                        ...p.episodes.map(
                                          (e) => Number(e.number) || 100,
                                        ),
                                      ) + 1
                                    : 101
                                  ).toString(10),
                                  title: '',
                                  localizationStartDate:
                                    formData.localizationStartDate ?? '',
                                },
                              ],
                            }));
                          }}
                        >
                          Add Episode
                        </Button>
                      </Box>
                    )}
                  />
                  {formData.episodes?.map((ep) => (
                    <Watcher
                      key={ep.episodeId}
                      values={[
                        ep.number,
                        ep.title,
                        ep.episodeId,
                        ep.localizationStartDate,
                      ]}
                      render={() => (
                        <Box
                          display="grid"
                          gridTemplateColumns="auto 128px 1fr auto"
                          gridGap={theme.spacing(6)}
                          padding={6}
                          borderTop={`1px solid ${theme.palette.primary.main}`}
                          position="relative"
                        >
                          <IconButton
                            style={{
                              alignSelf: 'center',
                              marginRight: -theme.spacing(3),
                            }}
                            disabled={Boolean(projectId)}
                            onClick={() =>
                              setFormData((p) => ({
                                ...p,
                                episodes: p.episodes?.filter(
                                  (e) => e.episodeId !== ep.episodeId,
                                ),
                              }))
                            }
                          >
                            <Delete />
                          </IconButton>
                          <Watcher
                            values={[ep.number]}
                            render={() => (
                              <TextField
                                label="Episode Number"
                                placeholder="101"
                                value={ep.number || ''}
                                required
                                onChange={(ev) => {
                                  const { value } = ev.currentTarget;
                                  setFormData((p) => ({
                                    ...p,
                                    episodes: [...(p.episodes ?? [])].map((e) =>
                                      e.episodeId !== ep.episodeId
                                        ? e
                                        : {
                                            ...e,
                                            number: value,
                                          },
                                    ),
                                  }));
                                }}
                              />
                            )}
                          />
                          <Watcher
                            values={[ep.title]}
                            render={() => (
                              <TextField
                                label="Episode Title"
                                placeholder="Pilot"
                                value={ep.title}
                                onChange={(ev) => {
                                  const { value } = ev.currentTarget;
                                  setFormData((p) => ({
                                    ...p,
                                    episodes: [...(p.episodes ?? [])].map((e) =>
                                      e.episodeId !== ep.episodeId
                                        ? e
                                        : {
                                            ...e,
                                            title: value,
                                          },
                                    ),
                                  }));
                                }}
                              />
                            )}
                          />
                          <Watcher
                            values={[ep.localizationStartDate]}
                            render={() => {
                              const [date, tz] = deserializeDateWithTimezone(
                                ep.localizationStartDate,
                              );
                              return (
                                <KeyboardDatePicker
                                  disableToolbar
                                  inputVariant="outlined"
                                  variant="inline"
                                  format={`MM/dd/yyyy '(${timezoneCity(tz)})'`}
                                  margin="none"
                                  label="Localization Start Date"
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                  value={prepareForDateField(date, tz)}
                                  onChange={(date) => {
                                    date &&
                                      setFormData((p) => ({
                                        ...p,
                                        episodes: [...(p.episodes ?? [])].map(
                                          (e) =>
                                            e.episodeId !== ep.episodeId
                                              ? e
                                              : {
                                                  ...e,
                                                  localizationStartDate:
                                                    serializeDateWithTimezone(
                                                      date,
                                                      user.timezone,
                                                    ) ?? '',
                                                },
                                        ),
                                      }));
                                  }}
                                />
                              );
                            }}
                          />
                        </Box>
                      )}
                    />
                  ))}
                </Box>
              )
            }
          />
          <Watcher
            values={[formData.characters, characterPictures, formData.episodes]}
            render={() => (
              <Box
                gridColumn="1 / -1"
                bgcolor={lighten(theme.palette.primary.main, 0.05)}
                marginX={-6}
              >
                <Watcher
                  values={[formData.characters?.length]}
                  render={() => (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      padding={6}
                    >
                      <Typography variant="subtitle1">
                        Characters ({formData.characters?.length ?? 0}){' / '}
                        Voice Test (
                        {formData.characters?.filter((c) => c.requiresVoiceTest)
                          .length ?? 0}
                        ){' / '}
                        Children (
                        {formData.characters?.filter((c) => c.childCharacter)
                          .length ?? 0}
                        )
                      </Typography>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          setFormData((p) => ({
                            ...p,
                            characters: [
                              {
                                originallyVoicedBy: '',
                                characterId: nanoid(),
                                description: '',
                                gender: '',
                                name: '',
                                requiresVoiceTest: false,
                                childCharacter: false,
                                episodes: [],
                                principleCast: false,
                                previousWork: '',
                                age: '',
                              },
                              ...(p.characters ?? []),
                            ],
                          }));
                        }}
                        disableElevation
                      >
                        Add Character
                      </Button>
                    </Box>
                  )}
                />
                {formData.characters?.map((character) => (
                  <Watcher
                    key={character.characterId}
                    values={[
                      character,
                      characterPictures[character.characterId],
                      formData.episodes,
                    ]}
                    render={() => (
                      <Box
                        display="grid"
                        gridTemplateColumns="128px 1fr 1fr"
                        gridTemplateRows="auto auto auto"
                        gridGap={theme.spacing(6)}
                        padding={6}
                        borderTop={`1px solid ${theme.palette.primary.main}`}
                        position="relative"
                      >
                        <IconButton
                          style={{
                            position: 'absolute',
                            bottom: theme.spacing(2),
                            left: theme.spacing(2),
                            zIndex: 1,
                          }}
                          onClick={() =>
                            setFormData((p) => ({
                              ...p,
                              characters: p.characters?.filter(
                                (c) => c.characterId !== character.characterId,
                              ),
                            }))
                          }
                        >
                          <Delete />
                        </IconButton>
                        <Watcher
                          values={[
                            characterPictures[character.characterId],
                            character.picture,
                          ]}
                          render={() => (
                            <Box gridRow="1 / span 4">
                              <Dropzone
                                files={
                                  characterPictures[character.characterId]
                                    ? [characterPictures[character.characterId]]
                                    : []
                                }
                                onChange={([img]) =>
                                  setCharacterPictures((p) => ({
                                    ...p,
                                    [character.characterId]: img,
                                  }))
                                }
                                multiple={false}
                                renderDragActive={() => (
                                  <>Drop your files here</>
                                )}
                                renderEmpty={() =>
                                  character.picture ? (
                                    <DropzoneAfterDrop
                                      imgSrc={character.picture}
                                      onDelete={() =>
                                        updateCharacter(character.characterId, {
                                          picture: undefined,
                                        })
                                      }
                                    />
                                  ) : undefined
                                }
                                renderAfterDrop={() => (
                                  <DropzoneAfterDrop
                                    imgSrc={URL.createObjectURL(
                                      characterPictures[character.characterId],
                                    )}
                                    onDelete={() =>
                                      setCharacterPictures((p) => {
                                        const t = { ...p };
                                        delete t[character.characterId];
                                        return t;
                                      })
                                    }
                                  />
                                )}
                                containerStyle={{
                                  minHeight: 148,
                                  height: '100%',
                                  position: 'relative',
                                }}
                              />
                            </Box>
                          )}
                        />
                        <Watcher
                          values={[character.name]}
                          render={() => (
                            <TextField
                              label="Character Name"
                              placeholder="Character Name"
                              value={character.name}
                              required
                              style={{ gridColumn: '2 / span 1' }}
                              onChange={(ev) => {
                                const { value } = ev.currentTarget;
                                updateCharacter(character.characterId, {
                                  name: value,
                                });
                              }}
                            />
                          )}
                        />
                        <Box
                          display="grid"
                          gridColumn="2 / span 1"
                          gridTemplateColumns="1fr 1fr"
                          gridGap={theme.spacing(4)}
                        >
                          <Watcher
                            values={[character.age]}
                            render={() => (
                              <TextField
                                label="Age Range"
                                placeholder="40 yo"
                                value={character.age ?? ''}
                                onChange={(ev) => {
                                  const { value } = ev.currentTarget;
                                  updateCharacter(character.characterId, {
                                    age: value,
                                  });
                                }}
                              />
                            )}
                          />
                          <Watcher
                            values={[character.gender]}
                            render={() => (
                              <SelectField
                                labelBgcolor={lighten(
                                  theme.palette.primary.main,
                                  0.05,
                                )}
                                empty
                                options={['Male', 'Female', 'Other'].map(
                                  (name) => ({
                                    label: name,
                                    value: name,
                                  }),
                                )}
                                label="Gender"
                                value={character.gender}
                                onChange={(value) =>
                                  updateCharacter(character.characterId, {
                                    gender: value as string,
                                  })
                                }
                              />
                            )}
                          />
                        </Box>
                        <Watcher
                          values={[character.originallyVoicedBy]}
                          render={() => (
                            <TextField
                              label="Originally Voiced by"
                              placeholder="Originally Voiced by"
                              value={character.originallyVoicedBy}
                              style={{ gridColumn: '2 / span 1' }}
                              onChange={(ev) => {
                                const { value } = ev.currentTarget;
                                updateCharacter(character.characterId, {
                                  originallyVoicedBy: value,
                                });
                              }}
                            />
                          )}
                        />
                        <Watcher
                          values={[character.previousWork]}
                          render={() => (
                            <TextField
                              label="Previous Work"
                              placeholder="Previous Work"
                              value={character.previousWork}
                              style={{ gridColumn: '2 / span 1' }}
                              InputProps={{
                                style: { height: '100%' },
                              }}
                              onChange={(ev) => {
                                const { value } = ev.currentTarget;
                                updateCharacter(character.characterId, {
                                  previousWork: value,
                                });
                              }}
                            />
                          )}
                        />
                        <Watcher
                          values={[character.description]}
                          render={() => (
                            <TextField
                              label="Vocal Qualities / Description"
                              placeholder="Vocal Qualities / Description"
                              value={character.description}
                              onChange={(ev) => {
                                const { value } = ev.currentTarget;
                                updateCharacter(character.characterId, {
                                  description: value,
                                });
                              }}
                              inputProps={{
                                style: {
                                  height: '100%',
                                },
                              }}
                              InputProps={{
                                style: {
                                  height: '100%',
                                },
                              }}
                              multiline
                              style={{
                                gridRow: '1 / span 2',
                                gridColumn: '3 / span 1',
                              }}
                            />
                          )}
                        />
                        <Watcher
                          values={[character.episodes, formData.episodes]}
                          render={() => (
                            <SelectField
                              labelBgcolor={lighten(
                                theme.palette.primary.main,
                                0.05,
                              )}
                              multiple
                              options={
                                formData.episodes?.map((episode) => ({
                                  label: `${episode.number} – ${episode.title}`,
                                  value: episode.episodeId,
                                })) ?? []
                              }
                              empty
                              label="Episodes"
                              value={character.episodes}
                              style={{
                                gridColumn: '3 / span 1',
                                gridRow: '3 / span 1',
                              }}
                              onChange={(value) =>
                                updateCharacter(character.characterId, {
                                  episodes: value as string[],
                                })
                              }
                            />
                          )}
                        />
                        <Watcher
                          values={[
                            character.requiresVoiceTest,
                            character.principleCast,
                            character.childCharacter,
                          ]}
                          render={() => (
                            <Fieldset label="Please select all that apply:">
                              <div
                                style={{
                                  margin: '-11px 0',
                                  display: 'grid',
                                  gridTemplateColumns: 'auto auto auto',
                                  gridGap: theme.spacing(2),
                                }}
                              >
                                <FormControlLabel
                                  control={<Checkbox color="default" />}
                                  label="Principle Cast"
                                  checked={Boolean(character.principleCast)}
                                  onChange={(ev) => {
                                    const value = (ev.currentTarget as any)
                                      .checked;
                                    updateCharacter(character.characterId, {
                                      principleCast: value,
                                    });
                                  }}
                                />
                                <FormControlLabel
                                  control={<Checkbox color="default" />}
                                  label="Children"
                                  checked={Boolean(character.childCharacter)}
                                  onChange={(ev) => {
                                    const value = (ev.currentTarget as any)
                                      .checked;
                                    updateCharacter(character.characterId, {
                                      childCharacter: value,
                                    });
                                  }}
                                />
                                <FormControlLabel
                                  control={<Checkbox color="default" />}
                                  label="Requires Voice Test"
                                  checked={Boolean(character.requiresVoiceTest)}
                                  onChange={(ev) => {
                                    const value = (ev.currentTarget as any)
                                      .checked;
                                    updateCharacter(character.characterId, {
                                      requiresVoiceTest: value,
                                    });
                                  }}
                                />
                              </div>
                            </Fieldset>
                          )}
                        />
                      </Box>
                    )}
                  />
                ))}
              </Box>
            )}
          />
          <Watcher
            values={[formData.originalPostProductionServices]}
            render={() => (
              <Fieldset
                label="Original Post Production"
                gridColumn="1 / -1"
                gridRow="auto / span 2"
                variant="box"
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    marginTop: -theme.spacing(2),
                    marginBottom: theme.spacing(2),
                  }}
                >
                  Please check the services that are required.
                </Typography>
                <Box display="grid" gridTemplateColumns="repeat(6, auto)">
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Picture Editorial"
                    {...postProdChangeHandler('PICTURE_EDITORIAL')}
                  />
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="Sound Editorial/Mixing"
                    {...postProdChangeHandler('SOUND_EDITORIAL/MIXING')}
                  />
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="VFX"
                    {...postProdChangeHandler('VFX')}
                  />
                  <FormControlLabel
                    control={<Checkbox color="default" />}
                    label="DI Picture Finishing"
                    {...postProdChangeHandler('DI_PICTURE_FINISHING')}
                  />
                </Box>
              </Fieldset>
            )}
          />
          <Fieldset
            label="Localization"
            gridColumn="1 / -1"
            gridRow="auto / span 2"
            variant="box"
          >
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              gridGap={theme.spacing(4)}
            >
              <Watcher
                values={[formData.localizationServices]}
                render={() => (
                  <Fieldset
                    label="Services"
                    gridColumn="1 / span 1"
                    gridRow="auto / span 3"
                  >
                    <Typography
                      variant="subtitle2"
                      style={{ marginTop: -theme.spacing(2) }}
                    >
                      Please check the services that are required.
                    </Typography>
                    <Box
                      display="grid"
                      gridTemplateColumns="1fr 1fr 1fr"
                      gridColumnGap={theme.spacing(2)}
                      marginBottom={-3}
                    >
                      <FormControlLabel
                        control={<Checkbox color="default" />}
                        label="Scripting"
                        {...locServiceChangeHandler('SCRIPTING')}
                      />
                      <FormControlLabel
                        control={<Checkbox color="default" />}
                        label="Translating"
                        {...locServiceChangeHandler('TRANSLATING')}
                      />
                      <FormControlLabel
                        control={<Checkbox color="default" />}
                        label="Adapting"
                        {...locServiceChangeHandler('ADAPTING')}
                      />
                      <FormControlLabel
                        control={<Checkbox color="default" />}
                        label="Subtitling"
                        {...locServiceChangeHandler('SUBTITLING')}
                      />
                      <FormControlLabel
                        control={<Checkbox color="default" />}
                        label="Casting"
                        {...locServiceChangeHandler('CASTING')}
                      />
                      <FormControlLabel
                        control={<Checkbox color="default" />}
                        label="Recording"
                        {...locServiceChangeHandler('RECORDING')}
                      />
                      <FormControlLabel
                        control={<Checkbox color="default" />}
                        label="Editing"
                        {...locServiceChangeHandler('EDITING')}
                      />
                      <FormControlLabel
                        control={<Checkbox color="default" />}
                        label="Mixing"
                        {...locServiceChangeHandler('MIXING')}
                      />
                      <FormControlLabel
                        control={<Checkbox color="default" />}
                        label="Linguistic QC"
                        {...locServiceChangeHandler('LINGUISTIC_QC')}
                      />
                      <FormControlLabel
                        control={<Checkbox color="default" />}
                        label="Technical QC"
                        {...locServiceChangeHandler('TECHNICAL_QC')}
                      />
                      <FormControlLabel
                        control={<Checkbox color="default" />}
                        label="Complete QC"
                        {...locServiceChangeHandler('COMPLETE_QC')}
                      />
                    </Box>
                  </Fieldset>
                )}
              />
              <Watcher
                values={[formData.requestedSubtitledLanguages]}
                render={() => (
                  <SelectField
                    empty
                    multiple
                    style={{
                      gridColumn: '2 / span 1',
                    }}
                    options={allLanguages.map((lang) => ({
                      label: lang,
                      value: lang,
                    }))}
                    value={formData.requestedSubtitledLanguages}
                    onChange={(value) =>
                      update(
                        'requestedSubtitledLanguages',
                        value as E.Language[],
                      )
                    }
                    labelBgcolor={theme.palette.primary.light}
                    label="Requested Subtitled Languages"
                  />
                )}
              />
              <Watcher
                values={[formData.requestedDubbedLanguages]}
                render={() => (
                  <SelectField
                    empty
                    multiple
                    style={{
                      gridColumn: '2 / span 1',
                    }}
                    options={allLanguages.map((lang) => ({
                      label: lang,
                      value: lang,
                    }))}
                    value={formData.requestedDubbedLanguages}
                    onChange={(value) =>
                      update('requestedDubbedLanguages', value as E.Language[])
                    }
                    label="Requested Dubbed Languages"
                    labelBgcolor={theme.palette.primary.light}
                  />
                )}
              />
              <Watcher
                values={[formData.dubbingStyle]}
                render={() => (
                  <SelectField
                    empty
                    options={[
                      {
                        label: 'Lip Sync',
                        value: 'LIP_SYNC',
                      },
                      {
                        label: 'Voice Over',
                        value: 'VOICE_OVER',
                      },
                    ]}
                    value={formData.dubbingStyle ?? ''}
                    onChange={(value) => update('dubbingStyle', value as any)}
                    label="Dubbing Style"
                    labelBgcolor={theme.palette.primary.light}
                    style={{
                      gridColumn: '2 / span 1',
                    }}
                  />
                )}
              />
            </Box>
          </Fieldset>
          <Box gridColumn="1 / -1" display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disableElevation
              disabled={working}
            >
              {working && (
                <CircularProgress
                  size={16}
                  style={{ marginRight: theme.spacing(2) }}
                />
              )}
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </MuiPickersUtilsProvider>
  );
};
