import React, { FC, ReactNode, useState, useMemo, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import {
  Paper,
  Button,
  Chip,
  Box,
  useTheme,
  Typography,
  MenuItem,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { sortBy } from 'lodash';

import { useAppFrameOptions } from 'src/hooks/navigation';
import {
  deserializeDateWithTimezone,
  timeToLocalDateString,
  timezoneCity,
} from 'src/util/date';
import CustomTable from 'src/components/CustomTable';
import { AutoMenu } from 'src/components/AutoPopover';
import * as I from 'src/components/Icons';
import { getProjects } from 'src/services/projects';
import { Loader } from 'src/components/Loader';
import { useSelector } from 'src/store';

const ItemContainer = styled(Paper)({
  overflow: 'hidden',
  height: '100%',
});

const GridViewItemInfo: FC<{ title: ReactNode; info: ReactNode }> = ({
  title,
  info,
}) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="caption" color="textSecondary">
        {title}
      </Typography>
      <Typography variant="subtitle2">{info}</Typography>
    </Box>
  );
};

const GridViewItem: FC<{ project: E.Project }> = ({ project }) => {
  const theme = useTheme();
  const user = useSelector((state) => state.session.user!);
  return (
    <Link to={`/projects/view/${project.projectId}`}>
      <ItemContainer>
        <div
          style={{
            paddingTop: '56.25%',
            background: `url(${project.coverImage}) no-repeat center`,
            backgroundSize: 'cover',
          }}
        />
        <Box padding={4}>
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: 'bold',
              lineHeight: 1.15,
              marginBottom: theme.spacing(2),
            }}
          >
            {project.title}
          </Typography>
          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gridColumnGap={theme.spacing(4)}
            gridRowGap={theme.spacing(2)}
          >
            <GridViewItemInfo title="Episodes" info={project.episodes.length} />
            <GridViewItemInfo title="Runtime" info={project.runtime + ' min'} />
            <GridViewItemInfo
              title="Start Date"
              info={(() => {
                const [date, tz] = deserializeDateWithTimezone(
                  project.postProductionStartDate,
                );
                return (
                  <span
                    title={
                      timeToLocalDateString(date, tz) + ` (${timezoneCity(tz)})`
                    }
                  >
                    {timeToLocalDateString(date, user.timezone)}
                  </span>
                );
              })()}
            />
            <GridViewItemInfo
              title="Due Date"
              info={(() => {
                const [date, tz] = deserializeDateWithTimezone(
                  project.finalDeliverablesDueDate,
                );
                return (
                  <span
                    title={
                      timeToLocalDateString(date, tz) + ` (${timezoneCity(tz)})`
                    }
                  >
                    {timeToLocalDateString(date, user.timezone)}
                  </span>
                );
              })()}
            />
          </Box>
        </Box>
      </ItemContainer>
    </Link>
  );
};

const sortFieldsMap: [keyof E.Project, string][] = [
  ['finalDeliverablesDueDate', 'Due Date'],
  ['postProductionStartDate', 'Start Date'],
  ['runtime', 'Runtime'],
  ['episodes', 'Episodes'],
  ['title', 'Title'],
];

export const ProjectsPage = () => {
  const theme = useTheme();
  const history = useHistory();
  const user = useSelector((state) => state.session.user!);

  const [mode, setMode] = useState('grid' as 'grid' | 'list');
  const [showingOrderBy, setShowingOrderBy] = useState(false);
  const [showingOrder, setShowingOrder] = useState(false);
  const [orderBy, setOrderBy] = useState<keyof E.Project>(
    'finalDeliverablesDueDate',
  );
  const [order, setOrder] = useState('asc' as 'asc' | 'desc');
  const [projects, setProjects] = useState<E.Project[] | -1>();

  useAppFrameOptions(
    useMemo(
      () => ({
        topbarLeftActions: (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => history.push('/projects/edit')}
            disableElevation
          >
            New Project
          </Button>
        ),
        title: 'Projects',
      }),
      [history],
    ),
  );

  const sortedProjects = useMemo(() => {
    const sorted = sortBy(projects instanceof Array ? projects : [], orderBy);
    return order === 'asc' ? sorted : sorted.reverse();
  }, [order, orderBy, projects]);

  useEffect(() => {
    getProjects()
      .then(setProjects)
      .catch(() => {});
  }, []);

  return projects === -1 || !projects ? (
    <Loader loading={!projects} error={projects === -1} />
  ) : (
    <>
      <Box
        padding={8}
        display="grid"
        gridTemplateColumns={
          mode === 'grid' ? 'repeat(auto-fill, 200px)' : '1fr'
        }
        justifyContent="center"
        gridGap={theme.spacing(6)}
      >
        <Box display="flex" justifyContent="space-between" gridColumn="1 / -1">
          <Box
            display="grid"
            alignItems="center"
            gridTemplateColumns="auto auto auto"
            gridColumnGap={theme.spacing(2)}
          >
            {mode === 'grid' && (
              <>
                <Typography variant="overline" color="textSecondary">
                  Sort
                </Typography>
                <div>
                  <Chip
                    label={sortFieldsMap.find(([key]) => key === orderBy)?.[1]}
                    deleteIcon={<KeyboardArrowDown />}
                    onDelete={() => setShowingOrderBy(true)}
                    onClick={() => setShowingOrderBy(true)}
                    size="medium"
                  />
                  <AutoMenu
                    open={showingOrderBy}
                    onClose={() => setShowingOrderBy(false)}
                    onClick={() => setShowingOrderBy(false)}
                  >
                    {sortFieldsMap.map(([key, label]) => (
                      <MenuItem key={key} onClick={() => setOrderBy(key)}>
                        <Typography variant="inherit">{label}</Typography>
                      </MenuItem>
                    ))}
                  </AutoMenu>
                </div>
                <div>
                  <Chip
                    label={order.toUpperCase()}
                    icon={
                      order === 'asc' ? (
                        <I.SortAscending
                          size={16}
                          style={{ marginLeft: theme.spacing(3) }}
                        />
                      ) : (
                        <I.SortDescending
                          size={16}
                          style={{ marginLeft: theme.spacing(3) }}
                        />
                      )
                    }
                    deleteIcon={<KeyboardArrowDown />}
                    onDelete={() => setShowingOrder(true)}
                    onClick={() => setShowingOrder(true)}
                    size="medium"
                  />
                  <AutoMenu
                    open={showingOrder}
                    onClose={() => setShowingOrder(false)}
                    onClick={() => setShowingOrder(false)}
                  >
                    <MenuItem onClick={() => setOrder('asc')}>
                      <I.SortAscending
                        size={16}
                        style={{ marginRight: theme.spacing(2) }}
                      />
                      <Typography variant="inherit">ASC</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => setOrder('desc')}>
                      <I.SortDescending
                        size={16}
                        style={{ marginRight: theme.spacing(2) }}
                      />
                      <Typography variant="inherit">DESC</Typography>
                    </MenuItem>
                  </AutoMenu>
                </div>
              </>
            )}
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="auto auto"
            gridColumnGap={theme.spacing(2)}
          >
            <Button disabled={mode === 'grid'} onClick={() => setMode('grid')}>
              <I.Grid size={17} style={{ marginRight: theme.spacing(2) }} />
              Grid
            </Button>
            <Button disabled={mode === 'list'} onClick={() => setMode('list')}>
              <I.List size={18} style={{ marginRight: theme.spacing(2) }} />
              List
            </Button>
          </Box>
        </Box>
        {mode === 'grid' && (
          <>
            {sortedProjects.map((project) => (
              <GridViewItem key={project.projectId} project={project} />
            ))}
          </>
        )}
        {mode === 'list' && (
          <Box gridColumn="1 / -1">
            <CustomTable
              onClickRow={(row) => history.push(`/projects/view/${row.id}`)}
              rows={projects.map((a) => ({
                ...a,
                languages: [
                  ...new Set([
                    ...a.requestedDubbedLanguages,
                    ...a.requestedSubtitledLanguages,
                  ]),
                ].join(', '),
                episodes: a.episodes.length,
                id: a.projectId,
              }))}
              columns={[
                {
                  id: 'coverImage',
                  label: 'Image',
                  bodyCellStyle: {
                    position: 'relative',
                    minHeight: 78,
                    width: 145,
                  },
                  render: (row) => (
                    <img
                      src={
                        row.coverImage ??
                        'https://www.cowgirlcontractcleaning.com/wp-content/uploads/sites/360/2018/05/placeholder-img.jpg'
                      }
                      alt={row.title}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  ),
                  disablePaddingBody: true,
                },
                {
                  id: 'title',
                  label: 'Title',
                },
                {
                  id: 'episodes',
                  label: 'Episodes',
                },
                {
                  id: 'postProductionStartDate',
                  label: (
                    <span style={{ whiteSpace: 'nowrap' }} title="Start Date">
                      Start Date
                    </span>
                  ),
                  render: (row) => {
                    const [date, tz] = deserializeDateWithTimezone(
                      row.postProductionStartDate,
                    );
                    return (
                      <span
                        title={
                          timeToLocalDateString(date, tz) +
                          ` (${timezoneCity(tz)})`
                        }
                      >
                        {timeToLocalDateString(date, user.timezone)}
                      </span>
                    );
                  },
                },
                {
                  id: 'finalDeliverablesDueDate',
                  label: (
                    <span style={{ whiteSpace: 'nowrap' }} title="Due Date">
                      Due Date
                    </span>
                  ),
                  render: (row) => {
                    const [date, tz] = deserializeDateWithTimezone(
                      row.finalDeliverablesDueDate,
                    );
                    return (
                      <span
                        title={
                          timeToLocalDateString(date, tz) +
                          ` (${timezoneCity(tz)})`
                        }
                      >
                        {timeToLocalDateString(date, user.timezone)}
                      </span>
                    );
                  },
                },
                {
                  id: 'runtime',
                  label: 'Runtime',
                },
                {
                  id: 'languages',
                  label: 'Languages',
                },
              ]}
              defaultOrder="desc"
              defaultOrderBy="finalDeliverablesDueDate"
            />
          </Box>
        )}
      </Box>
    </>
  );
};
