import React, { ReactNode } from 'react';
import { Box, AppBar, Tabs as MuiTabs, Tab } from '@material-ui/core';

export const Tabs = ({
  items,
  active,
}: {
  items: { label: ReactNode; content: ReactNode; onActive?: () => unknown }[];
  active: number;
}) => {
  return (
    <>
      <AppBar position="static">
        <MuiTabs
          value={active}
          onChange={(_, v) => {
            items[v].onActive?.();
          }}
          aria-label="tab"
        >
          {items.map((item, i) => (
            <Tab
              key={i}
              label={item.label}
              id={`tab-${i}`}
              aria-controls={`tabpanel-${i}`}
              style={{ flex: 1, maxWidth: 'none' }}
            />
          ))}
        </MuiTabs>
      </AppBar>
      {items.map((item, i) => (
        <div
          key={i}
          role="tabpanel"
          hidden={active !== i}
          id={`tabpanel-${i}`}
          aria-labelledby={`tab-${i}`}
        >
          {active === i && <Box>{item.content}</Box>}
        </div>
      ))}
    </>
  );
};
