import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  useTheme,
} from '@material-ui/core';
import {
  ExpandMore,
  CloudUpload,
  CloudDownload,
  Send,
} from '@material-ui/icons';
import { getAssets } from 'src/services/assets';
import CustomTable, { CustomTableProps } from 'src/components/CustomTable';
import { Loader } from 'src/components/Loader';
import { useStyles } from './styles';
import { localizationCategoryMap } from 'src/util/common';
import {
  deserializeDateWithTimezone,
  timeToLocalDateString,
  timezoneCity,
} from 'src/util/date';
import { useSelector } from 'src/store';
import { useGroups, groups, TGroupBy } from './useGroups';
import { StatusChip } from './StatusChip';
import { StatusSummary } from './StatusSummary';
import { ShareModal } from './ShareModal';
import { Upload } from './Upload';
import { AsperaFile } from 'src/services/aspera';
import { useAspera } from 'src/hooks/aspera';
import { requestAsperaUpload } from 'src/services/uploads';
import { CircularProgressWithLabel } from 'src/components/Progress';
import { requestAsperaDownload } from 'src/services/downloads';

export type AssetsTabProps = {
  project: E.Project;
  requestsView?: boolean;
};

type Selection = E.Asset & { episode: E.Episode };

export const AssetsTab = ({ project, requestsView }: AssetsTabProps) => {
  const styles = useStyles();
  const [loading, setLoading] = useState<boolean | -1>(false);
  const [assets, setAssets] = useState<E.Asset[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<Selection[]>([]);
  const [shareModal, setShareModal] = useState<Selection[]>();

  const [groupedAssets, groupingBy, setGrouping] = useGroups(
    project,
    assets,
    requestsView,
  );

  useEffect(() => {
    setLoading(true);
    getAssets(project.projectId)
      .then((ass) => {
        setAssets(
          ass.map((a) => {
            const rand = Math.round(Math.random() * 1000) % 4;
            return {
              ...a,
              status:
                rand === 3
                  ? 'OPEN'
                  : rand === 2
                  ? 'UNASSIGNED'
                  : rand === 1
                  ? 'ACCEPTED'
                  : 'REDELIVERY',
            };
          }),
        );
        setLoading(false);
      })
      .catch(() => setLoading(-1));
  }, [project.projectId]);

  const modalHandler = useCallback(
    (rows: Selection[]) =>
      rows ? setShareModal(rows) : setShareModal(selectedAssets),
    [selectedAssets],
  );

  return (
    <>
      <Box className={styles.header}>
        <Box className={styles.filters}>
          <Typography variant="body2">Group by</Typography>
          {groups.map((group) => (
            <Button
              key={group}
              variant="contained"
              color="primary"
              disableElevation
              size="small"
              disabled={group === groupingBy}
              onClick={() => setGrouping(group)}
            >
              {group}
            </Button>
          ))}
        </Box>
      </Box>
      <Box>
        {Boolean(loading) ? (
          <Loader loading={loading === true} error={loading === -1} />
        ) : (
          groupedAssets.map(([title, key, assets]) => (
            <Accordion
              key={key}
              className={styles.groupContainer}
              TransitionProps={{ unmountOnExit: true }}
            >
              <AccordionSummary expandIcon={<ExpandMore />}>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography component="div" className={styles.groupTitle}>
                    {title}
                  </Typography>
                  <StatusSummary assets={assets} />
                </div>
              </AccordionSummary>
              <AccordionDetails className={styles.groupDetailsContainer}>
                <Box p={4} className={styles.groupDetails}>
                  <Table
                    assets={assets}
                    groupingBy={groupingBy}
                    project={project}
                    requestsView={requestsView}
                    setSelectedAssets={setSelectedAssets}
                    shareModal={modalHandler}
                  />
                </Box>
              </AccordionDetails>
            </Accordion>
          ))
        )}
      </Box>
      {shareModal && (
        <ShareModal
          open={Boolean(shareModal)}
          onClose={() => setShareModal(undefined)}
          assets={shareModal}
        />
      )}
    </>
  );
};

const Table = ({
  assets,
  project,
  requestsView,
  setSelectedAssets,
  shareModal,
  groupingBy,
}: {
  assets: E.Asset[];
  project: E.Project;
  requestsView?: boolean;
  setSelectedAssets(assets: Selection[]): unknown;
  shareModal(rows?: Selection[]): unknown;
  groupingBy: TGroupBy;
}) => {
  const aspera = useAspera();
  const theme = useTheme();
  const user = useSelector((state) => state.session.user!);

  const [files, setFiles] = useState<
    Record<
      string,
      {
        files: (AsperaFile & {
          progress?: number;
        })[];
        progress?: number;
        transferId?: string;
      }
    >
  >({});

  useEffect(
    () =>
      aspera.onTransfersUpdate(({ transfers }) => {
        if (!transfers.length) return;
        console.log(transfers);
        setFiles((p) =>
          Object.fromEntries(
            Object.entries(p).map(([id, info]) => {
              const transfer = transfers.find(
                (t) => t.aspera_connect_settings.request_id === info.transferId,
              );
              if (!transfer) return [id, info];
              if (
                transfer.status === 'completed' &&
                transfer.previous_status === 'running'
              )
                return [
                  id,
                  {
                    ...info,
                    transferId: undefined,
                    progress: undefined,
                    files: [],
                  },
                ];
              return [
                id,
                {
                  ...info,
                  progress: transfer.percentage,
                  files: info.files.map((f) => {
                    const file = transfer.files?.find((x) => x.file === f.name);
                    return {
                      ...f,
                      progress:
                        file && file.bytes_written / file.bytes_expected,
                    };
                  }),
                },
              ];
            }),
          ),
        );
      }),
    [aspera],
  );

  const isSomeTransferHappening = useMemo(
    () => Object.values(files).some((v) => v.transferId),
    [files],
  );

  const tableRows = useMemo(
    () =>
      assets.map((ass) => {
        const episode = project.episodes.find(
          (e) => e.episodeId === ass.episodeId,
        )!;
        return {
          ...ass,
          id: ass.assetId,
          episode,
          _episode_sort: episode.number,
          _dueDate_sort: deserializeDateWithTimezone(ass.dueDate)[0].getTime(),
          language: ass.language ?? ('OV' as E.Language),
          dueDateTz: deserializeDateWithTimezone(ass.dueDate),
          progress: files[ass.assetId]?.progress,
        };
      }),
    [assets, files, project.episodes],
  );

  type TableData = typeof tableRows[0];

  const tableColumns: CustomTableProps<TableData>['columns'] = useMemo(
    () => [
      {
        id: '_' as any,
        label: '',
        disablePaddingBody: true,
        disablePaddingHeader: true,
        render: requestsView
          ? (row, expand) => (
              <IconButton
                onClick={() => {
                  expand();
                }}
                style={{ marginRight: -theme.spacing(4) }}
              >
                <CloudUpload />
              </IconButton>
            )
          : (row) => (
              <>
                <IconButton
                  onClick={async () => {
                    const data = await requestAsperaDownload(
                      row.assetId,
                      row.projectId,
                      row.episodeId,
                    );
                    if (data.transfer_specs && data.transfer_specs[0]) {
                      aspera.download(
                        data.transfer_specs[0].transfer_spec,
                        undefined,
                        () => console.log('download started'),
                      );
                    }
                  }}
                >
                  <CloudDownload />
                </IconButton>
                <IconButton
                  onClick={() => {
                    shareModal([row]);
                  }}
                  style={{ marginRight: -theme.spacing(6) }}
                >
                  <Send />
                </IconButton>
              </>
            ),
      },
      groupingBy !== 'Episode' && {
        id: 'episode',
        label: 'Episode',
        align: 'left',
        render: ({ episode }) => `Episode ${episode.number} – ${episode.title}`,
        filterValue: ({ episode }) =>
          `Episode ${episode.number} – ${episode.title}`,
      },
      {
        id: 'asset',
        label: 'Asset',
        align: 'left',
        filterValue: ({ asset }) => asset,
      },
      {
        id: 'dueDate',
        label: 'Due Date',
        align: 'left',
        render: ({ dueDateTz: [d, tz] }) => (
          <span title={timeToLocalDateString(d, tz) + ` (${timezoneCity(tz)})`}>
            {timeToLocalDateString(d, user.timezone)}
          </span>
        ),
        filterValue: ({ dueDateTz: [d] }) =>
          timeToLocalDateString(d, user.timezone),
      },
      groupingBy !== 'Language' && {
        id: 'language',
        label: 'Language',
        align: 'left',
        filterValue: ({ language }) => language,
      },
      groupingBy !== 'Asset Type' && {
        id: 'category',
        label: 'Service',
        align: 'left',
        render: ({ category }) => localizationCategoryMap[category],
        filterValue: ({ category }) => localizationCategoryMap[category],
      },
      {
        id: 'status',
        label: 'Status',
        render: ({ status }) => (
          <div style={{ textAlign: 'center' }}>
            <StatusChip status={status} />
          </div>
        ),
        filterValue: ({ status }) => status,
      },
      isSomeTransferHappening && {
        id: 'progress',
        label: 'Progress',
        render: ({ progress }) => (
          <div style={{ textAlign: 'center' }}>
            {progress === undefined ? (
              '--'
            ) : (
              <CircularProgressWithLabel value={progress * 100} />
            )}
          </div>
        ),
      },
    ],
    [
      aspera,
      groupingBy,
      isSomeTransferHappening,
      requestsView,
      shareModal,
      theme,
      user.timezone,
    ],
  );

  const table = useMemo(
    () => (
      <CustomTable
        rows={tableRows}
        columns={tableColumns}
        renderExpanded={(row) => (
          <Upload
            id={row.assetId}
            files={files[row.assetId]?.files ?? []}
            transferring={
              files[row.assetId]?.progress !== undefined &&
              files[row.assetId]?.progress !== 1
            }
            onChange={(files) => {
              setFiles((p) => ({
                ...p,
                [row.assetId]: {
                  ...p[row.assetId],
                  files,
                },
              }));
            }}
            onStartTransfer={async () => {
              const data = await requestAsperaUpload(
                row.assetId,
                row.projectId,
                row.episodeId,
              );
              if (data.transfer_specs && data.transfer_specs[0]) {
                aspera.upload(
                  data.transfer_specs[0].transfer_spec,
                  files[row.assetId].files.map((a) => ({
                    source: a.name,
                  })),
                  () => {
                    alert('Something went wrong. Please try again.');
                  },
                  (transferId) => {
                    setFiles((p) => ({
                      ...p,
                      [row.assetId]: {
                        ...p[row.assetId],
                        transferId,
                      },
                    }));
                  },
                );
              }
            }}
          />
        )}
        defaultOrder="asc"
        defaultOrderBy={groupingBy === 'Episode' ? 'dueDate' : 'episode'}
        onSelectionChanged={(sel) => {
          setSelectedAssets(sel);
        }}
        selectionOptions={
          requestsView ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => alert('!')}
              disableElevation
            >
              Assign Selected
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => alert('!')}
                disableElevation
                style={{ marginRight: theme.spacing(3) }}
              >
                <CloudDownload style={{ marginRight: theme.spacing(2) }} />
                Download
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => shareModal()}
                disableElevation
              >
                <Send style={{ marginRight: theme.spacing(2) }} />
                Share
              </Button>
            </>
          )
        }
        rowsPerPageOptions={[10, 25, 50, 100, 1000]}
        defaultRowsPerPage={25}
        onSearchChange={() => {}}
      />
    ),
    [
      aspera,
      files,
      groupingBy,
      requestsView,
      setSelectedAssets,
      shareModal,
      tableColumns,
      tableRows,
      theme,
    ],
  );

  return table;
};
