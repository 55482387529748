import React, { ReactNode } from 'react';
import {
  Box,
  Checkbox,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { Star, ExpandMore } from '@material-ui/icons';

import { useStyles } from './styles';
import { VendorInfo } from './types';

export const ServiceSection = ({
  title,
  vendors,
  category,
  selected,
  toggleSelection,
  invited,
}: {
  title: ReactNode;
  category: 'dubbing' | 'qc' | 'subtitling' | 'scripting';
  vendors: VendorInfo[];
  selected: boolean;
  invited: boolean;
  toggleSelection(): unknown;
}) => {
  const classes = useStyles();
  return (
    <>
      <Accordion
        className={classes.root}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Checkbox
            color="default"
            checked={selected}
            onChange={() => toggleSelection()}
            onClick={(ev) => ev.stopPropagation()}
            disabled={invited}
          />
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" className="comparasion">
            {vendors.map((vendor) => (
              <div key={vendor.vendorId} className="comparasion-item">
                <div className="comparasion-item__header">
                  <div
                    className="comparasion-item__background"
                    style={{
                      backgroundImage: `url(${vendor.logo})`,
                    }}
                  />
                  <div className="comparasion-item__header-content">
                    <Checkbox
                      color="default"
                      checked={vendor[category]?.selected}
                      onChange={() => vendor[category]?.toggleSelection()}
                      disabled={vendor[category]?.invited}
                    />
                    <div
                      style={{ marginLeft: -10, flex: 1, textAlign: 'center' }}
                    >
                      {vendor.logo ? (
                        <img
                          src={vendor.logo}
                          alt={vendor.name}
                          className="comparasion-item__logo"
                        />
                      ) : (
                        <Typography variant="body1">{vendor.name}</Typography>
                      )}
                      <div className="comparasion-item__rating">
                        {[0, 0, 0, 0, 0]
                          .slice(0, Math.round(vendor.rating ?? 0))
                          .map((_, k) => (
                            <Star key={k} style={{ width: 18 }} />
                          ))}
                        ・
                        <Typography variant="body2">
                          {vendor.location}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
                {vendor[category]?.services.map((service) => (
                  <div style={{ padding: '8px' }} key={service.id}>
                    <div>
                      <Checkbox
                        color="default"
                        checked={service.selected}
                        onChange={() => service.toggleSelection()}
                        disabled={service.invited}
                      />
                      {service.label}
                      {service.services?.map((subService) => (
                        <div style={{ marginLeft: 24 }} key={subService.id}>
                          <Checkbox
                            disabled={subService.invited}
                            color="default"
                            checked={subService.selected}
                            onChange={() => subService.toggleSelection()}
                          />
                          {subService.label}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
