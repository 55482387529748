import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  useTheme,
  Chip,
  MenuItem,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

import { SortField, Order, ViewMode } from '.';
import { BidStatusChip } from './StatusChip';
import { AutoMenu } from 'src/components/AutoPopover';
import * as I from 'src/components/Icons';
import { BidStatus } from '../common';

const sortFieldsMap: [SortField, string][] = [
  ['dueDate', 'Due Date'],
  ['submittedAt', 'Submission Date'],
  ['vendorId', 'Vendor'],
  ['projectId', 'Project'],
];

export const BidsHeader = ({
  filterStatus,
  setFilterStatus,
  order,
  setOrder,
  mode,
  setMode,
  orderBy,
  setOrderBy,
}: {
  filterStatus: BidStatus;
  setFilterStatus: (status: BidStatus) => unknown;
  order: Order;
  setOrder: (order: Order) => unknown;
  mode: ViewMode;
  setMode: (mode: ViewMode) => unknown;
  orderBy: SortField;
  setOrderBy: (orderBy: SortField) => unknown;
}) => {
  const theme = useTheme();
  const [showingOrderBy, setShowingOrderBy] = useState(false);
  const [showingOrder, setShowingOrder] = useState(false);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      gridColumn="1 / -1"
      height={36}
    >
      <Box display="flex">
        <Box
          display="grid"
          alignItems="center"
          gridTemplateColumns="repeat(6, auto)"
          gridColumnGap={theme.spacing(2)}
          mr={6}
        >
          <Typography variant="overline" color="textSecondary">
            Status
          </Typography>
          {(['OPEN', 'SUBMITTED', 'HIRED', 'DECLINED'] as BidStatus[]).map(
            (status) => (
              <BidStatusChip
                key={status}
                status={status}
                currentStatus={filterStatus}
                onClick={() => setFilterStatus(status)}
              />
            ),
          )}
        </Box>
        {mode === 'grid' && (
          <Box
            display="grid"
            alignItems="center"
            gridTemplateColumns="auto auto auto"
            gridColumnGap={theme.spacing(2)}
          >
            <Typography variant="overline" color="textSecondary">
              Sort
            </Typography>
            <div>
              <Chip
                label={sortFieldsMap.find(([key]) => key === orderBy)?.[1]}
                deleteIcon={<KeyboardArrowDown />}
                onDelete={() => setShowingOrderBy(true)}
                onClick={() => setShowingOrderBy(true)}
                size="medium"
              />
              <AutoMenu
                open={showingOrderBy}
                onClose={() => setShowingOrderBy(false)}
                onClick={() => setShowingOrderBy(false)}
              >
                {sortFieldsMap.map(([key, label]) => (
                  <MenuItem key={key} onClick={() => setOrderBy(key)}>
                    <Typography variant="inherit">{label}</Typography>
                  </MenuItem>
                ))}
              </AutoMenu>
            </div>
            <div>
              <Chip
                label={order.toUpperCase()}
                icon={
                  order === 'asc' ? (
                    <I.SortAscending
                      size={16}
                      style={{ marginLeft: theme.spacing(3) }}
                    />
                  ) : (
                    <I.SortDescending
                      size={16}
                      style={{ marginLeft: theme.spacing(3) }}
                    />
                  )
                }
                deleteIcon={<KeyboardArrowDown />}
                onDelete={() => setShowingOrder(true)}
                onClick={() => setShowingOrder(true)}
                size="medium"
              />
              <AutoMenu
                open={showingOrder}
                onClose={() => setShowingOrder(false)}
                onClick={() => setShowingOrder(false)}
              >
                <MenuItem onClick={() => setOrder('asc')}>
                  <I.SortAscending
                    size={16}
                    style={{ marginRight: theme.spacing(2) }}
                  />
                  <Typography variant="inherit">ASC</Typography>
                </MenuItem>
                <MenuItem onClick={() => setOrder('desc')}>
                  <I.SortDescending
                    size={16}
                    style={{ marginRight: theme.spacing(2) }}
                  />
                  <Typography variant="inherit">DESC</Typography>
                </MenuItem>
              </AutoMenu>
            </div>
          </Box>
        )}
      </Box>
      <Box
        display="grid"
        gridTemplateColumns={'auto auto auto'}
        gridColumnGap={theme.spacing(2)}
      >
        <Button disabled={mode === 'grid'} onClick={() => setMode('grid')}>
          <I.Grid size={17} style={{ marginRight: theme.spacing(2) }} />
          Grid
        </Button>
        <Button disabled={mode === 'list'} onClick={() => setMode('list')}>
          <I.List size={18} style={{ marginRight: theme.spacing(2) }} />
          List
        </Button>
      </Box>
    </Box>
  );
};
