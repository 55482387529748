export const allLanguages: E.Language[] = [
  'Bokmal (nb)',
  'Chinese (zh)',
  'Czech (cs)',
  'Danish (da)',
  'Dutch (nl)',
  'English-UK (en-GB)',
  'English (en)',
  'Finnish (fi)',
  'French (fr)',
  'German (de)',
  'Greek (el)',
  'Hebrew (he)',
  'Hindi (hi)',
  'Hungarian (hu)',
  'Indonesian (id)',
  'Italian (it)',
  'Japanese (ja)',
  'Korean (ko)',
  'Mandarin-China (zh-CN)',
  'Mandarin-Taiwan (zh-TW)',
  'Polish (pl)',
  'Portuguese (pt)',
  'Portuguese-Brazilian (pt-BR)',
  'Romanian (ro)',
  'Russian (ru)',
  'Spanish-Castilian (es-ES)',
  'Spanish-Neutral (es)',
  'Swedish (sv)',
  'Tamil (ta)',
  'Telugu (te)',
  'Thai (th)',
  'Turkish (tr)',
  'Vietnamese (vi)',
];
