import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { TextField } from 'src/components/Fields';
import { Fieldset } from 'src/components/Fieldset';
import { makeStyles } from 'src/styles';
import { Send } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'contents',
  },
  wrapper: {},
  fieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
  },
  messageField: {
    marginTop: theme.spacing(4),
    width: '100%',
    minWidth: 350,
  },
  assetList: {
    height: 130,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  asset: {
    whiteSpace: 'nowrap',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& + &': {
      marginTop: theme.spacing(3),
    },
  },
}));

export const ShareModal = ({
  open,
  onClose,
  assets,
}: {
  open: boolean;
  onClose: () => unknown;
  assets: (E.Asset & { episode: E.Episode })[];
}) => {
  const s = useStyles();
  const [working, setWorking] = useState(false);

  return (
    <div className={s.root}>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Share</DialogTitle>
        <DialogContent>
          <form>
            <div className={s.wrapper}>
              <Fieldset label={`Assets ${assets.length}`}>
                <ul className={s.assetList}>
                  {assets.map((a) => (
                    <li key={a.assetId} className={s.asset}>
                      {a.asset} – {a.episode.title} – {a.language}
                    </li>
                  ))}
                </ul>
              </Fieldset>
              <div className={s.fieldContainer}>
                <TextField
                  label="Recipient emails"
                  placeholder="mail@example.com, mail2@exmaple.com"
                />
                <TextField
                  className={s.messageField}
                  label="Message (optional)"
                  multiline
                />
              </div>
            </div>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setWorking(true);
                setTimeout(() => {
                  setWorking(false);
                  onClose();
                }, 2000);
              }}
              disableElevation
              disabled={working}
              style={{
                margin: '16px 0 12px',
                width: '100%',
              }}
            >
              {working ? (
                <CircularProgress size={16} style={{ marginRight: 8 }} />
              ) : (
                <Send style={{ marginRight: 8 }} />
              )}
              Share
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
