import React, { useRef, ReactNode } from 'react';
import {
  TextField as MuiTextField,
  TextFieldProps,
  FormControl,
  InputLabel,
  useTheme,
  Select,
  MenuItem,
  SelectProps,
} from '@material-ui/core';

export const TextField = (props: TextFieldProps) => {
  return (
    <MuiTextField
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    />
  );
};

export type SelectFieldProps = {
  options: {
    label: ReactNode;
    value: string;
    disabled?: boolean;
  }[];
  labelBgcolor?: string;
  value?: string | string[];
  onChange?: (value: string | string[]) => unknown;
  label?: ReactNode;
  empty?: boolean;
  emptyLabel?: ReactNode;
} & Omit<SelectProps, 'value' | 'onChange'>;

export const SelectField = ({
  options,
  labelBgcolor,
  value,
  onChange,
  label,
  style,
  empty,
  emptyLabel,
  multiple,
  ...selectProps
}: SelectFieldProps) => {
  const uniqId = useRef(Math.random().toString());
  const theme = useTheme();
  return (
    <FormControl style={style} variant="outlined">
      <InputLabel
        id={uniqId.current}
        shrink
        style={{
          backgroundColor: labelBgcolor ?? theme.palette.primary.main,
          paddingLeft: theme.spacing(1.5),
          paddingRight: theme.spacing(1.5),
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={uniqId.current}
        value={
          empty && multiple && value?.length === 0
            ? ['__']
            : empty && !value
            ? '__'
            : value
        }
        onChange={(ev) => {
          const { value } = ev.target;
          if (value instanceof Array) {
            onChange?.(value.filter((v) => v !== '__'));
          } else if (value !== '__') {
            onChange?.(value as any);
          }
        }}
        multiple={multiple}
        {...selectProps}
      >
        {empty && (
          <MenuItem value="__" disabled>
            {emptyLabel ?? 'Select'}
          </MenuItem>
        )}
        {options.map(({ label, value, disabled }) => (
          <MenuItem value={value} key={value} disabled={Boolean(disabled)}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
