import React, { FC, ReactNode, useState, useMemo, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import {
  Paper,
  Button,
  Chip,
  Box,
  useTheme,
  Typography,
  MenuItem,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { sortBy } from 'lodash';

import { useAppFrameOptions } from 'src/hooks/navigation';
import CustomTable from 'src/components/CustomTable';
import { AutoMenu } from 'src/components/AutoPopover';
import * as I from 'src/components/Icons';
import { getVendors } from 'src/services/vendors';
import { Loader } from 'src/components/Loader';

export type VendorsViewData = (E.Vendor & {
  vendor: E.Vendor;
})[];

const ItemContainer = styled(Paper)({
  overflow: 'hidden',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const GridViewItemInfo: FC<{ title: ReactNode; info: ReactNode }> = ({
  title,
  info,
}) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography variant="caption" color="textSecondary">
        {title}
      </Typography>
      <Typography variant="subtitle2">{info}</Typography>
    </Box>
  );
};

const GridViewItem: FC<{ vendor: VendorsViewData[0] }> = ({ vendor }) => {
  const theme = useTheme();

  return (
    <Link to={`/vendors/view/${vendor.vendorId}`}>
      <ItemContainer>
        <div
          style={{
            paddingTop: '56.25%',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <div
            style={{
              background: `url(${vendor.vendor.logo}) no-repeat center`,
              backgroundSize: 'cover',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              filter: 'blur(30px)',
            }}
          />
          <img
            src={vendor.vendor.logo}
            alt={vendor.vendor.name}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '80%',
              transform: 'translate(-50%, -50%)',
              filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.3))',
            }}
          />
        </div>
        <Box
          padding={4}
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <div>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 'bold',
                lineHeight: 1.15,
                marginBottom: theme.spacing(2),
              }}
            >
              {vendor.name}
            </Typography>
            <Box
              display="grid"
              gridTemplateRows="auto auto auto"
              gridRowGap={theme.spacing(2)}
            >
              <GridViewItemInfo title="Location" info={vendor.location} />
              <GridViewItemInfo
                title="Languages"
                info={vendor.languages.join(', ')}
              />
              <GridViewItemInfo title="Website" info={vendor.website} />
            </Box>
          </div>
          <Box
            borderTop="1px solid rgba(255, 255, 255, 0.14)"
            paddingTop={2}
            marginTop={2}
          >
            SPEED QLTY. RLBY. COM.
          </Box>
        </Box>
      </ItemContainer>
    </Link>
  );
};

const sortFieldsMap: [keyof E.Vendor, string][] = [
  ['location', 'Location'],
  ['name', 'Name'],
];

export const VendorsPage = () => {
  const theme = useTheme();
  const history = useHistory();

  const [mode, setMode] = useState('grid' as 'grid' | 'list');
  const [showingOrderBy, setShowingOrderBy] = useState(false);
  const [showingOrder, setShowingOrder] = useState(false);
  const [orderBy, setOrderBy] = useState<keyof VendorsViewData[0]>('name');
  const [order, setOrder] = useState('asc' as 'asc' | 'desc');
  const [vendors, setVendors] = useState<VendorsViewData | -1>();

  useAppFrameOptions(
    useMemo(
      () => ({
        topbarLeftActions: (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => history.push('/vendors/edit')}
            disableElevation
          >
            New Vendor
          </Button>
        ),
        title: 'Vendors',
      }),
      [history],
    ),
  );

  const sortedVendors = useMemo(() => {
    const sorted = sortBy(vendors instanceof Array ? vendors : [], orderBy);
    return order === 'asc' ? sorted : sorted.reverse();
  }, [order, orderBy, vendors]);

  useEffect(() => {
    getVendors()
      .then(async (vendors) => {
        setVendors(
          vendors.map((vendor) => ({
            ...vendor,
            vendor: vendors.find((v) => v.vendorId === vendor.vendorId)!,
          })),
        );
      })
      .catch(() => {});
  }, []);

  return vendors === -1 || !vendors ? (
    <Loader loading={!vendors} error={vendors === -1} />
  ) : (
    <>
      <Box
        padding={8}
        display="grid"
        gridTemplateColumns={
          mode === 'grid' ? 'repeat(auto-fill, 200px)' : '1fr'
        }
        justifyContent="center"
        gridGap={theme.spacing(6)}
      >
        <Box display="flex" justifyContent="space-between" gridColumn="1 / -1">
          <Box display="flex">
            {mode === 'grid' && (
              <Box
                display="grid"
                alignItems="center"
                gridTemplateColumns="auto auto auto"
                gridColumnGap={theme.spacing(2)}
              >
                <Typography variant="overline" color="textSecondary">
                  Sort
                </Typography>
                <div>
                  <Chip
                    label={sortFieldsMap.find(([key]) => key === orderBy)?.[1]}
                    deleteIcon={<KeyboardArrowDown />}
                    onDelete={() => setShowingOrderBy(true)}
                    onClick={() => setShowingOrderBy(true)}
                    size="medium"
                  />
                  <AutoMenu
                    open={showingOrderBy}
                    onClose={() => setShowingOrderBy(false)}
                    onClick={() => setShowingOrderBy(false)}
                  >
                    {sortFieldsMap.map(([key, label]) => (
                      <MenuItem key={key} onClick={() => setOrderBy(key)}>
                        <Typography variant="inherit">{label}</Typography>
                      </MenuItem>
                    ))}
                  </AutoMenu>
                </div>
                <div>
                  <Chip
                    label={order.toUpperCase()}
                    icon={
                      order === 'asc' ? (
                        <I.SortAscending
                          size={16}
                          style={{ marginLeft: theme.spacing(3) }}
                        />
                      ) : (
                        <I.SortDescending
                          size={16}
                          style={{ marginLeft: theme.spacing(3) }}
                        />
                      )
                    }
                    deleteIcon={<KeyboardArrowDown />}
                    onDelete={() => setShowingOrder(true)}
                    onClick={() => setShowingOrder(true)}
                    size="medium"
                  />
                  <AutoMenu
                    open={showingOrder}
                    onClose={() => setShowingOrder(false)}
                    onClick={() => setShowingOrder(false)}
                  >
                    <MenuItem onClick={() => setOrder('asc')}>
                      <I.SortAscending
                        size={16}
                        style={{ marginRight: theme.spacing(2) }}
                      />
                      <Typography variant="inherit">ASC</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => setOrder('desc')}>
                      <I.SortDescending
                        size={16}
                        style={{ marginRight: theme.spacing(2) }}
                      />
                      <Typography variant="inherit">DESC</Typography>
                    </MenuItem>
                  </AutoMenu>
                </div>
              </Box>
            )}
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="auto auto"
            gridColumnGap={theme.spacing(2)}
          >
            <Button disabled={mode === 'grid'} onClick={() => setMode('grid')}>
              <I.Grid size={17} style={{ marginRight: theme.spacing(2) }} />
              Grid
            </Button>
            <Button disabled={mode === 'list'} onClick={() => setMode('list')}>
              <I.List size={18} style={{ marginRight: theme.spacing(2) }} />
              List
            </Button>
          </Box>
        </Box>
        {mode === 'grid' && (
          <>
            {sortedVendors.map((vendor) => (
              <GridViewItem key={vendor.vendorId} vendor={vendor} />
            ))}
          </>
        )}
        {mode === 'list' && (
          <Box gridColumn="1 / -1">
            <CustomTable
              onClickRow={(row) => history.push(`/vendors/view/${row.id}`)}
              rows={vendors.map((a) => ({
                ...a,
                languages: a.languages.join(', '),
                id: a.vendorId,
              }))}
              columns={[
                {
                  id: 'vendorId',
                  label: 'Vendor',
                  bodyCellStyle: {
                    position: 'relative',
                    minHeight: 78,
                    width: 145,
                  },
                  render: (row) => (
                    <img
                      src={
                        row.vendor.logo ??
                        'https://www.cowgirlcontractcleaning.com/wp-content/uploads/sites/360/2018/05/placeholder-img.jpg'
                      }
                      alt={row.vendor.name}
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        width: '80%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  ),
                  disablePaddingBody: true,
                },
                {
                  id: 'name',
                  label: 'Name',
                  render: (row) => (
                    <div style={{ textAlign: 'center' }}>{row.name}</div>
                  ),
                },
                {
                  id: 'location',
                  label: 'Location',
                },
                {
                  id: 'languages',
                  label: 'Languages',
                },
                {
                  id: 'localizationServices',
                  label: 'Localization Services',
                  render: (row) =>
                    row.localizationServices?.map((a) => (
                      <div key={a}>{a}</div>
                    )),
                },
                {
                  id: 'originalPostProductionServices',
                  label: 'Post Production Services',
                  render: (row) =>
                    row.originalPostProductionServices?.map((a) => (
                      <div key={a}>{a}</div>
                    )),
                },
              ]}
              defaultOrder="desc"
              defaultOrderBy="name"
            />
          </Box>
        )}
      </Box>
    </>
  );
};
