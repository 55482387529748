import React, { FC, createContext, ReactNode, useState } from 'react';
import { Box } from '@material-ui/core';

import { Sidebar } from 'src/components/Sidebar';
import { Topbar } from 'src/components/Topbar';
import { useHistory, useLocation } from 'react-router';

export type AppFrameOptions = {
  topbarLeftActions?: ReactNode;
  topbarRightActions?: ReactNode;
  title?: ReactNode;
  backRoute?: string;
  setOptions: (opts: Partial<Omit<AppFrameOptions, 'setOptions'>>) => void;
};

export const AppFrameContext = createContext<AppFrameOptions>({} as any);

export const AppFrame: FC = ({ children }) => {
  const history = useHistory();
  const location = useLocation();

  const [appFrameOptions, setAppFrameOptions] = useState<AppFrameOptions>({
    setOptions: (opts) => {
      setAppFrameOptions((prev) => ({
        setOptions: prev.setOptions,
        ...opts,
      }));
    },
  });

  return (
    <AppFrameContext.Provider value={appFrameOptions}>
      <Box display="flex" height="100vh">
        <Sidebar component="aside" />
        <Box display="flex" flex={1} flexDirection="column">
          <Topbar
            flexShrink={0}
            title={appFrameOptions.title}
            leftArea={appFrameOptions.topbarLeftActions}
            rightArea={appFrameOptions.topbarRightActions}
            goBack={() => {
              if (appFrameOptions.backRoute) {
                history.replace(appFrameOptions.backRoute);
              } else if (history.length === 0) {
                history.replace(
                  location.pathname.split('/').slice(0, -1).join('/'),
                );
              } else {
                history.goBack();
              }
            }}
          />
          <Box component="main" flexGrow={1} overflow="hidden auto">
            {children}
          </Box>
        </Box>
      </Box>
    </AppFrameContext.Provider>
  );
};
