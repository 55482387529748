import React from 'react';
import { useHistory } from 'react-router-dom';

import { BidsViewData } from '.';
import CustomTable from 'src/components/CustomTable';
import { BidStatusChip } from './StatusChip';
import { BidStatus } from '../common';

export const BidsListView = ({
  bids,
  filterStatus,
}: {
  filterStatus: BidStatus;
  bids: BidsViewData;
}) => {
  const history = useHistory();
  return (
    <CustomTable
      onClickRow={(row) =>
        history.push(`/bids/bid/${row.projectId}/${row.bidId}`)
      }
      rows={bids
        .filter((b) => b.status === filterStatus)
        .map((a) => ({
          ...a,
          language: a.language,
          project: a.project.title,
          service: a.localizationServices ? 'Localization' : 'Post Production',
          id: a.bidId,
        }))}
      columns={[
        {
          id: 'vendorId',
          label: 'Vendor',
          bodyCellStyle: {
            position: 'relative',
            minHeight: 78,
            width: 145,
          },
          render: (row) => (
            <img
              src={
                row.vendor.logo ??
                'https://www.cowgirlcontractcleaning.com/wp-content/uploads/sites/360/2018/05/placeholder-img.jpg'
              }
              alt={row.vendor.name}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '80%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          ),
          disablePaddingBody: true,
        },
        {
          id: 'project',
          label: 'Project',
          render: (row) => (
            <div style={{ textAlign: 'center' }}>{row.project}</div>
          ),
        },
        {
          id: 'submittedAt',
          label: 'Status',
          render: (row) => (
            <div style={{ textAlign: 'center' }}>
              <BidStatusChip status={row.status} style={{ opacity: 1 }} />
            </div>
          ),
        },
        {
          id: 'service',
          label: 'Service',
          render: (row) => (
            <div style={{ textAlign: 'center' }}>{row.service}</div>
          ),
        },
        {
          id: 'language',
          label: 'Language',
        },
      ]}
      defaultOrder="desc"
      defaultOrderBy="dueDate"
    />
  );
};
