import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from 'react-router-dom';

import { AppFrame } from 'src/components/AppFrame';
import { ProjectsPage } from 'src/pages/Projects';
import { ProjectFormPage } from 'src/pages/ProjectForm';
import { ProjectPage } from 'src/pages/Project';
import { LoginPage } from 'src/pages/Login';
import { useSelector } from 'src/store';
import { SignUpPage } from 'src/pages/Signup';
import { BidsComplete, BidsHire, BidsInvite } from 'src/pages/Bids';
import { BidFormPage } from 'src/pages/BidForm';
import { VendorsPage } from 'src/pages/Vendors';
import { VendorFormPage } from 'src/pages/BidForm/VendorForm';
import { SettingsPage } from 'src/pages/Settings';

export default function AppRouter() {
  return (
    <Router>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/signup">
          <SignUpPage />
        </Route>
        <PrivateRoute exact path={'/'}>
          <Redirect to={{ pathname: '/projects' }} />
        </PrivateRoute>
        <PrivateRoute path={'/projects'} exact>
          <ProjectsPage />
        </PrivateRoute>
        <PrivateRoute path={'/projects/edit/:projectId?'} exact>
          <ProjectFormPage />
        </PrivateRoute>
        <PrivateRoute path={'/projects/view/:projectId/:tab?'}>
          <ProjectPage />
        </PrivateRoute>
        <PrivateRoute path={'/bids/invite'} exact>
          <BidsInvite />
        </PrivateRoute>
        <PrivateRoute path={'/bids/hire'} exact>
          <BidsHire />
        </PrivateRoute>
        <PrivateRoute path={'/bids/complete'} exact>
          <BidsComplete />
        </PrivateRoute>
        <PrivateRoute path={'/bids/bid/:projectId/:bidId'} exact>
          <VendorFormPage />
        </PrivateRoute>
        <PrivateRoute path={'/bids/edit/:projectId?'} exact>
          <BidFormPage />
        </PrivateRoute>
        <PrivateRoute path={'/casting'}>Casting</PrivateRoute>
        <PrivateRoute path={'/qcentral'}>QCentral</PrivateRoute>
        <PrivateRoute path={'/vendors'}>
          <VendorsPage />
        </PrivateRoute>
        <PrivateRoute path={'/users-permissions'}>
          Users &amp; Permissions
        </PrivateRoute>
        <PrivateRoute path={'/audit'}>Audit</PrivateRoute>
        <PrivateRoute path={'/notifications'}>Notifications</PrivateRoute>
        <PrivateRoute path={'/settings'}>
          <SettingsPage />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}

const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const { loggedIn } = useSelector((state) => state.session);
  const authorized = true;
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!loggedIn) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          );
        } else if (!authorized) {
          return <>You don&apos;t have access to this resource.</>;
        } else {
          return <AppFrame>{children}</AppFrame>;
        }
      }}
    />
  );
};
