import { http } from '../http';

export const getAssets = async (projectId: string, episodeId?: string) => {
  try {
    const url = `/assets/project/${projectId}`;
    const { data } = await http.get(
      `${url}${episodeId ? `/episode/${episodeId}` : ''}`,
    );
    return data as E.Asset[];
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};

export const createAsset = async (asset: Partial<E.Asset>) => {
  try {
    const { data } = await http.post(`/assets/create`, asset);
    return data as E.Asset;
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};

export const updateAsset = async (
  asset: Partial<E.Asset> & {
    episodeId: string;
    projectId: string;
    assetId: string;
  },
) => {
  try {
    await http.patch(
      `/assets/project/${asset.projectId}/episode/${asset.episodeId}/asset/${asset.assetId}`,
      asset,
    );
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};
