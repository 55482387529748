import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Auth } from 'aws-amplify';

import { AppTheme } from './styles';
import AppRouter from 'src/routes';
import { useDispatch } from './store';
import { logIn, setUser } from './store/session/actions';
import { getUser } from './services/user';
import { AsperaContext } from './hooks/aspera';
import { setup, Aspera } from './services/aspera';

export const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [aspera, setAspera] = useState<Aspera>();

  useEffect(() => {
    Auth.currentSession()
      .then((session) => {
        dispatch(logIn());
        return session;
      })
      .then((session) => getUser(session.getAccessToken().payload.sub))
      .then((user) => {
        dispatch(setUser(user));
        return setup().then(setAspera);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch]);

  return (
    <AsperaContext.Provider value={(aspera ?? {}) as Aspera}>
      <AppTheme>
        {loading ? (
          <CircularProgress
            style={{
              color: 'rgba(255, 255, 255, 0.3)',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        ) : (
          <AppRouter />
        )}
      </AppTheme>
    </AsperaContext.Provider>
  );
};
