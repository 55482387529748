import { http } from '../http';

export const getUser = async (userId: string) => {
  try {
    const { data } = await http.get(`/users/user/${userId}`);
    return data as E.User;
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};

export const updateUser = async (
  user: Partial<E.User> & { userId: string },
) => {
  try {
    await http.patch(`/users/user/${user.userId}`, user);
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};
