import * as effects from 'redux-saga/effects';

export function takeLatest<T extends (...args: any) => any>(
  pattern: Parameters<T>[0]['type'],
  fn: T,
) {
  return effects.takeLatest(pattern, fn);
}

export function takeEvery<T extends (...args: any) => any>(
  pattern: Parameters<T>[0]['type'],
  fn: T,
) {
  return effects.takeEvery(pattern, fn);
}

export const { call, put, all } = effects;
