import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';

import { AppFrameContext, AppFrameOptions } from 'src/components/AppFrame';

export const usePathMatcher = () => {
  const location = useLocation();
  return (path: string) => location.pathname.indexOf(path) === 0;
};

/**
 *
 * @param opts please ```useMemo``` to avoid unecessary re-renderings
 */
export const useAppFrameOptions = (
  opts: Parameters<AppFrameOptions['setOptions']>[0],
) => {
  const { setOptions } = useContext(AppFrameContext);
  useEffect(() => {
    setOptions(opts);
    return () => setOptions({});
  }, [opts, setOptions]);
};
