import React, { useState } from 'react';
import {
  IconButton,
  Box,
  useTheme,
  CircularProgress,
  Typography,
  Button,
} from '@material-ui/core';
import { KeyboardArrowDown, Search } from '@material-ui/icons';
import CustomTable from 'src/components/CustomTable';
import { timeToLocalDateString, timezoneCity } from 'src/util/date';
import { TextField } from 'src/components/Fields';
import { BidsViewData } from '.';
import { useSelector } from 'src/store';
import { ListItem } from './types';
import { ServiceSection } from './ServiceSection';
import { useData } from './Data';
import { useHistory } from 'react-router';

export type BidsUnassignedViewProps = {
  projects: E.Project[];
  vendors: E.Vendor[];
  bids: BidsViewData;
};

export const BidsPendingView = ({
  projects,
  vendors,
  bids,
}: BidsUnassignedViewProps) => {
  const theme = useTheme();
  const history = useHistory();
  const user = useSelector((state) => state.session.user!);
  const [list, selected] = useData(projects, vendors, bids);
  const [working, setWorking] = useState(false);

  return (
    <div
      style={{
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#424242',
      }}
    >
      <Box
        padding={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <div
          style={{
            padding: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Search style={{ marginRight: theme.spacing(2) }} />
          <TextField placeholder="Search" variant="standard" color="primary" />
        </div>
        {selected.length > 0 && (
          <div>
            <Typography
              color="inherit"
              variant="subtitle1"
              component="span"
              style={{ marginRight: theme.spacing(4) }}
            >
              {selected.length} selected
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={async () => {
                setWorking(true);
                try {
                  // await batchInviteToBid(selected);
                  history.push('/bids/complete');
                } catch (e) {
                  alert(
                    'Something went wrong: ' + e?.message ?? 'UNKOWN ERROR',
                  );
                  setWorking(false);
                }
              }}
              disableElevation
              disabled={working}
            >
              {working && (
                <CircularProgress
                  size={16}
                  style={{ marginRight: theme.spacing(2) }}
                />
              )}
              Hire services
            </Button>
          </div>
        )}
      </Box>
      <CustomTable<ListItem>
        defaultAlign="left"
        columns={[
          {
            id: 'project',
            label: <span style={{ width: 150 }}>Project</span>,
            render: (row) => <>{row.project.title}</>,
            filterValue: (row) => row.project.title,
          },
          {
            id: 'language',
            label: 'Language',
            filterValue: (row) => row.language,
          },
          {
            id: 'dueDate',
            label: <span style={{ whiteSpace: 'nowrap' }}>Due Date</span>,
            render: ({ dueDate: [d, tz] }) => (
              <span
                title={timeToLocalDateString(d, tz) + ` (${timezoneCity(tz)})`}
              >
                {timeToLocalDateString(d, user.timezone)}
              </span>
            ),
            filterValue: ({ dueDate: [d] }) =>
              timeToLocalDateString(d, user.timezone),
          },
          {
            id: '_' as any,
            label: '',
            disablePaddingBody: true,
            disablePaddingHeader: true,
            render: (_, expand, isExpanded) => (
              <div style={{ textAlign: 'right' }}>
                <IconButton onClick={expand}>
                  <KeyboardArrowDown
                    style={{
                      transform: isExpanded ? 'rotate(-180deg)' : '',
                      transition: 'transform 0.3s',
                    }}
                  />
                </IconButton>
              </div>
            ),
          },
        ]}
        renderExpanded={({ dubbing, scripting, subtitling, qc }) => {
          return (
            <>
              {dubbing && (
                <ServiceSection
                  title="Dubbing"
                  vendors={dubbing.vendors}
                  category="dubbing"
                />
              )}
              {scripting && (
                <ServiceSection
                  title="Scripting"
                  vendors={scripting.vendors}
                  category="scripting"
                />
              )}
              {subtitling && (
                <ServiceSection
                  title="Subtitling"
                  vendors={subtitling.vendors}
                  category="subtitling"
                />
              )}
              {qc && (
                <ServiceSection title="QC" vendors={qc.vendors} category="qc" />
              )}
            </>
          );
        }}
        rows={list}
        defaultOrder="asc"
        defaultOrderBy="dueDate"
      />
    </div>
  );
};
