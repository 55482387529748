import React, { FC, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, BoxProps, useTheme } from '@material-ui/core';

import { Logo } from 'src/components/Icons';
import { usePathMatcher } from 'src/hooks/navigation';

const NavItem: FC<{ to: string }> = ({ to, children }) => {
  const theme = useTheme();
  const is = usePathMatcher();
  return (
    <li>
      <Link
        style={{
          opacity: is(to) ? 0.6 : 1,
          display: 'block',
          fontSize: 16,
          padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
        }}
        to={to}
      >
        {children}
      </Link>
    </li>
  );
};

export function Sidebar(props: BoxProps) {
  const theme = useTheme();
  const [expanded, setExpanded] = useState<string[]>([]);
  const location = useLocation();
  const toggleItem = (item: string) => {
    setExpanded((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item],
    );
  };
  const isExpanded = (item: string) =>
    expanded.includes(item) || location.pathname.indexOf(`/${item}`) === 0;
  return (
    <Box
      {...props}
      display="flex"
      flexDirection="column"
      bgcolor={theme.palette.primary.light}
      zIndex={2}
    >
      <Box display="flex" padding={6}>
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Box component="span" fontSize={34} fontWeight="bold" color="#fff">
            ProForma
          </Box>
          <Logo size={58} />
        </Box>
      </Box>
      <Box component="nav">
        <ul>
          <NavItem to="/projects">Projects</NavItem>
          <li>
            <button
              style={{
                fontSize: 16,
                padding: `${theme.spacing(2)}px ${theme.spacing(6)}px`,
                outline: 'none',
                width: '100%',
                cursor: 'pointer',
                textAlign: 'left',
              }}
              onClick={() => toggleItem('bids')}
            >
              Bids
            </button>
            <ul
              style={{
                marginLeft: theme.spacing(4),
                display: isExpanded('bids') ? 'block' : 'none',
              }}
            >
              <NavItem to="/bids/invite">Invite</NavItem>
              <NavItem to="/bids/hire">Hire</NavItem>
              <NavItem to="/bids/complete">Complete</NavItem>
            </ul>
          </li>
          <NavItem to="/casting">Casting</NavItem>
          <NavItem to="/qcentral">QCentral</NavItem>
          <NavItem to="/vendors">Vendors</NavItem>

          <Box component="li" color="text.disabled" paddingY={2} paddingX={4}>
            Manage
          </Box>
          <NavItem to="/users-permissions">Users &amp; Permissions</NavItem>
          <NavItem to="/audit">Audit</NavItem>
          <NavItem to="/notifications">Notifications</NavItem>
        </ul>
      </Box>
    </Box>
  );
}
