import React, { useState, FC } from 'react';
import { Popover, PopoverProps, Menu, MenuProps } from '@material-ui/core';

export const AutoPopover: FC<Omit<PopoverProps, 'anchorEl'>> = ({
  children,
  open,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  return (
    <div ref={(div) => setAnchorEl(div?.parentElement || null)}>
      <Popover
        {...props}
        anchorEl={anchorEl}
        open={open && Boolean(anchorEl)}
        onClose={(event, reason) => {
          setAnchorEl(null);
          props.onClose?.(event, reason);
        }}
      >
        {children}
      </Popover>
    </div>
  );
};

export const AutoMenu: FC<Omit<MenuProps, 'anchorEl'>> = ({
  children,
  open,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  return (
    <div ref={(div) => setAnchorEl(div?.parentElement || null)}>
      <Menu
        {...props}
        anchorEl={anchorEl}
        open={open && Boolean(anchorEl)}
        onClose={(event, reason) => {
          setAnchorEl(null);
          props.onClose?.(event, reason);
        }}
      >
        {children}
      </Menu>
    </div>
  );
};
