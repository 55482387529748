import { http } from '../http';

export const getVendors = async () => {
  try {
    const { data } = await http.get('/vendors/all');
    return data as E.Vendor[];
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};

export const getVendor = async (vendorId: string) => {
  try {
    const { data } = await http.get(`/vendors/vendor/${vendorId}`);
    return data as E.Vendor;
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};

export const createVendor = async (vendor: Partial<E.Vendor>) => {
  try {
    const { data } = await http.post(`/vendors/create`, vendor);
    return data as E.Vendor;
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};

export const updateVendor = async (
  vendor: Partial<E.Vendor> & { vendorId: string },
) => {
  try {
    await http.patch(`/vendors/vendor/${vendor.vendorId}`, vendor);
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};
