import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { getAssets } from 'src/services/assets';
import {
  deserializeDateWithTimezone,
  timeToLocalDateString,
} from 'src/util/date';

export const ProgressTab = ({ project }: { project: E.Project }) => {
  const [assets, setAssets] = useState<E.Asset[]>([]);

  useEffect(() => {
    getAssets(project.projectId).then(setAssets);
  }, [project.projectId]);

  return (
    <Box p={4}>
      <div>Progress</div>
      {project.episodes.map((ep) => (
        <div
          key={ep.episodeId}
          style={{
            padding: 12,
            margin: '24px 0',
            background: 'rgba(255,255,255,0.1)',
          }}
        >
          {ep.title}
          {project.requestedDubbedLanguages.map((lang) => (
            <div
              style={{
                padding: 12,
                margin: '16px 0',
                background: 'rgba(255,255,255,0.1)',
              }}
            >
              {lang}
              {assets
                .filter(
                  (a) => a.episodeId === ep.episodeId && a.language === lang,
                )
                .sort(
                  (a, b) =>
                    deserializeDateWithTimezone(a.startDate)[0].getTime() -
                    deserializeDateWithTimezone(b.startDate)[0].getTime(),
                )
                .map((ass, _, arr) => {
                  const start = deserializeDateWithTimezone(ass.startDate);
                  const due = deserializeDateWithTimezone(ass.dueDate);
                  const min = Math.min(
                    ...arr.map((a) =>
                      deserializeDateWithTimezone(a.startDate)[0].getTime(),
                    ),
                  );
                  const max = Math.max(
                    ...arr.map((a) =>
                      deserializeDateWithTimezone(a.dueDate)[0].getTime(),
                    ),
                  );
                  const totalGap = max - min;
                  const gap = due[0].getTime() - start[0].getTime();
                  return (
                    <div
                      key={ass.assetId}
                      style={{
                        background: '#069',
                        color: '#fff',
                        marginTop: 4,
                        padding: 4,
                        width: 100 * (gap / totalGap) + '%',
                        marginLeft:
                          (100 * (start[0].getTime() - min)) / totalGap + '%',
                        textAlign: 'left',
                        whiteSpace: 'nowrap',
                        fontSize: 12,
                      }}
                    >
                      {ass.asset}
                      <br />
                      {timeToLocalDateString(start[0], start[1])}
                      <br />
                      {timeToLocalDateString(due[0], due[1])}
                    </div>
                  );
                })}
            </div>
          ))}
        </div>
      ))}
    </Box>
  );
};
