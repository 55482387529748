export const logIn = () => ({
  type: 'LOGIN' as const,
});

export const logOut = () => ({
  type: 'LOGOUT' as const,
});

export const setUser = (user: E.User) => ({
  type: 'SET_USER' as const,
  payload: user,
});
