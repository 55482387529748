import React from 'react';
import { useTheme } from '@material-ui/core';

import { statusColorMap } from './StatusChip';

export const StatusSummary = ({ assets }: { assets: E.Asset[] }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {[
        [assets.length, 'TOTAL'],
        [assets.filter((a) => a.status === 'ACCEPTED').length, 'ACCEPTED'],
        [assets.filter((a) => a.status === 'OPEN').length, 'OPEN'],
        [assets.filter((a) => a.status === 'REDELIVERY').length, 'REDELIVERY'],
        [assets.filter((a) => a.status === 'UNASSIGNED').length, 'UNASSIGNED'],
      ].map(([n, status]) => (
        <div
          key={status}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: (statusColorMap as any)[status],
            marginLeft: theme.spacing(4),
            filter: `brightness(1.5)`,
            opacity: 0.8,
          }}
        >
          <strong style={{ fontSize: 20 }}>{n}</strong>
          <span style={{ fontSize: 12 }}>{status}</span>
        </div>
      ))}
    </div>
  );
};
