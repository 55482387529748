import { http } from '../http';

export const getBids = async () => {
  try {
    const { data } = await http.get('/bids/all');
    return data as E.Bid[];
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};

export const getBid = async (projectId: string, bidId: string) => {
  try {
    const { data } = await http.get(`/bids/bid/${projectId}/${bidId}`);
    return data as E.Bid;
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};

export const batchInviteToBid = async (bids: Omit<E.Bid, 'bidId'>[]) => {
  try {
    await http.post(`/bids/create`, bids);
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};

export const updateBid = async (
  bid: Partial<E.Bid> & { projectId: string; bidId: string },
) => {
  try {
    await http.patch(`/bids/bid/${bid.projectId}/${bid.bidId}`, bid);
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};

export const calculateBidPrice = (
  bid: E.Bid,
  serviceGroup: 'localizationServices' | 'originalPostProductionServices',
  service?: E.LocalizationService | E.OriginalPostProductionService,
) =>
  (Object.entries(bid[serviceGroup] ?? {})
    .filter(([k, v]) => Boolean(v) && (!service || k === service))
    .flatMap(([, v]) => Object.values(v)) as number[]).reduce(
    (s, x) => s + x,
    0,
  );
