import React, { useMemo, useState, useEffect } from 'react';
import { Box, Button, useTheme } from '@material-ui/core';
import { useParams, useHistory } from 'react-router';

import { useAppFrameOptions } from 'src/hooks/navigation';
import { Tabs } from 'src/components/Tabs';
import { sweetch } from 'src/util/misc';
import { AssetsTab } from './Assets';
import { getProject } from 'src/services/projects';
import { Loader } from 'src/components/Loader';
import { ProjectHeader } from 'src/components/ProjectHeader';
import { ProgressTab } from './Progress';

export const ProjectPage = () => {
  const { projectId, tab = 'requests' } = useParams<{
    projectId: string;
    tab?: 'assets' | 'requests' | 'progress';
  }>();
  const history = useHistory();
  const theme = useTheme();

  const [project, setProject] = useState<E.Project | -1>();

  useEffect(() => {
    getProject(projectId)
      .then(setProject)
      .catch(() => {
        setProject(-1);
      });
  }, [projectId]);

  useAppFrameOptions(
    useMemo(
      () => ({
        backRoute: '/projects',
        title: `Projects > ${
          project === -1 ? 'not found' : project?.title ?? 'loading...'
        }`,
        topbarLeftActions: (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => history.push(`/projects/edit/${projectId}`)}
            disableElevation
          >
            Edit Project
          </Button>
        ),
      }),
      [history, project, projectId],
    ),
  );

  return (
    <Box padding={6}>
      {project === -1 || !project ? (
        <Loader loading={!project} error={project === -1} />
      ) : (
        <>
          <ProjectHeader
            project={project}
            buttons={
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => history.push('/qcentral')}
                  disableElevation
                >
                  QCentral
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => history.push('/casting')}
                  disableElevation
                >
                  Casting Tool
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => history.push('/bids')}
                  disableElevation
                >
                  Active Bids
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => history.push('/qcentral')}
                  disableElevation
                >
                  Show Guide
                </Button>
              </>
            }
          />
          <Box
            marginTop={6}
            borderRadius={theme.shape.borderRadius}
            overflow="hidden"
            bgcolor="primary.main"
          >
            <Tabs
              active={sweetch<typeof tab, number>(
                tab,
                0,
                ['requests', 1],
                ['progress', 2],
              )}
              items={[
                {
                  label: 'Assets',
                  content: <AssetsTab project={project} />,
                  onActive: () => {
                    history.push(`/projects/view/${projectId}/assets`);
                  },
                },
                {
                  label: 'Requests',
                  content: <AssetsTab project={project} requestsView />,
                  onActive: () => {
                    history.push(`/projects/view/${projectId}/requests`);
                  },
                },
                {
                  label: 'Progress',
                  content: <ProgressTab project={project} />,
                  onActive: () => {
                    history.push(`/projects/view/${projectId}/progress`);
                  },
                },
              ]}
            />
          </Box>
        </>
      )}
    </Box>
  );
};
