import React, { useMemo, useState, useCallback, useEffect } from 'react';
import {
  Button,
  Box,
  useTheme,
  Typography,
  MenuItem,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { useHistory, useParams } from 'react-router';

import { useAppFrameOptions } from 'src/hooks/navigation';
import { Fieldset } from 'src/components/Fieldset';
import { Dropzone } from 'src/components/Dropzone';
import { TextField } from 'src/components/Fields';
import { Watcher } from 'src/components/Watcher';
import {
  daysRelative,
  deserializeDateWithTimezone,
  serializeDateWithTimezone,
  timezoneCity,
  prepareForDateField,
} from 'src/util/date';
import { Loader } from 'src/components/Loader';
import { getProjects, updateProject } from 'src/services/projects';
import {
  postProductionCategoryMap,
  localizationCategoryMap,
} from 'src/util/common';
import { Delete } from '@material-ui/icons';
import { useSelector } from 'src/store';

type FormData = Partial<E.Project>;

export const BidFormPage = () => {
  const theme = useTheme();
  const history = useHistory();
  const user = useSelector((state) => state.session.user!);
  const { projectId } = useParams<{
    projectId?: string;
  }>();

  useAppFrameOptions(
    useMemo(
      () => ({
        title: projectId ? 'Edit Bid' : 'New Bid',
      }),
      [projectId],
    ),
  );

  const [loading, setLoading] = useState<boolean | -1>(Boolean(projectId));
  const [working, setWorking] = useState(false);
  const [termsFile, setTermsFile] = useState<File>();
  const [materialsFile, setMaterialsFile] = useState<File[]>([]);
  const [projects, setProjects] = useState<E.Project[]>([]);

  const [formData, setFormData] = useState<FormData>({
    bidDueDate: serializeDateWithTimezone(
      daysRelative(Date.now(), 28),
      user.timezone,
    ),
    bidTermsAndConditions: '',
    projectId: '',
  });

  const project = useMemo(
    () => projects.find((p) => p.projectId === formData.projectId),
    [formData.projectId, projects],
  );

  const update = useCallback(function <T extends keyof FormData>(
    k: T,
    v: FormData[T],
  ) {
    setFormData((prev) => ({
      ...prev,
      [k]: v,
    }));
  },
  []);

  useEffect(() => {
    getProjects()
      .then((projects) => {
        setProjects(projects);
        setLoading(false);
        const project =
          projects.find((a) => a.projectId === projectId) ??
          projects.find((a) => !a.bidDueDate) ??
          projects[0];
        project &&
          setFormData((prev) => ({
            projectId: project.projectId,
            bidTermsAndConditions: project.bidTermsAndConditions,
            bidDueDate: project.bidDueDate ?? prev.bidDueDate,
          }));
      })
      .catch(() => {
        setLoading(-1);
      });
  }, [projectId, update]);

  return Boolean(loading) ? (
    <Loader error={loading === -1} loading={loading === true} />
  ) : (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Box
        padding={6}
        margin={8}
        bgcolor={theme.palette.primary.main}
        borderRadius={theme.shape.borderRadius}
      >
        <Box
          component="form"
          display="grid"
          gridTemplateColumns="repeat(2, 1fr)"
          gridGap={theme.spacing(6)}
          onSubmit={async (ev) => {
            ev.preventDefault();
            setWorking(true);
            try {
              const data = { ...formData };
              if (!data.projectId) return;
              await updateProject({ ...data, projectId: data.projectId! });
              history.push(`/bids/invite`);
            } catch (err) {
              alert('Something went wrong: ' + err?.message ?? 'UNKOWN ERROR');
            } finally {
              setWorking(false);
            }
          }}
        >
          <Typography
            variant="subtitle1"
            style={{
              gridColumn: '1 / -1',
              marginBottom: theme.spacing(2),
            }}
          >
            Bid Details
          </Typography>
          <Watcher
            values={[formData.projectId, projects]}
            render={() => (
              <TextField
                select
                value={formData.projectId}
                onChange={(ev) => update('projectId', ev.target.value as any)}
                label="Project"
                disabled={Boolean(projectId)}
              >
                {projects.map((project) => (
                  <MenuItem key={project.projectId} value={project.projectId}>
                    {project.title}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Watcher
            values={[formData.bidDueDate]}
            render={() => {
              const [d, tz] = deserializeDateWithTimezone(formData.bidDueDate!);
              return (
                <KeyboardDatePicker
                  disableToolbar
                  inputVariant="outlined"
                  variant="inline"
                  format={`MM/dd/yyyy '(${timezoneCity(tz)})'`}
                  margin="none"
                  label="Bidding Due Date"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  value={prepareForDateField(d, tz)}
                  onChange={(date) => {
                    date &&
                      update(
                        'bidDueDate',
                        serializeDateWithTimezone(
                          new Date(date.setHours(23, 59, 59, 0)),
                          user.timezone,
                        ),
                      );
                  }}
                />
              );
            }}
          />
          <Watcher
            values={[termsFile, formData.bidTermsAndConditions]}
            render={() => (
              <Fieldset label="Terms and Conditions">
                <Dropzone
                  files={termsFile ? [termsFile] : []}
                  onChange={([file]) => setTermsFile(file)}
                  multiple={false}
                  renderDragActive={() => <>Drop a PDF file here</>}
                  renderAfterDrop={([f]) => (
                    <Box display="flex" alignItems="center">
                      <IconButton
                        style={{ marginRight: theme.spacing(4) }}
                        onClick={(ev) => {
                          ev.stopPropagation();
                          setTermsFile(undefined);
                        }}
                      >
                        <Delete />
                      </IconButton>
                      {f.name}
                    </Box>
                  )}
                  label="Drop a PDF file here"
                  containerStyle={{
                    minHeight: 148,
                    height: '100%',
                    position: 'relative',
                  }}
                />
              </Fieldset>
            )}
          />
          <Watcher
            values={[materialsFile]}
            render={() => (
              <Fieldset label="Reference Materials">
                <Dropzone
                  files={materialsFile}
                  onChange={(files) => setMaterialsFile(files)}
                  renderDragActive={() => <>Drop your files here</>}
                  renderAfterDrop={() =>
                    materialsFile.map((f, k) => (
                      <Box
                        key={f.name + k}
                        display="flex"
                        alignItems="center"
                        width="calc(100% + 32px)"
                        style={{
                          backgroundColor:
                            k % 2 === 0 ? 'rgba(0, 0, 0, 0.1)' : '',
                        }}
                      >
                        <IconButton
                          style={{ marginRight: theme.spacing(4) }}
                          onClick={(ev) => {
                            ev.stopPropagation();
                            setMaterialsFile((prev) =>
                              prev.filter((file) => file !== f),
                            );
                          }}
                        >
                          <Delete />
                        </IconButton>
                        {f.name}
                      </Box>
                    ))
                  }
                  label={
                    <>
                      Drop files here <br />
                      (video, .pdf, .docx, .fdx)
                    </>
                  }
                  containerStyle={{
                    minHeight: 148,
                    height: '100%',
                    position: 'relative',
                  }}
                />
              </Fieldset>
            )}
          />
          <Fieldset
            gridColumn="1 / -1"
            variant="box"
            label="Services and Languages"
          >
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              gridGap={theme.spacing(6)}
            >
              <Fieldset label="Localization" gridColumn="1 / span 1">
                <Box display="grid" gridTemplateColumns="1fr 1fr 1fr">
                  {project?.localizationServices &&
                    Object.entries(
                      localizationCategoryMap,
                    ).map(([key, service]) => (
                      <FormControlLabel
                        key={key}
                        control={<Checkbox color="default" />}
                        label={service}
                        checked={
                          project.localizationServices[
                            key as E.LocalizationService
                          ]
                        }
                        disabled
                      />
                    ))}
                </Box>
              </Fieldset>
              <Fieldset
                label="Original Post Production"
                gridColumn="1 / span 1"
              >
                <Box display="grid" gridTemplateColumns="1fr 1fr 1fr">
                  {project?.originalPostProductionServices &&
                    Object.entries(
                      postProductionCategoryMap,
                    ).map(([key, service]) => (
                      <FormControlLabel
                        key={key}
                        control={<Checkbox color="default" />}
                        label={service}
                        checked={
                          project.originalPostProductionServices[
                            key as E.OriginalPostProductionService
                          ]
                        }
                        disabled
                      />
                    ))}
                </Box>
              </Fieldset>
              <Fieldset
                label="Dubbing Languages"
                gridColumn="2 / span 1"
                gridRow="1 / auto"
              >
                <Box display="grid" gridTemplateColumns="1fr 1fr 1fr">
                  {project?.requestedDubbedLanguages.sort().map((lang) => (
                    <FormControlLabel
                      key={lang}
                      control={<Checkbox color="default" />}
                      label={lang}
                      checked
                      disabled
                    />
                  ))}
                </Box>
              </Fieldset>

              <Fieldset
                label="Subtitling Languages"
                gridColumn="2 / span 1"
                gridRow="2 / auto"
              >
                <Box display="grid" gridTemplateColumns="1fr 1fr 1fr">
                  {project?.requestedSubtitledLanguages.sort().map((lang) => (
                    <FormControlLabel
                      key={lang}
                      control={<Checkbox color="default" />}
                      label={lang}
                      checked
                      disabled
                    />
                  ))}
                </Box>
              </Fieldset>
            </Box>
          </Fieldset>
          <Box gridColumn="1 / -1" display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disableElevation
              disabled={working}
            >
              {working && (
                <CircularProgress
                  size={16}
                  style={{ marginRight: theme.spacing(2) }}
                />
              )}
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </MuiPickersUtilsProvider>
  );
};
