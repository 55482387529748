import { darken } from '@material-ui/core';
import { makeStyles, c } from 'src/styles';

export const useStyles = makeStyles((theme) => ({
  header: c({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
  }),
  filters: c({
    display: 'flex',
    alignItems: 'center',
    '& .MuiButton-containedPrimary': c({
      backgroundColor: theme.palette.primary.light,
      marginLeft: theme.spacing(2),
    }),
    '& .MuiButton-contained.Mui-disabled': c({
      opacity: 0.7,
    }),
    '& .MuiTypography-body2': c({
      color: theme.palette.text.disabled,
    }),
  }),
  groupContainer: {
    boxShadow: 'none',
    backgroundColor: darken(theme.palette.primary.main, 0.15),
    '&:nth-child(2n)': {
      backgroundColor: 'transparent',
    },
    '&.MuiAccordion-rounded:first-child': c({
      borderRadius: 0,
    }),
    '&.MuiAccordion-root:before': c({
      display: 'none',
    }),
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
    '&.MuiAccordion-root.Mui-expanded': {
      marginTop: 0,
    },
  },
  groupTitle: c({
    padding: theme.spacing(2),
  }),
  groupDetailsContainer: {
    padding: 0,
    '& .MuiAccordionDetails-root': {},
  },
  groupDetails: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
  },
}));
