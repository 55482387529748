import React, {
  ReactNode,
  useState,
  MouseEvent,
  ChangeEvent,
  CSSProperties,
  useMemo,
  useEffect,
} from 'react';
import clsx from 'clsx';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
} from '@material-ui/core';
import { FilterList, Search } from '@material-ui/icons';
import { TextField } from '../Fields';
import { stableSort, getComparator } from './sort';

export interface TableColumn<Data> {
  disablePaddingHeader?: boolean;
  disablePaddingBody?: boolean;
  id: keyof Data;
  label: ReactNode;
  align?: TableCellProps['align'];
  render?: (row: Data, expand: () => unknown, expanded: boolean) => ReactNode;
  bodyCellStyle?: CSSProperties;
  filterValue?: (row: Data) => string;
}

interface EnhancedTableHeadProps<Data> {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  order: CustomTable.Order;
  orderBy: string;
  rowCount: number;
  isSelectingEnabled?: boolean;
  columns: TableColumn<Data>[];
  defaultAlign?: TableCellProps['align'];
}

function EnhancedTableHead<Data>({
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  columns,
  isSelectingEnabled,
  defaultAlign = 'center',
}: EnhancedTableHeadProps<Data>) {
  const createSortHandler = (property: keyof Data) => (
    event: MouseEvent<unknown>,
  ) => {
    onRequestSort(event, property);
  };
  const theme = useTheme();

  return (
    <TableHead>
      <TableRow>
        {isSelectingEnabled && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all' }}
              color="default"
            />
          </TableCell>
        )}
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id as string}
            align={headCell.align ?? defaultAlign}
            padding={headCell.disablePaddingHeader ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {(headCell.align ?? defaultAlign) === 'center' &&
                headCell.filterValue && (
                  <span style={{ display: 'inline-block', width: 20 }} />
                )}
              {headCell.filterValue && (
                <FilterList
                  width={20}
                  style={{ marginRight: theme.spacing(1.5), width: 20 }}
                  onClick={(ev) => ev.stopPropagation()}
                />
              )}
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
    },
    highlight: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.light,
    },
  }),
);

interface EnhancedTableToolbarProps {
  numSelected: number;
  selectionOptions: ReactNode;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;
  const theme = useTheme();

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div
        style={{
          padding: theme.spacing(2),
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Search style={{ marginRight: theme.spacing(2) }} />
        <TextField placeholder="Search" variant="standard" color="primary" />
      </div>
      {numSelected > 0 ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: theme.spacing(2),
          }}
        >
          <Typography
            color="inherit"
            variant="subtitle1"
            component="div"
            style={{ marginRight: theme.spacing(4) }}
          >
            {numSelected} selected
          </Typography>
          {props.selectionOptions}
        </div>
      ) : null}
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export type CustomTableProps<Data> = {
  defaultOrderBy: keyof Data;
  defaultOrder: CustomTable.Order;
  rows: Data[];
  columns: (TableColumn<Data> | false | undefined)[];
  selectionOptions?: ReactNode;
  onSelectionChanged?: (selected: Data[]) => unknown;
  onClickRow?: (row: Data) => unknown;
  disablePagination?: boolean;
  rowsPerPageOptions?: number[];
  defaultRowsPerPage?: number;
  renderExpanded?: (row: Data) => ReactNode;
  onSearchChange?: (query: string) => unknown;
  defaultAlign?: EnhancedTableHeadProps<Data>['defaultAlign'];
};

export default function CustomTable<
  Data extends Record<string, unknown> & { id: string }
>({
  defaultOrder,
  defaultOrderBy,
  rows,
  columns,
  selectionOptions,
  onSelectionChanged,
  onClickRow,
  disablePagination,
  rowsPerPageOptions = [10, 25, 50],
  defaultRowsPerPage = 10,
  renderExpanded,
  onSearchChange,
  defaultAlign,
}: CustomTableProps<Data>) {
  const classes = useStyles();
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [selected, setSelected] = useState<(string | number)[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const _columns = useMemo(() => columns.filter(Boolean), [
    columns,
  ]) as TableColumn<Data>[];
  const [expanded, setExpanded] = useState<string[]>([]);

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleSelectClick = (
    event: MouseEvent<unknown>,
    id: string | number,
  ) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: typeof selected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: string | number) => selected.indexOf(id) !== -1;

  useEffect(() => {
    onSelectionChanged?.(rows.filter(({ id }) => selected.includes(id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {(Boolean(selectionOptions) || Boolean(onSearchChange)) && (
          <EnhancedTableToolbar
            numSelected={selected.length}
            selectionOptions={selectionOptions}
          />
        )}
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy as string}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              columns={_columns}
              isSelectingEnabled={Boolean(selectionOptions)}
              defaultAlign={defaultAlign}
            />
            <TableBody>
              {stableSort(rows as any, getComparator(order, orderBy))
                .slice(
                  disablePagination ? 0 : page * rowsPerPage,
                  disablePagination
                    ? undefined
                    : page * rowsPerPage + rowsPerPage,
                )
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return [
                    <TableRow
                      hover={Boolean(onClickRow) || Boolean(selectionOptions)}
                      onClick={() => onClickRow?.(row as any)}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      style={{
                        cursor: Boolean(onClickRow) ? 'pointer' : '',
                        backgroundColor: isItemSelected
                          ? 'rgba(0, 0, 0, 0.16)'
                          : '',
                      }}
                    >
                      {selectionOptions && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            onClick={(ev) => handleSelectClick(ev, row.id)}
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                            color="default"
                          />
                        </TableCell>
                      )}
                      {_columns.map(
                        (
                          { id, render, disablePaddingBody, bodyCellStyle },
                          i,
                        ) => (
                          <TableCell
                            key={id as string}
                            id={i === 0 ? labelId : undefined}
                            scope="row"
                            padding={disablePaddingBody ? 'none' : 'default'}
                            style={bodyCellStyle}
                          >
                            {render
                              ? render(
                                  row as any,
                                  () =>
                                    setExpanded((prev) => {
                                      if (prev.includes(row.id as string)) {
                                        return prev.filter((a) => a !== row.id);
                                      } else {
                                        return [...prev, row.id as string];
                                      }
                                    }),
                                  expanded.includes(row.id as string),
                                )
                              : row[id]}
                          </TableCell>
                        ),
                      )}
                    </TableRow>,
                    expanded.includes(row.id as string) && (
                      <tr key={`${row.id}_exp`}>
                        <td
                          colSpan={
                            _columns.length + Number(Boolean(selectionOptions))
                          }
                        >
                          {renderExpanded?.(row as any)}
                        </td>
                      </tr>
                    ),
                  ];
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {!disablePagination && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </div>
  );
}
