import { http } from '../http';

export const requestAsperaDownload = async (
  assetId: string,
  projectId: string,
  episodeId: string,
) => {
  try {
    const { data } = await http.get('/downloads/aoc-download', {
      params: {
        assetId,
        projectId,
        episodeId,
      },
    });
    return data;
  } catch (err) {
    console.error(err);
    throw err.data;
  }
};
