import React, { useState } from 'react';
import {
  Typography,
  Box,
  useTheme,
  CircularProgress,
  Button,
} from '@material-ui/core';
import { useHistory } from 'react-router';

import {
  SelectField,
  TextField,
  SelectFieldProps,
} from 'src/components/Fields';
import { timezones } from 'src/util/date';
import { useSelector, useDispatch } from 'src/store';
import { updateUser } from 'src/services/user';
import { setUser } from 'src/store/session/actions';

const timezoneOptions: SelectFieldProps['options'] = timezones.map((a) => ({
  label: a.replace('_', ' '),
  value: a,
}));

export const SettingsPage = () => {
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.session);
  const [working, setWorking] = useState(false);
  const [firstName, setFirstName] = useState(user?.firstName ?? '');
  const [lastName, setLastName] = useState(user?.lastName ?? '');
  const [email, setEmail] = useState(user?.email ?? '');
  const [timezone, setTimezone] = useState(
    user?.timezone ?? 'America/Los_Angeles',
  );

  return (
    <>
      <Box margin={8} padding={6} bgcolor={theme.palette.primary.main}>
        <Typography
          style={{
            marginBottom: theme.spacing(4),
          }}
          variant="subtitle1"
        >
          Settings
        </Typography>
        <form
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridGap: theme.spacing(4),
          }}
          onSubmit={async (ev) => {
            ev.preventDefault();
            if (!user) {
              return;
            }
            setWorking(true);
            try {
              await updateUser({
                userId: user.userId,
                firstName,
                lastName,
                email,
                timezone,
              });
              dispatch(
                setUser({ ...user, firstName, lastName, email, timezone }),
              );
              history.goBack();
            } catch (e) {
              alert('Something went wrong: ' + e?.message ?? 'UNKOWN ERROR');
              setWorking(false);
            }
          }}
        >
          <TextField
            value={firstName}
            onChange={(ev) => setFirstName(ev.currentTarget.value)}
            label="First Name"
          />
          <TextField
            value={lastName}
            onChange={(ev) => setLastName(ev.currentTarget.value)}
            label="Last Name"
          />
          <TextField
            value={email}
            onChange={(ev) => setEmail(ev.currentTarget.value)}
            label="Email"
          />
          <SelectField
            value={timezone}
            onChange={(value) => setTimezone(value as string)}
            label="Timezone"
            options={timezoneOptions}
          />
          <Box gridColumn="1 / -1" display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disableElevation
              disabled={working}
            >
              {working && (
                <CircularProgress
                  size={16}
                  style={{ marginRight: theme.spacing(2) }}
                />
              )}
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};
