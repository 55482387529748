import { useCallback } from 'react';
import * as ReactRedux from 'react-redux';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import createSagaMidleware from 'redux-saga';

import { sessionReducer } from './session/reducer';

import { all } from './saga';
import { sessionSaga } from './session/saga';

const reducers = {
  session: sessionReducer,
};

export type AppState = {
  [P in keyof typeof reducers]: ReturnType<typeof reducers[P]>;
};

const sagaMidleware = createSagaMidleware();

export const store = createStore(
  combineReducers(reducers),
  applyMiddleware(sagaMidleware),
);

sagaMidleware.run(function* () {
  yield all([sessionSaga()]);
});

export const getState = () => store.getState() as AppState;

export const useDispatch = () => {
  const store = ReactRedux.useStore();
  return useCallback(store.dispatch, [store]);
};

export const useStore = () => ReactRedux.useStore<AppState>();

export function useSelector<T>(selector: (state: AppState) => T) {
  return ReactRedux.useSelector(selector);
}

Object.assign(window, {
  _store: store,
});
