import React, { FC, CSSProperties } from 'react';
import {
  createMuiTheme,
  ThemeProvider,
  lighten,
  Theme,
  makeStyles as muiMakeStyles,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

const baseTheme = createMuiTheme({
  spacing: 4,
  palette: {
    type: 'dark',
    primary: {
      main: '#282828',
      contrastText: '#CCCCCC',
      light: '#383838',
      dark: '#111',
    },
    secondary: {
      main: '#9C0808',
    },
    text: {
      primary: '#ccc',
    },
  },
  shape: {
    borderRadius: 3,
  },
});

const theme = createMuiTheme(
  {
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '*': {
            margin: 0,
            padding: 0,
          },
          'body, section, div, main, ul, nav': {
            scrollbarColor: 'rgba(255, 255, 255, 0.3) none',
            scrollbarWidth: '10px' as any,
            '&::-webkit-scrollbar': {
              width: '10px',
              height: '10px',
            },
            '&::-webkit-scrollbar-button': {
              display: 'none',
            },
            '&::-webkit-scrollbar-track': {
              background: 'none',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '5px',
              background: 'rgba(255, 255, 255, 0.3)',
            },
          },
          body: {
            '-webkit-font-smoothing': 'antialiased',
            '-moz-osx-font-smoothing': 'grayscale',
            backgroundColor: '#111',
            lineHeight: 1,
          },
          a: {
            font: 'inherit',
            'text-decoration': 'none',
            color: 'inherit',
            transition: 'filter 0.3s',

            '&:focus,&:hover': {
              filter: 'brightness(1.2)',
            },
          },
          ul: {
            listStyle: 'none',
          },
          button: {
            border: 'none',
            font: 'inherit',
            background: 'none',
            padding: '0',
            margin: '0',
            color: 'inherit',
          },
        },
      },
      MuiFormControl: {
        root: {
          '& label': {
            whiteSpace: 'nowrap',
          },
          '& label.Mui-focused': {
            color: lighten(baseTheme.palette.primary.light, 0.5),
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: lighten(baseTheme.palette.primary.light, 0.3),
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: lighten(baseTheme.palette.primary.light, 0.3),
            },
          },
        },
      },
      MuiTextField: {
        root: {
          '& label': {
            whiteSpace: 'nowrap',
          },
          '& label.Mui-focused': {
            color: lighten(baseTheme.palette.primary.light, 0.5),
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: lighten(baseTheme.palette.primary.light, 0.3),
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: lighten(baseTheme.palette.primary.light, 0.3),
            },
          },
        },
      },
    },
  },
  baseTheme,
);

export const AppTheme: FC = ({ children }) => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </ThemeProvider>
);

type X<T> =
  | {
      [K: string]: T;
    }
  | {
      [K in keyof CSSProperties]: CSSProperties[K];
    };

type C = CSSProperties;

type Css = X<C | X<C | X<C | X<C | X<C | X<C | X<C | X<C>>>>>>>>;

export function makeStyles<T extends Css>(css: (theme: Theme) => T) {
  return muiMakeStyles(((theme: Theme) => css(theme)) as any) as () => {
    [key in keyof T]: string;
  };
}

export const c = (css: Css): Css => css;
