import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Box } from '@material-ui/core';

import { useAppFrameOptions } from 'src/hooks/navigation';
import { getBids } from 'src/services/bids';
import { Loader } from 'src/components/Loader';
import { getVendors } from 'src/services/vendors';
import { getProjects } from 'src/services/projects';
import { BidsPendingView } from './Hire';

export type BidsViewData = (E.Bid & {
  vendor: E.Vendor;
  project: E.Project;
  bidTotalUsd: number;
  dueDate: string;
})[];

export const BidsHire = () => {
  const history = useHistory();
  const [bids, setBids] = useState<BidsViewData | -1>();
  const [projects, setProjects] = useState<E.Project[]>([]);
  const [vendors, setVendors] = useState<E.Vendor[]>([]);

  useAppFrameOptions(
    useMemo(
      () => ({
        topbarLeftActions: (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => history.push('/bids/edit')}
            disableElevation
          >
            New Bid
          </Button>
        ),
        title: 'Bids',
      }),
      [history],
    ),
  );

  useEffect(() => {
    getBids()
      .then(async (bids) => {
        const [vendors, projects] = await Promise.all([
          getVendors(),
          getProjects(),
        ]);
        setVendors(
          vendors.map((v) => ({
            ...v,
            rating: Math.min(5, Math.random() * 1.5 + 3.5),
          })),
        );
        setProjects(projects);
        setBids(
          bids.map((bid) => {
            const project = projects.find(
              (p) => p.projectId === bid.projectId,
            )!;
            return {
              ...bid,
              bidTotalUsd: [
                ...Object.values(bid.localizationServices ?? {})
                  .filter(Boolean)
                  .flatMap((v) => Object.values(v!)),
                ...Object.values(bid.originalPostProductionServices ?? {})
                  .filter(Boolean)
                  .flatMap((v) => Object.values(v!)),
              ].reduce((s, x) => (x || 0) + (s || 0), 0) as number,
              project,
              vendor: vendors.find((v) => v.vendorId === bid.vendorId)!,
              dueDate: project.bidDueDate!,
            };
          }),
        );
      })
      .catch(() => setBids(-1));
  }, []);

  return bids === -1 || !bids ? (
    <Loader loading={!bids} error={bids === -1} />
  ) : (
    <Box padding={8}>
      <Box>
        <BidsPendingView
          bids={bids}
          vendors={vendors}
          projects={projects.filter(
            (p) => Boolean(p.bidDueDate) && !p.bidsSent,
          )}
        />
      </Box>
    </Box>
  );
};
