import React from 'react';
import { Chip, ChipProps } from '@material-ui/core';

export const statusColorMap: { [key in E.Asset['status']]: string } = {
  OPEN: '#3185E7',
  ACCEPTED: '#73C059',
  REDELIVERY: '#C65353',
  UNASSIGNED: '#EF861B',
};

export const StatusChip = ({
  status,
  ...chipProps
}: ChipProps & { status: E.Asset['status'] }) => (
  <Chip
    label={status}
    size="medium"
    {...chipProps}
    style={{
      backgroundColor: statusColorMap[status],
      width: 110,
      ...chipProps.style,
    }}
  />
);
