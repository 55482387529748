import axios from 'axios';
import { Auth } from 'aws-amplify';

export const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

http.interceptors.request.use((config) => {
  if ((config as any).disableAutomaticAuthorizationHandling) {
    return config;
  } else {
    return Auth.currentSession().then((session) => {
      config.headers.Authorization = session.getIdToken().getJwtToken();
      return config;
    });
  }
});
