export const groupNameMap: Record<string, string> = {
  LOCALIZATION: 'Localization',
  POST_PRODUCTION: 'Post Production',
};

export const localizationCategoryMap: Record<string, string> = {
  // The order of the fields matter
  SCRIPTING: 'Scripting',
  TRANSLATING: 'Translating',
  ADAPTING: 'Adapting',
  SUBTITLING: 'Subtitling',
  CASTING: 'Casting',
  RECORDING: 'Recording',
  EDITING: 'Editing',
  MIXING: 'Mixing',
  LINGUISTIC_QC: 'Linguistic QC',
  TECHNICAL_QC: 'Technical QC',
  COMPLETE_QC: 'Complete QC',
};

export const localizationServicesDetailedMap: {
  [K1 in keyof E.LocalizationServiceCosts]: {
    [K2 in keyof E.LocalizationServiceCosts[K1]]: string;
  };
} = {
  // The order of the fields matter
  SCRIPTING: {
    SCRIPTING: 'Scripting',
  },
  TRANSLATING: {
    TRANSLATING: 'Translating',
  },
  ADAPTING: {
    ADAPTING: 'Adapting',
  },
  SUBTITLING: {
    SUBTITLES: 'Subtitles',
    FORCED_NARRATIVES: 'Forces Narratives',
  },
  CASTING: {
    VOICE_CASTING: 'Voice Casting',
    STAR_TALENTS: 'Star Talents',
    CHILD_TALENTS: 'Child Talents',
  },
  RECORDING: {
    RECORDING_DIALOGUE: 'Recording Dialogue',
    RECORDING_SONGS_MUSIC: 'Recording Songs/Music',
  },
  EDITING: {
    EDITING: 'Editing',
  },
  MIXING: {
    MIXING: 'Mixing',
  },
  LINGUISTIC_QC: {
    LINGUISTIC_QC: 'Linguistic QC',
  },
  TECHNICAL_QC: {
    TECHNICAL_QC: 'Technical QC',
  },
  COMPLETE_QC: {
    COMPLETE_QC: 'Complete QC',
  },
};

export const postProductionCategoryMap: Record<string, string> = {
  // The order of the fields matter
  PICTURE_EDITORIAL: 'Picture Editorial',
  'SOUND_EDITORIAL/MIXING': 'Sound Editorial/Mixing',
  VFX: 'VFX',
  DI_PICTURE_FINISHING: 'DI Picture Finishing',
};
