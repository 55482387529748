import { Auth } from 'aws-amplify';

export type LoginResult = {
  updatePassword?: (password: string) => Promise<any>;
};

export const signIn = async (
  email: string,
  password: string,
): Promise<LoginResult> => {
  try {
    const user = await Auth.signIn(email, password);
    if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return {
        updatePassword: (password) =>
          new Promise((resolve, reject) => {
            user.completeNewPasswordChallenge(
              password,
              {},
              {
                onSuccess: resolve,
                onFailure: reject,
              },
            );
          }),
      };
    }
    return {};
  } catch (e) {
    console.log(e);
    throw e;
  }
};
